import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IterateSamplesSampling = ({ open, setOpen, handleIteration }) => {
  
  const [input, setInput] = React.useState("")
  const [error, setError] = React.useState(false)
  const handleCancel = () => {    
    setOpen(false);
  };

  const handleCommit = () => {    
    if (open === "dimension") {
      handleIteration(input, open)
      setOpen(false);
    }

    if (
        input.length < 9 ||
        input.length > 9 ||
        input.charAt(2) !== "-" ||
        !Number.isInteger(input.substring(0,2) * 1) ||
        !Number.isInteger(input.substring(3,9) * 1)
      ) {
        setError(true)
      } else {
        setError(false)
        handleIteration(input, open)
        setOpen(false);
      }

  };

  const handleInput = (e) => {


    setInput(e.target.value)
  }

  const handleSelect = (val) => {
    setInput(val)
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >


        <DialogTitle>Iterate Samples?</DialogTitle>

        {open === "columbiaSampleID" ? 
          <>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Enter the project ID below. Samples will be numbered -0001 to XXXX. 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                    Lab order MUST match exactly as it appears in lims. Example: 22-000123, with all zeroes in place.
                    </DialogContentText>

                    <TextField id="filled-basic" label="LIMS Order ID" fullWidth variant="filled" onChange={handleInput} value={input} />
                    {error ? 
                      <Alert style={{margin: "15px"}} severity="error">Check Entry!</Alert>
                    : null}
                  </DialogContent>
                  
                  <DialogActions>
                    <Button onClick={handleCommit}>Iterate Now</Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </DialogActions>
          </>
        : null}

        {open === "dimension" ? 
          <>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Set all samples to one &quot;Dimension&quot; type then adjust any others manually.
                    </DialogContentText>
                    

                    <ButtonGroup >
                      <Button variant={input === "3D" ? "contained" : "outlined"} onClick={() => handleSelect("3D")} >3D</Button>
                      <Button variant={input === "2D" ? "contained" : "outlined"} onClick={() => handleSelect("2D")} >2D</Button>
                    </ButtonGroup>

                    
                  </DialogContent>
                  
                  <DialogActions>
                    <Button disabled={input === ""} onClick={handleCommit}>Iterate Now</Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </DialogActions>
          </>
        : null}
      </Dialog>
    </div>
  );
}

export default IterateSamplesSampling;
