import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SampleAmount = ({ open, setOpen }) => {
    
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Sample Amount Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please refer to the table below to ensure that enough sample is submitted to perform all selected testing. Insufficient sample volume will be noted on the report.
          </DialogContentText>

          <img style={{ width: "100%", maxWidth: "650px"}} src="/images/samplesAmount.png" />

        </DialogContent>
            
              
            

        <DialogActions>
          <Button onClick={handleCancel}>Go Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SampleAmount;
