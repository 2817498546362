import React from 'react';

// components
import Signature from '../../dialogues/Signature';

// mui
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const SamplingEventInstructions = ({ data, userData, setUserData }) => {
  const [signOpen, setSignOpen] = React.useState(false)

    return (
      <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px', marginBottom: '15px'}}>
        <Signature data={data} samplingMode={false} open={signOpen} setOpen={setSignOpen} userInput={userData} setUserData={setUserData} /> 


      <Typography align="left" variant="h5" >Sampling Event Instructions</Typography> 
      <Divider variant="middle" />
      <Stepper orientation="vertical">
            <Step active={true}>
                  <StepLabel>

                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <CopyAllIcon />
                      <span>Create Duplicates </span>
                    </div>
                     
                  </StepLabel>
                  <StepContent >
                    <Typography align="left" style={{fontSize: "12px"}}>Click the 	&quot;Create Duplicates	&quot; butotn on the COC, then SAVE</Typography>                    
                  </StepContent>
              </Step>

              <Step active={true}>
                  <StepLabel>

                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <DownloadIcon />
                      <span>Import to LIMS </span>
                    </div>

                    
                  </StepLabel>
                  <StepContent >
                    <Typography align="left" style={{fontSize: "12px"}}>Import Project into LIMS.</Typography>                    
                  </StepContent>
              </Step>
              <Step active={true}>
                  <StepLabel>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <CreateIcon />
                      <span>Add Columbia ID and other data </span>
                    </div>
                    
                  </StepLabel>
                  <StepContent >
                    
                    
                    <Typography align="left" style={{fontSize: "12px"}}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <span>Add Columbia Sample IDs, </span>
                      <FormatListNumberedIcon />
                      <span>Dimensions, </span>
                      <ViewInArIcon />
                      <span>and Metrc Tag ID into COC when that data is available. SAVE!</span>
                      <SaveIcon />
                    </div>
                      </Typography>                    
                  </StepContent>
              </Step>

              <Step active={true}>
                  <StepLabel>
                    Sampling Event
                  </StepLabel>
                  <StepContent >
                    <Typography align="left" style={{fontSize: "12px"}}>Perform Sampling Event by clicking 	&quot;Sampling Worksheets	&quot; button above. In the sampling event area, when complete, click 	&quot;Complete Sampling Event	&quot; to mark project as 	&quot;complete	&quot;</Typography>                    
                  </StepContent>
              </Step>

              <Step active={true}>
                  <StepLabel>
                    Relinquish
                  </StepLabel>
                  <StepContent >
                    <Typography align="left" style={{fontSize: "12px"}}>Have client relinquish samples, now sampled, to sampler. Follow instructions on main ECOC page (follow link below)</Typography>                    
                  </StepContent>
              </Step>

              <Step active={true}>
                  <StepLabel>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <CreateIcon />
                      <span>Add or Adjust Metrc Tag ID & Other Information </span>
                    </div>
                    
                  </StepLabel>
                  <StepContent >
                    
                    
                    <Typography align="left" style={{fontSize: "12px"}}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                      <span>Metrc Tag ID and other details can be adjusted after sampling here as well when that data is available. Don&apos;	t forget to SAVE!</span>
                      <SaveIcon />
                    </div>
                      </Typography>                    
                  </StepContent>
              </Step>

      </Stepper>

      <Divider variant="middle" style={{ margin: '8px' }} />

      {userData.status === 'samplingComplete' ? 
        <Button onClick={() => setSignOpen(true)} variant='contained' endIcon={<BorderColorIcon style={{fontSize: '2rem'}} />} style={{ backgroundColor: 'rgba(38, 168, 137, 1)', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}} color="success">              
          Save & Relinquish            
        </Button> 
      : null}  
       
      <Button variant="contained" component={Link} href={`${window.location.href.replace("admin/", "").replace("admin-", "")}`.replace('?','')}  target="_blank"  style={{ marginTop: '4px', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}}>  
        ECOC Client Page
      </Button>
    </div>
    )

}

export default SamplingEventInstructions;
