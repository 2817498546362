import React from 'react';

// mui
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';

const MirrorKey = ({ selected, updateValue, currentForm }) => {
    
    const [newKey, setNewKey] = React.useState(false)

    const toggleChecked = () => {
        if (selected.mirrorKey || newKey) {
            updateValue(false, 'mirrorKey', false, 'delete')
            setNewKey(false)
        } else {
            setNewKey(true)
        }
    }
    
    const handleSelection = (e) => {
        updateValue(e, 'mirrorKey') 
        setNewKey(false) 
    }

    const renderOptions = () => {
        const formGroups = currentForm.sub_sections;
        const options = []
        formGroups.forEach(p => {
            const inputs = p.inputs
            inputs.forEach(c => {
                options.push({
                    key: c.input_key,
                    text: p.header + " - " + c.label
                })
            })
        })

        return options.filter(item => item.text.indexOf('undefined') < 0)
    }


    const selectOptions = renderOptions()
    return (
        <>
        <Typography variant="h5"  style={{float: "Left"}}>Mirroring</Typography>
        <br /><br />
            <FormControlLabel
                label="Use Mirror Key?"
                control={
                    <Checkbox
                        checked={selected.mirrorKey || newKey}
                        onChange={toggleChecked}
                    />
                }
            />

            {selected.mirrorKey || newKey ? 
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Available Keys</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        style={{width: "100%"}}
                        id="demo-simple-select"
                        value={selected.mirrorKey || ''}
                        label="Validate Entry"
                        onChange={handleSelection}
                    >   
                        {selectOptions.map((option, i) => {
                            return <MenuItem key={option.key + i + 'selectOpt'} value={option.key}>{option.text}</MenuItem>
                        })}
                    </Select>
                    <FormHelperText id="component-helper-text">
                        Each option shows the item and its parent group. Sometimes the item has a generic name so the parent should help specify what exactly you&apos;re copying. Also, only items from the full gruop itself, ie, Client vs Sample form, are permitted at this time.
                    </FormHelperText>
                </FormControl>
            : null }
            
            
            <FormHelperText id="component-helper-text">
                A mirror key is a key from which THIS entry should Copy if the option &quot;Use Same Details as Above&quot; is selected. For example, Billing Address should have a mirror key to copy Company Address. Clicking YES here will then display all available options.
            </FormHelperText>


        </>                              
    );

}

export default MirrorKey;

