import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import GenericSave from '../../dialogues/GenericSave';
import IterateSamplesSampling from '../../dialogues/IterateSamplesSampling';

// mui
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

// utils
import FormAPI from '../../../utils/API/api-form';

const DocumentControlSampling = ({ userInput, setUserData, createSamplingDuplicates, setSamplingEventMode, samplingEventMode, samplingEventInputs, setSamplingEventInputs }) => {

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [saveOpen, setSaveOpen] = React.useState(false)
  const [snackOpen, setSnackOpen] = React.useState(false)
  const [iterateOpen, setIterateOpen] = React.useState(false)

  const handleOpenSamplingEvent = () => {    
    setSamplingEventInputs(userInput.samplingEventInputs ?? [])
    setSamplingEventMode(true)
  };

  const handleClose = () => {    
    setSamplingEventInputs(userInput.samplingEventInputs ?? [])
    setSnackOpen(false);
  };

  const handleSaveUserData = async () => {     
    setSamplingEventMode(false)  
    setLoading(true)
    try {
      // set sampling data here into userdata
      const newUserInput = cloneDeep(userInput)
      newUserInput.samplingEventInputs = samplingEventInputs

      const res = await FormAPI.saveClientSubmission({data: {
        userJSON: newUserInput
      }})
      
      console.log(res)
      if (res.status === 200) {            
          setError(false)      
          setLoading(false)
          setUserData(res.data)
      } else {
          setLoading(false)
          setError(true)
      }    
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  } 

  const handleIteration = (value, cat) => {
    
    const newInputs = cloneDeep(samplingEventInputs)

    userInput.samples.forEach((sample) => {
      let newSampleInput = newInputs.find(sam => sam.id === sample.id)

      if (cat === "columbiaSampleID") {
        const colSamID = sample.id + 1
        const leadingZeroes = "000".substring(0, 4 - colSamID.toString().length)
        const columbiaID = value + "-" + leadingZeroes + colSamID
        
  
        if (newSampleInput) {
            newSampleInput[cat] = columbiaID
            newInputs.splice(sample.id, 1, newSampleInput)
        } else {
            newSampleInput = {id: sample.id}
            newSampleInput[cat] = columbiaID
            newInputs.push(newSampleInput)
        }
      }

      if (cat === "dimension") {        
        
        if (newSampleInput) {
            newSampleInput[cat] = value
            newInputs.splice(sample.id, 1, newSampleInput)
        } else {
            newSampleInput = {id: sample.id}
            newSampleInput[cat] = value
            newInputs.push(newSampleInput)
        }
        
      }


    })

    newInputs.sort((a, b) => (a.id > b.id ? 1 : -1))
    setSamplingEventInputs(newInputs)

  }
  
  return (
    <>

    {saveOpen ? <GenericSave open={saveOpen} setOpen={setSaveOpen} handleSave={handleSaveUserData} snackOpen={setSnackOpen} projectID={userInput.submissionID}/> : null}
    {iterateOpen ? <IterateSamplesSampling open={iterateOpen} setOpen={setIterateOpen} handleIteration={handleIteration} /> : null}


    <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleClose}       
    >

    <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
      Project Saved!
    </Alert>
    </Snackbar>

    {loading ? 
    <>   
    
      <CircularProgress style={{color: "white"}} />     
    
    </>
    :
    <>


        {samplingEventMode ? 
        <>


        <Tooltip title="Iterate Sample IDs">
          <IconButton
            style={{color: "white"}}            
            onClick={() => setIterateOpen("columbiaSampleID")}            
          >
            <FormatListNumberedIcon />       
          </IconButton>
        </Tooltip>

        <Tooltip title="Set Container Dimensions">
          <IconButton
            style={{color: "white"}}            
            onClick={() => setIterateOpen("dimension")}            
          >
            <ViewInArIcon />       
          </IconButton>
        </Tooltip>

        <Tooltip title="Cancel Input Mode">
          <IconButton
            style={{color: "white"}}            
            onClick={() => setSamplingEventMode(false)}            
          >
            <ClearIcon />       
          </IconButton>
        </Tooltip>
        </>
        : null}



        
          {!userInput.duplicated ?
              <>
                  <Tooltip title="Generate Sampling Duplicates">
                  <IconButton
                    style={{color: userInput.duplicated ? "#999999" : "white"}}            
                    onClick={createSamplingDuplicates}
                  >
                    <CopyAllIcon />       
                  </IconButton>
                </Tooltip>
                
              </>
          :
          <>
          
          <Tooltip title="Input Sampler Data">
                <IconButton
                  style={{color: samplingEventMode ? "#999999" : "white"}}            
                  onClick={handleOpenSamplingEvent}
                  disabled={samplingEventMode}
                >
                  <CreateIcon />       
                </IconButton>
              </Tooltip>
              
           <Tooltip title="Save">
            <IconButton
              style={{color: "white"}}          
              onClick={() => {setSaveOpen(true)}}
            >
              <SaveIcon />       
            </IconButton>
          </Tooltip>
          


              </>
          }


        
        {error ? <div style={{color: "white"}}>Error saving! Try again. If error persists please speak to administrator.</div> : null}
      </> 
    }
    
  </>
  );
}

export default DocumentControlSampling;
