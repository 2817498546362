import React from 'react';


// mui
import Paper from '@mui/material/Paper';

// styles
import '../utils/styles/styleSheet.css'

const NoPage = () => {
   

    return (
    <Paper elevation={2} className="formCanvas"  >
        404 This Page Does Not Exist
        <br />
        <a href="https://us.submissions.tentamus.com/cannabis">Click here to return home</a>
    </Paper>
    )
}

export default NoPage
