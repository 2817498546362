import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

// utils
import FormAPI from '../../../../utils/API/api-form';

const BalanceTools = ({ userData, setUserData, references, balanceEntries, setBalanceEntries, editMode, setEditMode, error, setError, setLoading }) => {

    

    const handleSubmit = () => {
      // check all entires that they are filled      
      const newEntries = cloneDeep(balanceEntries)    

      const checkFilled = []
      const checkPassed = []
      
      references.samplingEventBalanceCriteria.columns.forEach(row => {        
          
        // apply checks


        references.samplingEventBalanceCriteria.rows.forEach(crit => {

          const checkKey = crit.id + "-" + row
          
          let checkEntry = newEntries.entries[checkKey]

          if(checkEntry) {
            
            // set any filled to fixed 4
            checkEntry = (1 * checkEntry).toFixed(4)          
            // check that it meets parameters
            newEntries.assessments[checkKey] = checkEntry / crit.target > 0.9 && checkEntry / crit.target < 1.1     

            if (!newEntries.assessments[checkKey]) {
              
              checkPassed.push(false)
            }

            // note that it's filled
            checkFilled.push(true)
            // also check the balance situation, make sure they have selections
            if (!newEntries.entries["weightSerial-" + crit.id]) {              
              checkFilled.push(false)
            }

          } else {
            // note that it's not filled
            checkFilled.push(false)
          }

        })
        
      })

      setBalanceEntries(newEntries)
      
      if (checkFilled.indexOf(false) > -1) {
        setError("missingEntries")
      } else {

        // set error on failures
        if (checkPassed.indexOf(false) > -1) {
          setError("balanceFailure")          
        } else {
          setError(false)
        }

        setEditMode("closed")  
        
      }
    } 

    const handleCommit = async () => {
      setEditMode("commit") 
      setLoading(true)
      setError("")
      const newUserData = cloneDeep(userData)
      newUserData.status = "samplingBalanceValidated"
      newUserData.balanceEntries = balanceEntries

      try {
        const res = await FormAPI.saveClientSubmission({data: {
          userJSON: newUserData
        }})


        if (res.status === 200) {            
            setError("")      
            setLoading(false)
            setUserData(res.data)
        } else {
            setLoading(false)
            setError("saveError")
        } 
      } catch (err) {
        setLoading(false)
        setError("saveError")
      }

    }
    
    return (
        <>
        {error === "saveError" ? 
          <Alert style={{margin: "15px"}} severity="error">Failed to save to server! Connection to server failed! Click <Button onClick={handleCommit} >Here</Button> to try again!</Alert>
        : null}

        <ButtonGroup variant="outlined" aria-label="outlined button group">

            <Button disabled={editMode !== "open"} onClick={handleSubmit}>Submit Entries</Button>
            <Button disabled={editMode !== "closed"} onClick={() => setEditMode("open")}>Retry</Button>
            <Button disabled={error !== false && editMode !== "open"} onClick={handleCommit} >Commit</Button>

        </ButtonGroup>
                {/* Show error for not having filled everything */}
                
        {error === "missingEntries" ? 
          <Alert style={{margin: "15px"}} severity="error">All balance checks must be filled before submission!</Alert>
        : null}

        {error === "balanceFailure" ? 
          <Alert style={{margin: "15px"}} severity="error">Balance must pass validation before proceeding!</Alert>
        : null}

        </>
    )

}

export default BalanceTools;
