import React from 'react';
import { cloneDeep } from "lodash";

// mui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';

// utils
import "./scrollBar.css"

const BottlesForm = ({ userInput, setUserInput, refSample, checkRequirements, disabled }) => {

    const handleInput = (value, bottle) => {
        const newUserInput = cloneDeep(userInput)
        const newSample = newUserInput.samples.find(s => s.id === refSample.id)
        const newBottle = newSample.activeBottles.find(ab => ab.BOTTLE_LIMS_ID === bottle.BOTTLE_LIMS_ID)
        newBottle.lotNumber = value
        setUserInput(newUserInput)
    }

    return (
        <>
        {refSample.activeBottles?.length > 0 ? 
        <div role="presentation" style={{ width: "100%" }}>
            <Paper elevation={3} sx={{m: '15px', p: '15px', textAlign: 'left'}}>
    
                <div className="formHeaderContainer">
                    <Battery0BarIcon className="formHeaderIcon" />
                    <Typography component="h5" variant="h5">Bottles</Typography>                
                </div>
                
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: "90%" }} size="small" aria-label="a dense table">
                    
                <TableBody>

                    {refSample.activeBottles?.sort((a, b) => a.BOTTLE_NAME?.localeCompare(b.BOTTLE_NAME)).map(b => {
                        return (
                            <TableRow
                                key={"bottle-"+b?.BOTTLE_LIMS_ID+"-"+refSample.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{b?.BOTTLE_NAME}</TableCell>         
                                <TableCell align="center">

                                    <FormControl variant="standard" style={{width: '100%'}}>

                                        <TextField   
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={disabled}
                                            label={<span style={{fontSize: '1.2em'}}>B#/Lot #</span>}   
                                            variant="standard"                    
                                            helperText={'Located on bottle label (enter "NA" if unknown)'}
                                            error={checkRequirements && (!b.lotNumber || b.lotNumber === '')}
                                            size="small"
                                            value={b.lotNumber || ''}
                                            onChange={(e) => {
                                                handleInput(e.target.value, b);
                                            }}
                                        />

                                    </FormControl>

                                </TableCell>
                            </TableRow>
                        )
                    })}

                </TableBody>
                </Table>
                </TableContainer>

            </Paper>
        </div>
        : null}

        </>
    );
}

export default BottlesForm;
