import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { cloneDeep } from 'lodash';

// components
import AdminLogin from './Admin/Standard/AdminLogin';

// mui
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// utils
import FormAPI from '../utils/API/api-form';
import APIQueries from '../utils/API/api-queries';

const DocumentViewer = () => {
    
  const [filename, setFilename] = React.useState('')
  
  useEffect(() => {
    const currentURL = window.location.href
    const urlArr = currentURL.split('/')   
    console.log(urlArr[4]); 
    setFilename('/api/document?q=' + urlArr[4]);      
  }, [filename])

    return (
    <>   
        <iframe src={filename} style={{width:'100%', height:'1000px'}}></iframe>       
    </>
        
    )

}

export default DocumentViewer;
