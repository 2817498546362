import * as React from 'react';
import ReactQuill from 'react-quill';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

// utils
import FormAPI from '../../utils/API/api-form';
import dateHandlers from '../../utils/functions/dateHandlers';
import "./QuillEditor.css"

import 'react-quill/dist/quill.snow.css';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminNotes = ({ open, setNotes, setOpen, notes }) => {

  const useNotes = notes.filter(n => n.clientID === open.submissionID)

    const [value, setValue] = React.useState('');
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)

    const handleSave = async () => {
      
      setLoading(true)
      setError(false)

      const submission = {
        clientID: open.submissionID,
        text: value,
        dateTime: new Date(),
        user: name
      }
      
      const saved = await FormAPI.putAdminNotes(submission)
      
      if (saved) {
        let newNotes = [...cloneDeep(notes), submission]
        setNotes(newNotes)        
        setValue('')
        setName('')
        
      } else {
        setError(true)
      }

      setLoading(false)
      
    }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={!!open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Project Notes</DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                {useNotes.map(n => {
                  return (
                    <Item key={n.user + n.dateTime}>
                      <div style={{width: '33%', float: 'left'}}>
                        <p>{n.user}</p>
                        <p>{dateHandlers.standardDate(n.dateTime)} {dateHandlers.standardTime(n.dateTime)}</p>
                      </div>
                      <div dangerouslySetInnerHTML={{__html: n.text}} />
                    </Item>
                  )
                })}
                {loading ? 
                "Saving Now..."
                : 
                <>
                  <Item className='ql-container'><ReactQuill className='ql-editor' theme="snow" value={value} onChange={setValue} /></Item>
                  <Item>
                      Name
                      <OutlinedInput
                          fullWidth
                          value={name}  
                          onChange={(e) => setName(e.target.value)}
                          endAdornment={
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleSave}
                                  color='success'
                                  edge="end"
                                  disabled={name === '' || value === ''}
                              >
                                  <SendIcon />
                              </IconButton>
                          </InputAdornment>
                          }
                          InputLabel="Name"
                      />
                      {error ? <span style={{color: 'red'}}>Something went wrong saving, try again, else contact devs!</span> : null}
                  </Item>
                </>
                }
                
            </Stack>

        </DialogContent>
            
              
        <DialogActions>          
          <Button onClick={( )=> {setOpen(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminNotes;
