import React, { useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import AdminLogin from './Admin/Standard/AdminLogin';
import MainToolbar from './Admin/Standard/MainToolbar';
import MainTable from './Admin/Standard/MainTable';
import Relinquishment from './Admin/Relinquishment/Relinquishment';
import MaterialOrderFulfillment from './Admin/MaterialOrderFulfillment/MaterialOrderFulfillment';
import GeneralSamplingEvent from './Admin/GeneralSamplingEvent/GeneralSamplingEvent';
import Snackbar from '@mui/material/Snackbar';

// utils
import FormAPI from '../utils/API/api-form';
import APIQueries from '../utils/API/api-queries';
import applySamplingDuplicates from '../utils/functions/applySamplingDuplicates';

// add a form ID filter and it have "blur" or whatever to lag a bit after typing and auto-query

const Admin = () => {
    
    const [mode, setMode] = React.useState("admin")
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState(false)
    const [permission, setPermission] = React.useState(false)
    const [filters, setFilters] = React.useState([])    
    const [error, setError] = React.useState(false)
    const [userData, setUserData] = React.useState(false)
    const [formData, setFormData] = React.useState(false)
    const [samplingReferences, setSamplingReferences ] = React.useState(false)
    const [archiveData, setArchiveData] = React.useState(false)
    const [loadLimit, setLoadLimit] = React.useState(25);
    const [serviceLines, setServiceLines] = React.useState(false)
    const [notes, setNotes] = React.useState(false)
    const [statuses, setStatuses] = React.useState([])
    const [submissionTypes, setSubmissionTypes] = React.useState([])
    const [snackbar, setSnackbar] = React.useState(false);

    const getData = async (loadingParams) => {
        setLoading(true)

        try {          

          const queryParams = {
            archive: archiveData, 
            limit: archiveData ? loadLimit : 10000, 
            offset: 0, // do something with this if you want
          }

          const serviceLineFilter = filters.find(f => f.key === 'serviceLine')
          if (serviceLineFilter) {
            queryParams.serviceLine = serviceLineFilter.value
          }
          
          const submissionIDFilter = filters.find(f => f.key === 'submissionID')
          if (submissionIDFilter) {
            queryParams.submissionID = submissionIDFilter.value
          }

          const receiveFilter = filters.find(f => f.key === 'receive')
          if (receiveFilter) {
            queryParams.receive = receiveFilter.value
          }

          const statusFilter = filters.find(f => f.key === 'status')
          if (statusFilter) {
            queryParams.status = statusFilter.value
          }
          
          const submissionTypeFilter = filters.find(f => f.key === 'submissionType')
          if (submissionTypeFilter) {
            queryParams.submissionType = submissionTypeFilter.value
          }

          const adminList = await FormAPI.getAdminClientList(queryParams)      
          const fullData = await APIQueries.getFullData('cfl', loadingParams?.serviceLine || queryParams?.serviceLine || false, true, true, loadingParams?.submissionID || false, loadingParams?.sampling || false)

          setData(adminList.data.data)
          const currentStatuses = [...statuses]
          const incomingStatuses = [...new Set(adminList.data.data.map(dat => dat.status).filter(dat => currentStatuses.indexOf(dat) < 0))]
          setStatuses([...currentStatuses, ...incomingStatuses].sort())

          const currentSubmissionTypes = [...submissionTypes]
          const incomingSubmissionTypes = [...new Set(adminList.data.data.map(dat => dat.submissionType).filter(dat => currentSubmissionTypes.indexOf(dat) < 0))]
          setSubmissionTypes([...currentSubmissionTypes, ...incomingSubmissionTypes].sort())

          setNotes(adminList.data.notes)

          const formattedData = {
            forms: fullData.form,
            testing: fullData.testing,
            references: fullData.references,
            samplingEvent: fullData.sampling?.samplingEvent,
          }

          setFormData(formattedData)
          setUserData(fullData.clientSubmissionData || false)

          if (queryParams.submissionID && !fullData.clientSubmissionData) {
            setSnackbar(<p>the URL provided could not be openned, please check the submission ID or URL: {window.location.href}</p>)
            window.history.pushState('page2', 'Title', '/admin/')
          }
          
          if (fullData.clientSubmissionData?.submissionType?.includes('samplingEvent')) {
            setMode('samplingEvent')
          } else if (fullData.clientSubmissionData && fullData.clientSubmissionData.submissionType === 'samples') {
            setMode('relinquishment')
          } else if (fullData.clientSubmissionData && fullData.clientSubmissionData.submissionType !== 'samples') {
            setMode('materials')
          }

          setSamplingReferences(fullData.sampling || false)
         
          setLoading(false)

        } catch (err) {
          console.log(err)
          setLoading(false)
          setError("getDataErr")
        }

    }

    const getServiceLines = async () => {
      const serviceLinesData = await FormAPI.getServiceLines({ labCode: 'cfl' })
      setServiceLines(serviceLinesData.data)
    }

    useEffect(() => {
      setLoading(true)
      const localPermission = JSON.parse(localStorage.getItem('admin'))

      if (localPermission?.permission) {
        if (localPermission.date === new Date().getDate()) {
          setPermission(true)
          setLoading(false)
        } else {
          localStorage.removeItem('admin');
          setLoading(false)
        }
        
      } else {
        setLoading(false)
      }

      
    }, [permission])


    const updateSavedData = async (sel, status) => {       
      const selInput = data.find(d => d.submissionID === sel)
            
      const fullData = await APIQueries.getFullData('cfl', selInput.serviceLine, false, false, selInput.submissionID, false)                             
      const newData = fullData.clientSubmissionData
      
      // apply change
      newData.archive = status
      
      const res = await FormAPI.saveClientSubmission({data: {
          userJSON: newData
      }})

      if (res.status === 200) {            
          setError(false)      
          setLoading(false)
          getData(false, !status)

      } else {
          setLoading(false)
          setError("archiveError")
      }
    } 

    const createSamplingDuplicates = () => {
      setError(false)
      const newInput = cloneDeep(userData)  
      const withDuplicates = applySamplingDuplicates(newInput, samplingReferences, formData.references)
      if (withDuplicates.status) {
        withDuplicates.status = "samplingDuplicatesCreated"
        setUserData(withDuplicates)
      } else {
        // there was an error
        setError(withDuplicates)
      }
    }

    useEffect(() => {
      if (permission) {
        // get form data when loading page from fresh.
        // use this for API calls...
        
        const currentURL = window.location.href
        const urlArr = currentURL.split('/')        
        
        const loadingParams = {
          sampling: ["massUnitConversions", "incrementZones", "matrixConversions", "samplingDuplicates", "samplingEventBalanceCriteria", "samplingIncrementRanges", "matrixConversions", "samplingEvent"]
        }
        if (urlArr[4]) {        
          loadingParams.mode = 'relinquishment'
          loadingParams.submissionID = urlArr[4] ? decodeURIComponent(urlArr[4]) : false
          loadingParams.serviceLine = urlArr[3].replace('admin-', '')
        } 

        getData(loadingParams)
        getServiceLines()
      }

      
    }, [permission])


    const setFilter = (cat, sel) => {
      const newFilters = cloneDeep(filters).filter((f) => f.key !== cat);
      newFilters.push({ key: cat, value: sel });
      setFilters(newFilters);
    };

    const resetFilter = (cat) => {
      const newFilters = cloneDeep(filters).filter((f) => f.key !== cat);
      setFilters(newFilters);
    };

    useEffect(() => {
      if (permission) {
        getData();
      }
    }, [filters, archiveData, loadLimit])

    return (
      <>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        message={snackbar}
      />

      {permission && data && formData ?           
        <>
          
          {/* Here we can split between Sampling and Standard and more, also, probably */}              
          {mode === "admin" ? 
          <>
            <MainToolbar data={data} resetFilter={resetFilter} serviceLines={serviceLines} statuses={statuses} filters={filters} setFilter={setFilter} setFilters={setFilters} getData={getData} loading={loading} setMode={setMode} setArchiveData={setArchiveData} archiveData={archiveData} submissionTypes={submissionTypes} />
            <MainTable notes={notes} setNotes={setNotes} formData={formData} resetFilter={resetFilter} setFilters={setFilters} setFilter={setFilter} archiveData={archiveData} data={data} updateSavedData={updateSavedData} filters={filters} loading={loading} setLoading={setLoading} loadLimit={loadLimit} setLoadLimit={setLoadLimit} />                
          </>
          : null}

          {mode === "relinquishment" ? 
          <>
            <Relinquishment loading={loading} error={error} setError={setError} setMode={setMode} userData={userData} setUserData={setUserData} formData={formData} createSamplingDuplicates={createSamplingDuplicates} references={samplingReferences} />
          </>
          : null}

        {mode === "materials" ? 
          <>
            <MaterialOrderFulfillment loading={loading} error={error} setError={setError} setMode={setMode} userData={userData} setUserData={setUserData} formData={formData} createSamplingDuplicates={createSamplingDuplicates} references={samplingReferences} />
          </>
          : null}

        {mode === "samplingEvent" ? 
          <>
            <GeneralSamplingEvent loading={loading} error={error} setError={setError} setMode={setMode} userData={userData} setUserData={setUserData} formData={formData} createSamplingDuplicates={createSamplingDuplicates} references={samplingReferences} />
          </>
          : null}
        </>
      : 
        // get access here
        <AdminLogin setPermission={setPermission} setLoading={setLoading} target={'admin'} loading={loading} />
      }

    </>
        
    )

}

export default Admin;
