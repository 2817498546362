import React from 'react';

// components
import SampleDataForm from './SampleDataForm';
import SamplesPagination from './SamplesPagination';

// mui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

// styles
import '../../../utils/styles/styleSheet.css';

const Samples = ({ userInput, data, setUserInput, setPage, checkRequirements, handleRemoveSample, handleNewSample, maxPages, pageSamples, setPageSamples, mode, samplingMode }) => {

    const renderSamples = userInput.samples.slice(0 + (pageSamples * 10), 10 + (pageSamples * 10))

    return (
        <>

         {/* Samples Here */}
         {renderSamples.map((s, i) => {
            
            return (
                <React.Fragment key={`sample-${s.id}`}>
                <Grid item xs={0} md={2} lg={3} id={`sample-${s.id}`}>                
                </Grid>
                    <Grid item xs={12} md={8} lg={6} id={`sample-form-${s.id}`}>
                        <SampleDataForm 
                            checkRequirements={checkRequirements} 
                            setPage={setPage} 
                            data={data}
                            userInput={userInput} 
                            setUserInput={setUserInput} 
                            sampleIndex={s.id} 
                            deleteSample={handleRemoveSample} 
                            labelIndex={i}
                            mode={mode}
                            samplingMode={samplingMode}
                        />
                    </Grid> 
                <Grid item xs={0} md={2} lg={3} id={`sample-helper-${s.id}`}>       
                </Grid>
                
                </React.Fragment>
            )  
        })}
        
        <Grid item xs={5} md={7}></Grid>
        <Grid item xs={6} md={2} container justifyContent={'flex-end'} >
            <Button startIcon={<AddIcon style={{fontSize: "20px"}} />} className={!userInput.serviceLine ? "addSampleButtonDisabled" : "addSampleButton"} variant="contained" disabled={!userInput.serviceLine} onClick={handleNewSample}>Add Sample</Button>                        
        </Grid>
        <Grid item xs={1} md={3}></Grid>

        <Grid item xs={0} md={3}>                            
        </Grid>
        <Grid item xs={12} md={6} >                
            <SamplesPagination maxPages={maxPages} pageSamples={pageSamples} setPageSamples={setPageSamples} /> 
        </Grid>   
        <Grid item xs={0} md={3}>                
        </Grid>

    </>
        
    )

}

export default Samples;
