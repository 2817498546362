import React from 'react';

// mui
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// utils
import checkComplexRequired from '../../../utils/functions/checkComplexRequired.js';

const UserSelectAutoComplete = ({ value, refItem, disabled, handleInput, sampleIndex, checkRequirements, userInput, data }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''
    const requiredCheck = checkComplexRequired(value.required, userInput, sampleIndex)

    if (useInput !== '' && value.input_key === 'matrix') {
        const refItem = data.references[value.selection.source]?.find(it => it.LIMS_ID === useInput)
        useInput = {
            label: refItem.DISPLAYNAME,
            id: refItem.LIMS_ID
        }
    }

    const [valueNew, setValueNew] = React.useState(useInput || null);

    const canMirror = value.mirrorKey ? true : false    
    const isMirrored = disabled ? disabled : false
    if (canMirror && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : '' 
    }

    const renderError = checkRequirements && requiredCheck && useInput === ''    

    const options = value.selection?.value.map(item => {
        return {
            label: value.selection.source === 'specified' ? item : data.references[value.selection.source]?.find(it => it.LIMS_ID === item)?.DISPLAYNAME,
            id: item
        }
    }).sort((a, b) => a.label?.localeCompare(b.label))
    
    const defaultProps = {
        options: options,
        getOptionLabel: (option) => option.label,
        renderOption: (props, option) => {
            return (
                <li {...props} key={option.id}>
                    {option.label}
                </li>
            );
        },
        isOptionEqualToValue: (option, value) => option.label === value.label,
    };

    const handleACInput = (val) => {
        if (value.input_key === 'matrix' && val?.id) {
            handleInput(false, value.input_key, val.id)
        } else if (val?.label) {
            handleInput(false, value.input_key, val.label)
        } else {
            handleInput(false, value.input_key, val)            
        }
        
        setValueNew(val)
    }

    return (
        <Grid item xs={value.xs} md={value.md}>
            <FormControl sx={{ width: '100%' }} size="small" error={renderError}>
   
            <Autocomplete
                {...defaultProps}
                value={valueNew}
                onChange={(event, newValue) => {
                    handleACInput(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={
                            <span>{value.label}<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span>
                        }
                        variant="outlined"
                        fullWidth size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                
            />

                {renderError ? <FormHelperText>Required Entry</FormHelperText> : null}

            </FormControl>
        </Grid>
    );
}

export default UserSelectAutoComplete;
