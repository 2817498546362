import React from "react";
import { cloneDeep } from "lodash";

// components
import InputObjRender from "../InputObjRender.js";
import TestingBody from "../testing/TestingBody.js";
import DeleteSample from "../../dialogues/DeleteSample.js";
import DuplicateSample from "../../dialogues/DuplicateSample.js";
import SampleAmount from "../../dialogues/SampleAmount.js";

// mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BiotechIcon from "@mui/icons-material/Biotech";
import ErrorIcon from "@mui/icons-material/Error";

// utils
import "../../../utils/styles/styleSheet.css";
import samplingEventTools from "../../../utils/functions/samplingEventMode.js";

const SampleDataForm = ({ data, userInput, setUserInput, sampleIndex, deleteSample, checkRequirements, mode, samplingMode }) => {

  const [disabled] = React.useState({});
  const [samAmtOpen, setSamAmtOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [duplicateOpen, setDuplicateOpen] = React.useState(false);

  const refSample = userInput.submissionType === 'samples' || userInput.submissionType.includes('samplingEvent') ? userInput.samples?.find((s) => s.id === sampleIndex) : userInput.materialOrders[0];

  const handleInput = (e, cat, alt) => {

    const newInput = cloneDeep(userInput);

    if (refSample) {
      const newSample = newInput.samples.find((s) => s.id === sampleIndex);
      newSample[cat] = alt || alt === null ? alt : e.target.value;

      if (cat === "matrix") {
        const newTests = refSample.activeTests?.map(at => {
            const materials = data.references[at.type + 'Materials']?.filter(psm => psm[at.type.toUpperCase() + '_LIMS_ID'] === at.LIMS_ID)
            if (materials?.length) {
                const matrixCheck = !!materials.find(m => m.MATERIAL_LIMS_ID === e?.target?.value)
                if (!matrixCheck) {return false}
            }
            return at
        }).filter(at => at)
        if (newTests?.length !== refSample.activeTests?.length) {
            newSample.activeTests = newTests;
        }
      }

    } else {
      newInput.samples.push({
        id: sampleIndex,
        [cat]: alt ? alt : e.target.value,
      });
    }

    setUserInput(newInput);
  };


  const handleDeleteSample = () => {
    deleteSample(sampleIndex);
  };


  const handleDuplicateSample = (val) => {
    const newInput = cloneDeep(userInput);
    const lastID = newInput.samples.length;
    for (let i = 0; i < val; i++) {
      const newSample = cloneDeep(refSample);
      newSample.id = i + lastID;
      delete newSample.collectionDate
      delete newSample.collectionTime
      newSample.name = newSample.batchName ? newSample.batchName + " duplicate " + (i + 1) : newSample.name + " duplicate " + (i + 1)
      newInput.samples.push(newSample);
    }

    setUserInput(newInput);
  };

  // form references. Sample is a little more specific, can't just render it iteratively as Client does
  const sampleForm = data.forms?.sections?.find(f => f.type === "sample") || false
  const general = sampleForm?.sub_sections?.find(ss => ss.sectionKey === "general") || false
  const additional = sampleForm?.sub_sections?.find(ss => ss.sectionKey === "additional") || false

  const testing = data.forms?.sections?.find(f => f.type === "testing") || false

  const filteredGeneralInputs = samplingEventTools.filteredInputs(general.inputs, samplingMode, mode)
  const filteredAdditionalInputs = samplingEventTools.filteredInputs(additional.inputs, samplingMode, mode)

  return (
    <Paper elevation={2} className="formCanvas" >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} className="formHeaderContainer">
          <AnalyticsIcon className="formHeaderIcon" />
          <Typography component="h5" variant="h5">
            {userInput.serviceLine.indexOf("sampling") > -1 ? "Batch" : "Sample"}{" "}
            {refSample.id + 1} Information
          </Typography>
        </Grid>

        {filteredGeneralInputs.length > 0 ? 
          <InputObjRender
            checkRequirements={checkRequirements}
            sourceDataObj={general}
            setUserInput={setUserInput}
            objKey={"general"}
            data={data}
            userInput={userInput}
            disabled={disabled}
            handleInput={handleInput}
            sampleIndex={sampleIndex}
            filteredInputs={filteredGeneralInputs}
          />
        : null}


        <Grid item xs={12} md={12} className="formHeaderContainer">
          <BiotechIcon className="formHeaderIcon" />
          <Typography component="h5" variant="h5">
            Testing Requests
          </Typography>
        </Grid>

        {checkRequirements && !refSample.activeTests ? (
          <Grid item xs={12} md={12} className="formHeaderContainer">
            <ErrorIcon className="formHeaderIcon" color="error" />
            <Typography color="red" component="body" variant="body">
              Must Select Testing
            </Typography>
          </Grid>
        ) : null}

        <TestingBody
          checkRequirements={checkRequirements}
          renderData={testing}
          userInput={userInput}
          setUserInput={setUserInput}
          refSample={refSample}
          data={data}
          formMode={mode}
          samplingMode={samplingMode}
        />

        <div role="presentation" style={{ width: "100%"}}>
      
        <Paper elevation={3} sx={{m: '15px', p: '15px', textAlign: 'left'}}>

          <Typography variant="h6" sx={{marginBottom: "15px"}}>Additional Information</Typography>
          
        <Grid container spacing={1}>

          <InputObjRender
            checkRequirements={checkRequirements}
            sourceDataObj={additional}
            setUserInput={setUserInput}
            objKey={"additional"}
            data={data}
            userInput={userInput}
            disabled={disabled}
            handleInput={handleInput}
            sampleIndex={sampleIndex}
            filteredInputs={filteredAdditionalInputs}
          />
        </Grid>

        </Paper>
        </div>


        <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            color="success"
            onClick={() => setDuplicateOpen(true)}
          >
            Create Copy(s)
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setDeleteOpen(true)}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
      <DeleteSample
        handleDeleteSample={handleDeleteSample}
        open={deleteOpen}
        setOpen={setDeleteOpen}
      />
      <DuplicateSample
        handleDuplicateSample={handleDuplicateSample}
        open={duplicateOpen}
        setOpen={setDuplicateOpen}
      />

      <SampleAmount open={samAmtOpen} setOpen={setSamAmtOpen} />
    </Paper>
  );
};

export default SampleDataForm;
