import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DuplicateSample = ({ open, setOpen, handleDuplicateSample }) => {
    
  const [value, setValue] = React.useState(1)

  const handleInput = (e) => {
    const integerCheck = Number.isInteger(1 * e.target.value)
    
    if (integerCheck) {
      setValue(e.target.value)
    }
  }

  const handleConfirm = () => {
    handleDuplicateSample(value)
    setOpen(false)
    
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{maxWidth: "100vw"}}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Copy Sample Info"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Indicate how many copies of this sample/batch you wish to generate. All information already input will carry over and be given a generic name.
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description" style={{ marginTop: "15px", marginBottom: "15px"}} >
            Ensure all required fields are completed before copying a sample.
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-input" component="span">
            <TextField onChange={handleInput} value={value} id="standard-basic" label="Number of Copies" variant="standard" />
          </DialogContentText>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm}>Duplicate Now</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DuplicateSample;
