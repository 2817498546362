import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Typography } from '@mui/material';

// utils
import "../../utils/styles/cocStyle.css"

const ClientData = ({ userInput, data }) => {

    const clientSections = data.forms?.sections?.find(sec => sec.type === 'client')?.sub_sections

  return (
    <TableContainer >
        <Table>
        <TableBody >
        <TableRow>
            <td className="contactCell">
                <Typography className="contactInputleader" style={{fontWeight: "bold"}}>{clientSections[0].header || 'Company Details'}</Typography>     
            </td>
        </TableRow>


        <TableRow className="contactCellRow">
            <td className="contactCell" >
                <Typography className="contactInputleader" >Company: <span className="contactInputText">{userInput.companyName}{userInput.passKey ? <span style={{marginLeft: '15px', fontSize: '0.75em'}}>(Passkey: {userInput.passKey})</span> : ''}</span></Typography>
            </td>
        </TableRow>

        {userInput.reportingAlias ? 
        <TableRow className="contactCellRow">
        <td className="contactCell" >
            <Typography className="contactInputleader" >Reporting As: <span className="contactInputText">{userInput.reportingAlias}</span></Typography>
        </td>
        </TableRow>
        : null}

        <TableRow>
            <td className="contactCell" >
                <Typography className="contactInputleader" >Contact: <span className="contactInputText">{`${userInput.contactFirstName} ${userInput.contactLastName}`}</span></Typography>   
            </td>
        </TableRow>

        <TableRow>
            <td className="contactCell" >
                <Typography className="contactInputleader" >Street Address: <span className="contactInputText">{userInput.companyAddress1}{userInput.companyAddress2 ? `, ${userInput.companyAddress2}` : null}</span></Typography>                            
            </td>
        </TableRow>

        <TableRow>
            <td className="contactCell" >            
                <Typography className="contactInputleader" >{userInput.companyCity ? 'City, State, Zip' : 'Region'}: 
                    
                    <span className="contactInputText">
                        {userInput.companyCity ? 
                            `${userInput.companyCity}, ${data.references?.states?.find(item => item.LIMS_ID === userInput.companyState)?.STATE_SHORTHAND} ${userInput.companyZip}`
                        : 
                            `${userInput.internationalAddressLine}`
                        }
                    </span>

                </Typography>                
            </td>
        </TableRow>

        <TableRow>
            <td className="contactCell" >            
                <Typography className="contactInputleader" >Email: <span className="contactInputText">{userInput.clientEmail}</span></Typography>    
            </td>
        </TableRow>
        
        {userInput.pwsID ? 
        <TableRow>
            <td className="contactCell" >            
                <Typography className="contactInputleader" >PWS ID: <span className="contactInputText">{userInput.pwsID}</span></Typography>    
            </td>
        </TableRow>
        : null}
        

        {userInput.clientEmailcc ?
            <TableRow>
                <td className="contactCell" >            
                    <Typography className="contactInputleader" >Email, CC: <span className="contactInputText">
                            {userInput.clientEmailcc.split('\n').filter(em => em.length > 0).map((cc, i) => {
                                return cc + (i + 1 === userInput.clientEmailcc.split('\n').filter(em => em.length > 0).length ? "" : ", ")
                            })}
                        </span>
                    </Typography>    
                </td>
            </TableRow>
        : null}

        {userInput.clientPhone ? 
         <TableRow>
         <td className="contactCell" >
             <Typography className="contactInputleader" >Contact Phone: <span className="contactInputText">{userInput.clientPhone}</span></Typography>
         </td>
        </TableRow>
        : null}

       {userInput.companyPhone ? 
         <TableRow>
         <td className="contactCell" >
             <Typography className="contactInputleader" >Company Phone: <span className="contactInputText">{userInput.companyPhone}</span></Typography>
         </td>
        </TableRow>
        : null}


        <TableRow>
            <td className="contactCell" >
                <Typography className="contactInputleader" style={{fontWeight: "bold", marginTop: "5px"}}>Billing Information</Typography>            
            </td>
        </TableRow>

        {userInput.billingContactPhone ? 
         <TableRow>
         <td className="contactCell" >
             <Typography className="contactInputleader" >Billing Phone: <span className="contactInputText">{userInput.billingContactPhone}</span></Typography>
         </td>
        </TableRow>
        : null}

        {userInput.billingEmail ? 
         <TableRow>
         <td className="contactCell" >
             <Typography className="contactInputleader" >Billing Email: <span className="contactInputText">{userInput.billingEmail}</span></Typography>
         </td>
        </TableRow>
        : null}

    </TableBody>
        </Table>
    </TableContainer>
    
  );
}

export default ClientData;