import React, { useEffect } from 'react';

// components
import AdminLogin from './Admin/Standard/AdminLogin';
import FormManager from './FormEditor/FormManager';

const FormEditor = () => {
    
    const [loading, setLoading] = React.useState(false)
    const [permission, setPermission] = React.useState(false)

    useEffect(() => {
      const localPermission = JSON.parse(localStorage.getItem('formeditor'))
      if (localPermission?.permission) {
        if (localPermission.date === new Date().getDate()) {
          setPermission(true)
        } else {
          localStorage.removeItem('formeditor');
        }
        
      }
      
    }, [permission])



    return (
    <>   
        {loading ? 
        
        "Loading" 
        
        : 
        
        <>

          {permission ?           
            <>
              <FormManager />
            </>
          : 
            // get access here
            <AdminLogin setPermission={setPermission} setLoading={setLoading} target={'formEditor'} loading={loading} />
          }

        </>
        
        }

        


        
    </>
        
    )

}

export default FormEditor;
