import React from 'react';

// components
import TestingBody from '../testing/TestingBody';

// mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BiotechIcon from "@mui/icons-material/Biotech";

// styles
import '../../../utils/styles/styleSheet.css';

const Materials = ({ userInput, data, setUserInput, checkRequirements, mode, handleRemoveSample, handleNewSample, maxPages, pageSamples, setPageSamples }) => {
    
    const testing = data.forms?.sections?.find(f => f.type === "testing") || false

    return (
        <>
        <Grid item xs={0} md={2} lg={3} >                
          </Grid>
            <Grid item xs={12} md={8} lg={6}>
            <Paper elevation={2} className="formCanvas">
          <Grid container spacing={1}>

          <Grid item xs={12} md={12} className="formHeaderContainer">
            <BiotechIcon className="formHeaderIcon" />
            <Typography component="h5" variant="h5">
              Material & Testing Requests
            </Typography>
          </Grid>

          <TestingBody
            checkRequirements={checkRequirements}
            renderData={testing}
            userInput={userInput}
            setUserInput={setUserInput}
            refSample={false}
            data={data}
            formMode={mode}
          />

      </Grid>
    </Paper>


            </Grid>
        <Grid item xs={0} md={2} lg={3}>       
        </Grid>
        
        </>

    
        
    )

}

export default Materials;
