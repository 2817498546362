import React from 'react';

// mui
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const styles = {
  cell: { padding: "1px", fontSize: "0.7em", backgroundColor: "white", border: "1px solid black" },
  cellHeader: { fontWeight: "bold", padding: "1px", fontSize: "0.7em", borderRight: "1px solid black" },
}

const SampleDataTable = ({ specifiedData }) => {
  
    return (
      <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
        <TableContainer >
        <Table size="small" aria-label="a dense table">

          <TableBody>

            <TableRow>

              <TableCell style={styles.cellHeader} align="center">CL Sample ID</TableCell>
              <TableCell style={styles.cell} align="center">{specifiedData.samplingData.columbiaSampleID}</TableCell>

              <TableCell style={styles.cellHeader} align="center">METRC ID</TableCell>
              <TableCell style={styles.cell} align="center">not being taken yet</TableCell>
            
            </TableRow>

          <TableRow>

          <TableCell style={styles.cellHeader} align="center">Product/Strain ID</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.batchName}</TableCell>

            <TableCell style={styles.cellHeader} align="center">Sample Type</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.samplingDuplicate}</TableCell>
                

          </TableRow>

          <TableRow>
            
  

            <TableCell style={styles.cellHeader} align="center">Product type</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.matrixGroup.name}</TableCell>

          </TableRow>

          <TableRow>

            <TableCell style={styles.cellHeader} align="center">Harvest/Prod Date</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.harvestProductionDate}</TableCell>

            <TableCell style={styles.cellHeader} align="center">Batch Size</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.batchSizeUnit === "units for sale" ? specifiedData.amountKgs + " units for sale" : specifiedData.amountKgs.toFixed(3) + " kg"}</TableCell>

          </TableRow>

          <TableRow>
            
            <TableCell style={styles.cellHeader} align="center">Container Type</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.containerType}</TableCell>

            <TableCell style={styles.cellHeader} align="center">Count of Containers</TableCell>
            <TableCell style={styles.cell} align="center">{specifiedData.sampleData.containerCount}</TableCell>

          </TableRow>

            
            
              

          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    )

}

export default SampleDataTable;
