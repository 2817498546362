import React from 'react';
import { cloneDeep } from 'lodash';

// components

// mui
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import Link from "@mui/material/Link";
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:'#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%'
}));

const TopClientEditor = ({ data, setData }) => {
  
    const handleInput = (e) => {
        const newData = cloneDeep(data)
        newData.forms.pageHeader = e.target.value
        setData(newData)
    }
    
    const handleCheck = (e, cat) => {
        const newData = cloneDeep(data)
        newData.forms[cat] = !newData.forms[cat]
        setData(newData)
    }

  return (
            <Item>
                <Typography variant="h5">Top Level Form Settings</Typography>
                
                <Button
                    component={Link}
                    href={`${window.location.href
                        .replace("formeditor", data.serviceLine)}/`}
                    style={{ fontSize: "0.75em", color: "black", border: '0px solid black', backgroundColor: 'lightgreen' }}
                    target="_blank"
                    >
                    Go to Service Line
                </Button>

                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Page Header
                    </InputLabel>
                    <Input
                        id="input-page-header"
                        value={data.forms.pageHeader}
                        onChange={handleInput}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Divider style={{margin: '15px'}} />
                
                <Typography>
                    Bottles - Do you want this service line to show and allow users to input Bottle Lot IDs?
                </Typography>
                <FormControlLabel
                    label="YES BOTTLES"
                    control={<Checkbox checked={data.forms?.bottlesMenu} onChange={(e) => handleCheck(e, 'bottlesMenu')} />}
                />

                {/* 
                
                CONSIDER: The COC items below are HTML based. We can try to create HTML here using a WZYWIG editor which we will add when we add notes. 

                Keep these comments here as a reminder to do this eventually.
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Page Header
                    </InputLabel>
                    <Input
                        id="input-coc-header"
                        value={data.forms.pageHeader}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Page Header
                    </InputLabel>
                    <Input
                        id="input-coc-footer"
                        value={data.forms.pageHeader}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                    />
                </FormControl> */}

                
            </Item>
  );

}

export default TopClientEditor;
