import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';

// components
import COCRender from '../../COCRender/COCRender.js';
import InputObjRender from '../../Form/InputObjRender.js';
import ProjectNotes from '../ProjectNotes.js';
import BottlesForm from '../../Form/testing/BottlesForm.js';
import LabUse from '../../dialogues/LabUse.js';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import headerIcons from '../../Form/headerIcons.js';
import FormAPI from '../../../utils/API/api-form.js';

const GeneralSamplingEvent = ({ loading, userData, setUserData, setMode, formData, createSamplingDuplicates, error, setError }) => {

    const [changeDetected, setChangeDetected] = React.useState(false)
    const [entryError, setEntryError] = React.useState(false)
    const [loadingLocal, setLoadingLocal] = React.useState(false)
    const [labUseOpen, setLabUseOpen] = React.useState(false)

    const handleBackToMain = () => {
      setMode('admin')
      setError(false) 
      setEntryError(false)
      setChangeDetected(false)
      window.history.pushState('page2', 'Title', '/admin/')
    }

    const samplingForm = formData?.forms?.sections?.find(s => s.type === 'samplingEvent' && s.samplingEventKey === userData.submissionType.split(',')[1])

    const handleGeneralSamplingInput = (e, cat) => {
      const newUserData = cloneDeep(userData)

      if (cat === 'tempurature' && !validator.isNumeric((e.target.value * 10).toString())) {
        return
      }
      newUserData.samplingPerson = { ...newUserData.samplingPerson || {}, [cat]: e.target.value }
      setUserData(newUserData)
      setChangeDetected(true)
    }

    const handleInput = (e, key, alt, sample) => {
      const useValue = (e.target?.value || e) || alt

      // need to jam these into the "sampling Event Data" object so it shows up on coc
      const newUserData = cloneDeep(userData)
      if (!newUserData.samplingEventInputs) {
        newUserData.samplingEventInputs = []
      } 

      const foundInput = newUserData.samplingEventInputs.find(sei => sei.id === sample)
      if (foundInput) {
        foundInput[key] = useValue
      } else {
        newUserData.samplingEventInputs = [...newUserData.samplingEventInputs, {
          id: sample,
          [key]: useValue
        }]
      }

      setUserData(newUserData)
      setChangeDetected(true)
    }

    const saveData = async (submissionData, status) => {
      setLoadingLocal(true)
      setEntryError(false)

      // api to save data here
      const sendData = submissionData || cloneDeep(userData);
      sendData.status = status || 'sampling-saved';
      try {
        const res = await FormAPI.saveClientSubmission({data: {
          userJSON: sendData
        }})

        setUserData(res.data)
        setChangeDetected(false)

        if (res.status === 200 && status === 'sampling-complete') {
          // ship email only on complete
          await FormAPI.submitClientDataEmail({ data: res.data, type: 'samplingEvent' })
        }
        
      } catch (err) {
        console.log(err)
        setEntryError('Saving Failed, could not connect to database! Try again. Contact IT if issue persists.')
      }
      setLoadingLocal(false)

    }
    
    const completesampling = (status) => {
      setEntryError(false)

      const submissionData = cloneDeep(userData)
      submissionData.samplingPerson.date = new Date();
      // check requirements here  
      const requiredInputs = samplingForm.sub_sections.map(ss => {
        return ss.inputs.map(input => {
          const dependencyCheck = input.dependency
          return input.required?.key && !dependencyCheck ? input.input_key : false
        })
      }).flat().filter(input => input)
      let checkError = !submissionData.samplingPerson.name
      requiredInputs.forEach(ri => {
        submissionData.samplingEventInputs?.forEach(sei => {
          if (!sei[ri] || sei[ri].length < 1) {      
            checkError = true;
          }
        })
      })

      if (checkError && status === 'sampling-complete') {
        setEntryError('Not all fields filled!')
      } else {
        // submit here through API
        submissionData.signatures = [...submissionData.signatures, {name: submissionData.samplingPerson.name, dateTime: submissionData.samplingPerson.date, staff: 'sampler', type: 'receive', tempurature: submissionData.samplingPerson.tempurature, cooling: 'NA' }]
        saveData(submissionData, status)

      }
    }

    const samplingComplete = userData.signatures?.find(s => s.staff === 'sampler' && s.type === 'receive')
    
    return (
      <Box
        component="form"
        autoComplete="off"        
        style={{backgroundColor: 'rgb(100,200,100,0.10)', minHeight: '100vh'}}
      >
        <LabUse data={formData} userInput={userData} open={labUseOpen} setOpen={setLabUseOpen} setUserInput={setUserData} samplingMode={true} /> 

        {loading ? "Loading..." : 
        <>

        {!userData.submissionID ? "Almost done loading..." : null}

        {error === "noFIDData" ? 
          <>The Form ID provied could not be found. Please check URL.</>
        : null}

        {error && error !== "noFIDData" ? <h4 style={{color: 'red'}}>{error}</h4> : null}
        
        <Grid container spacing={2} style={{ padding: '30px'}}>

          <Grid item xs={12}>
              {/* Toolbar / Menu */}
              <Button onClick={handleBackToMain}>Back to Admin Home</Button>
          </Grid>
       
        

        {/* Render Tools Below */}
        
        {userData.submissionID ? 
        <>  
            <Grid item xs={12} md={9}>
              
              <Paper elevation={3} style={{marginBottom: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
                <Typography>{samplingForm?.samplingEventName} Order Summary on {userData.submissionID}</Typography>
                <Typography>
                  <a href={`${window.location.origin}/${userData.serviceLine}/${encodeURIComponent(userData.submissionID)}`} target="_blank" rel="noopener noreferrer">Client Review Page</a>
                </Typography>
                
              </Paper>
              <COCRender setMode={setMode} userInput={userData} setUserData={setUserData} data={formData} labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} createSamplingDuplicates={createSamplingDuplicates} samplingMode={true} showBottles={true} />
            </Grid>


            <Grid item xs={12} md={3}>
              
            <Paper elevation={3} style={{padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
                          
            <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                {userData.samplingData?.samplingStartedName && userData.status.includes('sampling') ? 
                  <Typography>Sampling Started by {userData.samplingData?.samplingStartedName} on {userData.samplingData?.samplingDateTime}</Typography>
                : 
                  <>
                    <TextField   
                      style={{ width: '50%' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      label={<span>Name<span style={{color: "red"}}>*</span></span>}
                      error={entryError && !userData.samplingData?.samplingStartedName ? true : false}
                      size="small"
                      value={userData.samplingData?.samplingStartedName || ''}
                      onChange={(e) => handleInput(e, 'samplingStartedName')}
                  />
                  <Button disabled={!userData.samplingData?.samplingStartedName} style={{ width: '45%', height: '40px' }} variant="contained" color="warning" onClick={() => completesampling('sampling-started')} >Begin sampling</Button>
                  </>
                }
              </Grid> */}
            
              <Grid item xs={12} md={12} className="formHeaderContainer" justifyContent='center'><Typography component='h5' variant='h5'>Sampling Event Fulfillment Form</Typography></Grid>
              
              {userData.samples.map((sample, i) => {
                const sampleInput = (e, key, alt) => {
                  handleInput(e, key, alt, sample.id)
                }
                return (
                  <Grid item xs={12} md={12} key={'fulfillment-'+i} style={{borderBottom: i < userData.samples.length - 1 ? '3px grey solid' : 'none', paddingBottom: '20px', margin: '5px'}} >
                    
                    <Typography component='h5' variant='h5'>Sample {i + 1}</Typography>

                    {samplingForm?.sub_sections.map(ss => {

                      const useIcon = headerIcons.find(ic => ic.key === ss.icon)  
                      const useInputs = cloneDeep(userData)
                      useInputs.samples = useInputs.samples.map(uis => {
                        const findSei = useInputs.samplingEventInputs?.find(sei => sei.id === uis.id)
                        return {...uis, ...findSei || {}}
                      })
                      
                      return (
                        <Grid container spacing={1} key={ss.header}>
                          
                          <Grid item xs={12} md={12} className="formHeaderContainer" justifyContent='center'>{useIcon ? useIcon.component : null}<Typography component='p' variant='p'>{ss.header}</Typography></Grid>
                          <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>                              
                            
                            <InputObjRender
                              checkRequirements={entryError}
                              sourceDataObj={ss}
                              setUserInput={setUserData}
                              objKey={ss.key}
                              data={formData}
                              userInput={useInputs}
                              disabled={userData.status === 'sampling-complete' ? 'force' : false}
                              handleInput={sampleInput}
                              sampleIndex={sample.id}
                            />
                            <div style={{ margin: 'auto', paddingLeft: '15px'}}>
                              <BottlesForm checkRequirements={entryError} refSample={sample} data={formData} userInput={useInputs} setUserInput={setUserData} disabled={userData.status === 'sampling-complete'} />
                            </div>
                            </Grid>
                          </Grid>
                        
                        </Grid>
                      )
                      })}


                  </Grid>
                )
              })}

            </Grid>
            </Paper>
              
          <Paper elevation={3} style={{marginTop: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
            {!userData.status !== 'sampling-complete' ?
            <>
              <TextField   
                style={{ width: '50%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={<span>Name<span style={{color: "red"}}>*</span></span>}
                error={entryError && !userData.samplingPerson?.name ? true : false}
                size="small"
                disabled={samplingComplete}
                value={userData.samplingPerson?.name || ''}
                onChange={(e) => handleGeneralSamplingInput(e, 'name')}
              />

              <TextField
                style={{ width: '50%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={<span>Samples Tempurature<span style={{color: "red"}}> (at sampler discretion)</span></span>}
                error={entryError && !userData.samplingPerson?.name ? true : false}
                size="small"
                disabled={samplingComplete}
                value={userData.samplingPerson?.tempurature || ''}
                onChange={(e) => handleGeneralSamplingInput(e, 'tempurature')}
              />

              <Button disabled={samplingComplete || !userData.samplingPerson?.name || loadingLocal} style={{ width: '100%', height: '40px', marginTop: '5px' }} variant="contained" color="success" onClick={() => completesampling('sampling-complete')} >Complete Sampling</Button>

              {entryError && !userData.samplingPerson?.name ? <FormHelperText style={{color: 'red'}}>Required Entry</FormHelperText> : null}

              <p>An email will be sent when sampling is complete</p>
              <Button variant='outlined' onClick={() => {saveData(false, false)}} disabled={!changeDetected}>Save for Later</Button>
                
              <Typography style={{color: 'red'}}>{entryError}</Typography>
              {loadingLocal ? <>Committing...<br /><LinearProgress /></> : null}
            </>
            : 
            <>
              <Typography>sampling Complete</Typography>
              <Typography>{userData.samplingPerson?.name}</Typography>
              <Typography>{userData.samplingPerson?.date}</Typography>
            </>
            } 
            </Paper>
            

            <br />
            <ProjectNotes userInput={userData} />  

            </Grid>
        </>
        : null}



        </Grid>
        </>
        }

        
      </Box>
    )

}

export default GeneralSamplingEvent;
