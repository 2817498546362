import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';

const TestGroupMenu = ({ testingData, data, setData, active, setActive, deleteGroup, setDeleteGroup, updateValue, group }) => {
 
    const handleSave = () => {
        console.log('in here')
        const newData = cloneDeep(data)
        const newTestingData = cloneDeep(testingData)
        
        newTestingData.testing_groups = newTestingData.testing_groups.map(u => {
        
            const check = u.group === active.group

            if (check) {
                const useGroup = active
                useGroup.group = useGroup.tempName ?? useGroup.group
                delete useGroup.tempName
                return useGroup
            }

            return u
        })

        // account for new client testing group
        if (active.group === "clientTesting" && !testingData.testing_groups.find(g => g.group === "clientTesting")) {
            console.log('deal with this')
            console.log(active)
            newTestingData.testing_groups.push(active)

        }
        console.log(newTestingData.testing_groups)

        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newTestingData.type ? newTestingData : form
        })
        setData(newData)
        setDeleteGroup(false)
    }

    const handleDeleteGroup = () => {
        const newData = cloneDeep(data)
        const newTestingData = cloneDeep(testingData)
        newTestingData.testing_groups = newTestingData.testing_groups.filter(u => u.group !== active.group);
        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newTestingData.type ? newTestingData : form
        })
        setData(newData)
        setDeleteGroup(false)
        setActive(newTestingData.testing_groups[0])
    }
        
    const detectChange = JSON.stringify(testingData.testing_groups?.find(g => g.group === active.group)) === JSON.stringify(active)

    return (
        <>        

            {active.group === group.group ?   
                <FormControl variant="standard" style={{width: "500px", padding: '15px'}}>
                    
                    <Input 
                        value={active.tempName ?? active.group}
                        disabled={active.group === 'clientTesting'}
                        onChange={(e) => {
                            updateValue("editName", e.target.value);
                        }}
                    />

                    <FormHelperText id="component-helper-text">
                        {active.group === 'clientTesting' ? 'The tab will show the passkey, no name required here' : 'Group Name (type above to edit)'}                        
                    </FormHelperText>

                </FormControl>
            :                
                <Typography>{group.group}</Typography>  
            }
            
            {active.group === group.group ? 
                <>  
                    <br />

                    {deleteGroup ? 
                        <>
                            <p>Are you sure you want to delete this entire group?</p>
                            <Button onClick={() => setDeleteGroup(false)}>Cancel</Button>
                            <Button onClick={handleDeleteGroup}>Delete Group</Button>
                        </>
                    : 
                        <>
                            <Tooltip title="Move Group Left">
                                <IconButton disabled={group.order === 1 || group.group === "clientTesting"} edge="end" aria-label="delete" onClick={() => updateValue("testGroupOrder", -1, group)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Group Right">
                                <IconButton disabled={group.order === testingData.testing_groups.length || group.group === "clientTesting"} edge="end" aria-label="delete" onClick={() => updateValue("testGroupOrder", 1, group)}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                            <Button onClick={handleSave}
                                disabled={detectChange}
                                variant="outlined"
                                style={{marginLeft: '10px'}}
                            >
                                Save Changes
                            </Button>
                            <Button onClick={() => setDeleteGroup(true)} style={{float: 'right'}} disabled={group.group === "clientTesting"}>Delete Group</Button>
                            {group.group === "clientTesting" ? <p>This group can&lsquo;t be deleted, just don&lsquo;t put testing here if you don&lsquo;t want to use it</p> : null}
                        </>
                    }
                </>
            : 
                <Button onClick={() => setActive(group)} >Enable Edit Package</Button>
            }


        </>
    );

}

export default TestGroupMenu;
