const reSortGroupedTesting = (testList, data) => {

    // return item
    let sortedTests = []
    
    // sort into groups by parent
    const parents = testList.filter(at => at.consolidateTarget === 'parent')
    // sort by display name
    parents.sort((a, b) => {

        const bottlesListA = data.testing[a.type + 's']?.filter(tt => tt.LIMS_ID === a.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)
        const bottlesListB = data.testing[b.type + 's']?.filter(tt => tt.LIMS_ID === b.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)

        const bottlesUniqueA = [...new Set(bottlesListA)]
        const bottlesUniqueB = [...new Set(bottlesListB)]

        return bottlesUniqueB.length - bottlesUniqueA.length
    })

    // sort parents by count of bottles, count of uniques
    
    parents.forEach(p => {
        const children = testList.filter(at => at.consolidateTarget === p.LIMS_ID)
        const fullGroup = [p, ...children]

        // sort by display name
        fullGroup.sort((a, b) => {
            const testA = data.testing[a.type + 's']?.find(tt => tt.LIMS_ID === a.LIMS_ID);
            const testB = data.testing[b.type + 's']?.find(tt => tt.LIMS_ID === b.LIMS_ID);

            const bottlesListA = data.testing[a.type + 's']?.filter(tt => tt.LIMS_ID === a.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)
            const bottlesListB = data.testing[b.type + 's']?.filter(tt => tt.LIMS_ID === b.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)

            const bottlesUniqueA = [...new Set(bottlesListA)]
            const bottlesUniqueB = [...new Set(bottlesListB)]

            if (bottlesUniqueA.length === bottlesUniqueB.length) {
                return testA.DISPLAYNAME.localeCompare(testB.DISPLAYNAME)
            } else {
                return bottlesUniqueB.length - bottlesUniqueA.length
            }
        })

        // reset parent target
        fullGroup.forEach((gi, i) => {
            gi.consolidateTarget = i === 0 ? 'parent' : fullGroup[0].LIMS_ID
        })

        // concat
        sortedTests = [...sortedTests, ...fullGroup]
    })
    
    return sortedTests;
}

export default reSortGroupedTesting;
