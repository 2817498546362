import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const UseAsAbove = ({ data, setData, selected, currentForm }) => {
    
    const currentSubSection = currentForm.sub_sections.find(ss => ss.sectionKey === selected)
    const childrenKeys = currentSubSection.inputs.map(ss => ss.input_key)

    const toggleChecked = () => {   
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newSubSection = cloneDeep(currentSubSection)
        if (currentSubSection.useAsAbove) {         
            newSubSection.useAsAbove = false
            
        } else {
            newSubSection.useAsAbove = true
        }

        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newSubSection.sectionKey ? newSubSection : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newForm.type ? newForm : form
        })
        
        setData(newData)
    }

    return (
        <>
            <Typography variant="h5"  style={{float: "Left"}}>Use As Above</Typography>
            <br /><br />        
            
            <Typography variant="p">This is the checkbox that appears above a section of forms that suggests to client to &quot;use as above&quot; for similar data sets.</Typography>
            <br /><br />      

            <Typography variant="p">For best results every item in the group should have a key to mirror when selected. For example, Billing Address would have a mirror key of Company Address. To add or change a mirrorKey please select the item on the left and do so there. <span style={{color: 'red', fontWeight: "bold"}}>If a REQUIRED field lacks a mirror key the submission cannot progress, effecitvely breaking the form!!</span></Typography>
            <br /><br />      
            
            <FormControlLabel
                label="Use As Above Button?"
                control={
                    <Checkbox
                        checked={currentSubSection.useAsAbove}
                        onChange={toggleChecked}
                    />
                }
            />            
            <br /><br />      
            
            {currentSubSection.useAsAbove ? 
            <>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            
                        <TableHead>
                            <TableRow>
                                <TableCell>key</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Mirror Key</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                                
                        {childrenKeys.map((row, i) => {
                            const refInputParent = currentForm.sub_sections?.find(ss => ss.inputs.find(input => input.input_key === row))
                            const refItem = refInputParent?.inputs?.find(input => input.input_key === row)
                            return (
                                <TableRow
                                    key={selected + "-" + row + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row}                 
                                    </TableCell>
                                    <TableCell>{refItem.label}</TableCell>
                                    <TableCell>{refItem.mirrorKey || (refItem.required?.key ? <span style={{color: 'red', fontWeight: "bold"}}>This is a required field, be sure to give it a mirrorKey!</span> : "Not a required field, mirrorKey not mandatory.")}</TableCell>
                                </TableRow>
                            )
                        })}

                        </TableBody>

                    </Table>
                </TableContainer>
            </>
            : null}
        </>                              
    );

}

export default UseAsAbove;
