import React from 'react';

// components
import ClientDataForm from './ClientDataForm';
import Samples from './Samples/Samples';
import Materials from './Materials/Materials';

// mui
import Grid from '@mui/material/Grid';
import Submission from './Submission';

// styles
import '../../utils/styles/styleSheet.css';

const Form = ({ userInput, data, setUserInput, page, setPage, checkRequirements, handleRemoveSample, handleSubmit, handleNewSample, disabled, setDisabled, maxPages, pageSamples, setPageSamples, submissionErrors, entryMode, globalLoading, samplingMode }) => {

    return (
        <>
        {/* Introduction : temporary removal*/}
        {/* <Grid item xs={0} md={3}>                
        </Grid>
        <Grid item xs={12} md={6}>                
                <Introduction />
        </Grid>   
        <Grid item xs={0} md={3}>                
        </Grid> */}

        {/* Client General Data : serviceLine Must be picked before samples show up */}
        <Grid item xs={0} md={2} lg={3}>                
        </Grid>
        <Grid item xs={12} md={8} lg={6}>                
                <ClientDataForm 
                    data={data} 
                    userInput={userInput} 
                    setUserInput={setUserInput} 
                    page={page} 
                    setPage={setPage} 
                    checkRequirements={checkRequirements} 
                    disabled={disabled} 
                    setDisabled={setDisabled} 
                    mode={entryMode}               
                    samplingMode={samplingMode}     
                />                
        </Grid>   
        <Grid item xs={0} md={2} lg={3}>
        </Grid>

        {entryMode === 'samples' && !globalLoading ? 
            <Samples userInput={userInput} data={data} setUserInput={setUserInput} setPage={setPage} checkRequirements={checkRequirements} handleRemoveSample={handleRemoveSample} handleNewSample={handleNewSample} maxPages={maxPages} pageSamples={pageSamples} setPageSamples={setPageSamples} mode={entryMode} samplingMode={samplingMode} />
        : null}

        {entryMode !== 'samples' && !globalLoading? 
            <Materials userInput={userInput} data={data} setUserInput={setUserInput} setPage={setPage} checkRequirements={checkRequirements} handleRemoveSample={handleRemoveSample} handleNewSample={handleNewSample} maxPages={maxPages} pageSamples={pageSamples} setPageSamples={setPageSamples} mode={entryMode} />
        : null}
        
        <Grid item xs={0} md={3} id={`submit`}>                
        </Grid>
        <Grid item xs={12} md={6} >   
                <Submission data={data} checkRequirements={checkRequirements} userInput={userInput} handleSubmit={handleSubmit} setPage={setPage} submissionErrors={submissionErrors} setUserInput={setUserInput} mirrored={disabled} samplingMode={samplingMode} />
        </Grid>   
        <Grid item xs={0} md={3}>                
        </Grid>

        


    </>
        
    )

}

export default Form;
