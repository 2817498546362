import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import GeneralSamplingData from './GeneralSamplingData.js';
import SampleWorksheet from './SampleWorksheet.js';
import BalanceValidator from './BalanceValidator/BalanceValidator.js';
import ReferenceDisplay from './ReferenceDisplay.js';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import Button from '@mui/material/Button';

// utils
import FormAPI from '../../../utils/API/api-form.js';
import ratioCalculator from '../../../utils/functions/ratioCalculator.js';

const SamplingWorksheetsMain = ({ data, userData, setUserData, references }) => {

    const [currSam, setCurrSam] = React.useState(1)
    const [samplingInput, setSamplingInput] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const sampleData = userData.samples[currSam - 1]
    const matrixName = data.references.materials.find(u => u.LIMS_ID === sampleData.matrix)?.DISPLAYNAME || sampleData.matrix;
    const matrixGroup = references.matrixConversions.find(g => g.options.indexOf(matrixName) > -1);
    const incrementsTable = references.samplingIncrementRanges[matrixGroup.name];    

    const unitName = data.references.units.find(u => u.LIMS_ID === sampleData.batchSizeUnit)?.DISPLAYNAME || sampleData.batchSizeUnit;
    const unitRef = references.massUnitConversions.find(unit => unit.unit === unitName)
    const amountKgs = unitRef.factor * sampleData.batchSize
    const rangeRef = incrementsTable.find(range => amountKgs >= range.min && amountKgs <= range.max) ? incrementsTable.find(range => amountKgs >= range.min && amountKgs <= range.max) : incrementsTable[incrementsTable.length - 1]
    
    const samplingData = userData.samplingEventInputs.find(inp => inp.id === sampleData.id)
    const targetMass = rangeRef.total.rule ? ratioCalculator(rangeRef.total, amountKgs) : rangeRef.total.toFixed(1)
    
    const specifiedData = {
      sampleData: sampleData,
      samplingData: samplingData,
      matrixGroup: matrixGroup,
      rangeRef: rangeRef,  
      amountKgs: amountKgs,
      targetMass: targetMass
    }

    const updateCurrSam = (dir) => {
        setCurrSam(currSam + dir)
    }
    
    
    const handleExit = () => {
      setSamplingInput(false)
    }
    
    const handleSave = async () => {            

      setError(false)   
      setLoading(true)
      // update userData, merge in new data for existing data
      const newUserData = cloneDeep(userData)
      const existingSamplingData = newUserData.samplingEventInputs.find(sImp => sImp.id === samplingInput.id)
      const newSamplingData = {...existingSamplingData, ...samplingInput}
      newUserData.samplingEventInputs.splice(newSamplingData.id, 1, newSamplingData)

      // send userData to back-end for saving
      try {
        const res = await FormAPI.saveClientSubmission({data: {
          userJSON: newUserData
        }})
  
        // handle errors here
        if (res.status === 200) {            
             
          setLoading(false)
          setUserData(res.data)
          setSamplingInput(false)
  
        } else {
            setLoading(false)
            setError("saveError")
        }  
      } catch (err) {
          setLoading(false)
          setError("connectionError")
      }
      
    }

    
    const handleCompleteSampling = async (bool) => {
      setError(false)   
      setLoading(true)

      // run through validations here when you can figure out how
      const newUserData = cloneDeep(userData)
      newUserData.status = bool ? "samplingComplete" : "samplingBalanceValidated"
      if (bool) {
        newUserData.samplingComplete = new Date()
      } else {
        delete newUserData.samplingComplete
      }

      // validations pass, do this, else make it not do this:
      try {
        const res = await FormAPI.saveClientSubmission({data: {
          userJSON: newUserData
        }})
  
        // handle errors here
        if (res.status === 200) {            
             
          setLoading(false)
          setUserData(res.data)
          setSamplingInput(false)
  
        } else {
            setLoading(false)
            setError("saveError")
        }  
      } catch (err) {
          setLoading(false)
          setError("connectionError")
      }
    }

    return (
      <Box>
        
        <Grid container spacing={3}>

          <Grid item xs={3}>
              {/* Order Properties */}
              <GeneralSamplingData data={data} userData={userData} setUserData={setUserData} />
              {userData.balanceEntries?.entries ? 
              <>
                <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>  

                {userData.status === "samplingComplete" || userData.status === "client-signed" ?
                <>
                <Typography variant="p"><CheckCircleOutlineIcon style={{color: "green", fontSize: "5em"}} /></Typography>               
                <Typography variant="h5" style={{color: "green"}}>Sampling Event Complete!</Typography>
                <Button disabled={userData.status === "client-signed"} style={{color: "red", fontSize: "0.5em", fontWeight: "bold"}}
                    onClick={() => {handleCompleteSampling(false)}}
                  >
                  undo
                </Button> 
                </>
                :
                <Button style={{color: "green", fontSize: "1em", fontWeight: "bold"}} endIcon={<NextWeekIcon style={{color: "green", fontSize: "5em"}} />}
                    onClick={() => {handleCompleteSampling(true)}}
                  >
                  Complete Sampling Event
                </Button>              
                }             

                </Box>   
              <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>                
                <Typography variant="p"><CheckCircleOutlineIcon style={{color: "green", fontSize: "5em"}} /></Typography>               
                <Typography variant="h5" style={{color: "green"}}>Balance Validated!</Typography>
              </Box>   
              

              <ReferenceDisplay targetMass={targetMass} incrementsTable={incrementsTable} rangeRef={rangeRef} samplingData={samplingData} />

              </>
            :
              null
            }

              





          </Grid>

          {/* Header Menu */}
          <Grid item xs={9}>
              
            {userData.balanceEntries?.entries ? 
              <SampleWorksheet
                handleSave={handleSave}
                handleExit={handleExit}
                error={error}
                references={references}
                loading={loading}
                updateCurrSam={updateCurrSam}
                currSam={currSam}
                setCurrSam={setCurrSam}
                samplingInput={samplingInput}
                setSamplingInput={setSamplingInput}
                specifiedData={specifiedData}
                userData={userData}
              />
            : 
              <BalanceValidator userData={userData} setUserData={setUserData} references={references} />
            }

          </Grid>
   
          
        </Grid>

      </Box>
    )

}

export default SamplingWorksheetsMain;
