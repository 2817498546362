import React from 'react';

// mui
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// utils
import formStyles from '../../../utils/styles/formStyles.js';
import checkComplexRequired from '../../../utils/functions/checkComplexRequired.js';

const UserRadioGroup = ({ data, value, refItem, disabled, handleInput, sampleIndex, checkRequirements, userInput }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''
    
    const requiredCheck = checkComplexRequired(value.required, userInput, sampleIndex)
    
    const isMirrored = disabled ? disabled : false
    if (value.mirrorKey && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : '' 
    }

    const renderError = checkRequirements && requiredCheck && useInput === ''    
    
    const handleInputCheck = (e) => {
        // special case for flipping this specific case. Could be generalized for "sampling" later on.
        // maybe think about de-serviceLineing -sampling
        if ((refItem.serviceLine === "cannabis" || refItem.serviceLine === "cannabis-sampling") && value.input_key === "samplesDelivery") {            
            if (e.target.value === "Sampling Event Request") {                
                window.history.pushState('page2', 'Title', '/cannabis-sampling');
            } else {                
                window.history.pushState('page2', 'Title', '/cannabis');
            }
        }

        handleInput(e, value.input_key)
    }

    return (
        <Grid item xs={value.xs} md={value.md}>
            <FormControl disabled={disabled} error={renderError}>
            <FormLabel id="demo-row-radio-buttons-group-label">{value.label}<span style={{color: "red"}}><span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span></FormLabel>
            <RadioGroup
                id={"sample-"+value.input_key}
                row   
                style={formStyles.dataInputRadio}
                onChange={handleInputCheck}    
            >
                {value.selection?.value.map((o, i) => {
                    const displayItem = value.selection.source === 'specified' ? o : data.references[value.selection.source]?.find(item => item.LIMS_ID === o)?.DISPLAYNAME
                    // return <MenuItem key={value.input_key+i} value={o} id={sampleIndex ? o+i+sampleIndex : o+i}>{displayItem || o}</MenuItem>
                    return <FormControlLabel key={value.input_key+i} value={o} control={<Radio />} label={displayItem || o} id={sampleIndex ? o+i+sampleIndex : o+i}/>
                })}
            </RadioGroup>
            
            {renderError ? <FormHelperText>Required Entry</FormHelperText> : null}
            </FormControl>
        </Grid>
    );
}

export default UserRadioGroup;
