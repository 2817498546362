import React from "react";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PinDropIcon from '@mui/icons-material/PinDrop';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Inventory2Icon from '@mui/icons-material/Inventory2';

// styles
import "../../../../utils/styles/styleSheet.css";

const PrepareYourSamples = () => {

  return (
    <>
    <div 
        style={{width: "100%", height: "20px", backgroundColor: "black", color: 'white', marginTop: "15px", padding: "3px"}}
      >
        <Typography style={{float: "left"}}>Prepare Samples for Relinquishment</Typography>
    </div>
    <Grid container style={{ margin: "auto", marginBottom: "35px" }}>
      
      <Grid item xs={12} md={6} sx={{ borderRight: {xs: '0px', md: '2px solid black'}, borderBottom: {xs: '2px solid black', md: '0px'} }}>
      <List dense={true}>
      <ListItem>
          <ListItemIcon>
            <Battery0BarIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Ensure Bottle Labels are complete and match ECOC below</Typography>}
          />
        </ListItem>
      <ListItem>
          <ListItemIcon>
            <Battery0BarIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Confirm secure closure of all bottles</Typography>}
          />
        </ListItem>
      <ListItem>
          <ListItemIcon>
            <Inventory2Icon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Most water samples require cold storage, please transport in a cooler with ice packs.</Typography>}
          />
        </ListItem>
      </List>
      </Grid>
      

      <Grid item xs={12} md={6} >
        
        <Alert severity="warning" style={{ backgroundColor: 'rgba(0,0,0,0)', color: 'black'}} icon={<WarningAmberIcon style={{ color: 'black' }}/>}>For samples that contain hazardous material, include safety data sheets (SDS) with samples.</Alert>
        <Divider color="black" style={{ marginBottom: '15px'}} />
        <Grid
          container
        >
          <Grid item xs={5}>
            <PinDropIcon style={{ fontSize: '2.5em', float: 'right', paddingRight: '15px'}}/>
          </Grid>
          <Grid item xs={7}>
            <Typography
              component="p"
              variant="p"
              style={{ fontSize: "1.00em", margin: "0 auto", float: 'left' }}
            >                    
              Columbia Laboratories
              <br />
              12423 NE Whitaker Way
              <br />
              Portland, OR 97230 USA
            </Typography>
          </Grid>

        </Grid>
      </Grid>


    </Grid>
  </>
  );
};

export default PrepareYourSamples;
