import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import MaterialsSummary from "../../MaterialsSummary/MaterialsSummary.js";
import InputObjRender from '../../Form/InputObjRender.js';
import ProjectNotes from '../ProjectNotes.js';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import headerIcons from '../../Form/headerIcons.js';
import FormAPI from '../../../utils/API/api-form.js';

const MaterialOrderFulfillment = ({ loading, userData, setUserData, setMode, formData, createSamplingDuplicates, error, setError, references }) => {
console.log(userData)
    const [changeDetected, setChangeDetected] = React.useState(false)
    const [entryError, setEntryError] = React.useState(false)
    const [loadingLocal, setLoadingLocal] = React.useState(false)

    const handleBackToMain = () => {
      setMode('admin')
      setError(false) 
      setEntryError(false)
      setChangeDetected(false)
      window.history.pushState('page2', 'Title', '/admin/')
    }

    const fulfillmentForm = formData?.forms?.sections?.find(s => s.type === 'materialOrder' && s.materialKey === userData.submissionType)

    const handleInput = (e, key) => {
      const newUserData = cloneDeep(userData)
      const materialData = newUserData.materialOrders[0]
      materialData[key] = e.target?.value || e
      setUserData(newUserData)
      setChangeDetected(true)
    }

    const saveData = async (submissionData, status) => {
      setLoadingLocal(true)
      setEntryError(false)

      // api to save data here
      const sendData = submissionData || cloneDeep(userData);
      sendData.status = status || 'fulfillment-saved';
      try {
        const res = await FormAPI.saveClientSubmission({data: {
          userJSON: sendData
        }})

        setUserData(res.data)
        setChangeDetected(false)

        if (res.status === 200 && status.includes('fulfillment-complete')) {
          // ship email only on complete
          await FormAPI.submitClientDataEmail({ data: res.data, type: 'materials', relinquishmentType: res.data.bottleRelinquishment })
        }
        
      } catch (err) {
        console.log(err)
        setEntryError('Saving Failed, could not connect to database! Try again. Contact IT if issue persists.')
      }
      setLoadingLocal(false)

    }
    
    const completeFulfillment = (status) => {
      setEntryError(false)

      const submissionData = cloneDeep(userData)
      const materialData = submissionData.materialOrders[0]

      materialData.fulfillmentDateTime = new Date();

      // check requirements here
      const requiredInputs = fulfillmentForm.sub_sections.map(ss => {
        return ss.inputs.map(input => {
          const dependencyCheck = input.dependency
          return input.required?.key && !dependencyCheck ? input.input_key : false
        })
      }).flat().filter(input => input)
      let checkError = !materialData.fulfillmentName
      requiredInputs.forEach(ri => {
        if (!materialData[ri] || materialData[ri].length < 1) {      
          checkError = true;

        }
      })

      if (checkError && status.includes('fulfillment-complete')) {
        setEntryError('Not all fields filled!')
      } else {
        // submit here through API
        saveData(submissionData, status)

      }
    }

    return (
      <Box
        component="form"
        autoComplete="off"        
        style={{backgroundColor: 'rgb(100,200,100,0.10)', minHeight: '100vh'}}
      >
        {loading ? "Loading..." : 
        <>       

        {!userData.submissionID ? "Almost done loading..." : null}

        {error === "noFIDData" ? 
          <>The Form ID provied could not be found. Please check URL.</>
        : null}

        {error && error !== "noFIDData" ? <h4 style={{color: 'red'}}>{error}</h4> : null}
        
        <Grid container spacing={2} style={{ padding: '30px'}}>

          <Grid item xs={12}>
              {/* Toolbar / Menu */}
              <Button onClick={handleBackToMain}>Back to Admin Home</Button>
          </Grid>
       
        

        {/* Render Tools Below */}
        
        {userData.submissionID ? 
        <>  
            <Grid item xs={12} md={9}>
              
              <Paper elevation={3} style={{marginBottom: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
                <Typography>{fulfillmentForm?.materialName} Order Summary on {userData.submissionID}</Typography>
                <Typography>
                  <a href={`${window.location.origin}/${userData.serviceLine}/${encodeURIComponent(userData.submissionID)}`} target="_blank" rel="noopener noreferrer">Client Review Page</a>
                </Typography>
                
              </Paper>
              <MaterialsSummary data={formData} userInput={userData} adminMode={true} />
            </Grid>


            <Grid item xs={12} md={3}>
              {fulfillmentForm?.sub_sections.map(ss => {

                const useIcon = headerIcons.find(i => i.key === ss.icon)  
                return (
                  <Paper elevation={3} key={ss.header} style={{padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
                                    
                
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {userData.materialOrders?.[0]?.fulfillmentStartedName && userData.status.includes('fulfillment') ? 
                          <Typography>Fulfillment Started by {userData.materialOrders?.[0]?.fulfillmentStartedName} on {userData.materialOrders?.[0]?.fulfillmentDateTime}</Typography>
                        : 
                          <>
                            <TextField   
                              style={{ width: '50%' }}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              disabled={userData.status.includes('fulfillment') || userData.status === 'saved'}
                              label={<span>Name<span style={{color: "red"}}>*</span></span>}
                              error={entryError && !userData.materialOrders?.[0]?.fulfillmentStartedName ? true : false}
                              size="small"
                              value={userData.materialOrders?.[0]?.fulfillmentStartedName || ''}
                              onChange={(e) => handleInput(e, 'fulfillmentStartedName')}
                          />
                          <Button disabled={!userData.materialOrders?.[0]?.fulfillmentStartedName} style={{ width: '45%', height: '40px' }} variant="contained" color="warning" onClick={() => completeFulfillment('fulfillment-started')} >Begin Fulfillment</Button>
                          </>
                        }
                      </Grid>
                    
                    <Grid item xs={12} md={12} className="formHeaderContainer">{useIcon ? useIcon.component : null}<Typography component='h5' variant='h5'>{ss.header}</Typography></Grid>

                    <InputObjRender 
                      data={formData}
                      checkRequirements={entryError} 
                      sourceDataObj={ss} 
                      objKey={ss.sectionKey} 
                      userInput={userData} 
                      disabled={userData.status.includes('fulfillment-complete') || userData.status === 'saved' ? 'force' : false} 
                      handleInput={handleInput} 
                      setUserInput={setUserData}
                      mode={false}
                    /> 
                    </Grid>
                  </Paper>
                )
              })}

          <Paper elevation={3} style={{marginTop: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
            {!userData.status.includes('fulfillment-complete') ?
            <>

                  <TextField   
                    style={{ width: '50%' }}
                    disabled={userData.status === 'saved'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={<span>Name<span style={{color: "red"}}>*</span></span>}
                    error={entryError && !userData.materialOrders?.[0]?.fulfillmentName ? true : false}
                    size="small"
                    value={userData.materialOrders?.[0]?.fulfillmentName || ''}
                    onChange={(e) => handleInput(e, 'fulfillmentName')}
                />
                <Button disabled={userData.status === 'saved'} style={{ width: '45%', height: '40px' }} variant="contained" color="success" onClick={() => completeFulfillment('fulfillment-complete')} >Complete Fulfillment</Button>

                {entryError && !userData.materialOrders?.[0]?.fulfillmentName ? <FormHelperText style={{color: 'red'}}>Required Entry</FormHelperText> : null}

                <p>After FULFILL is selected it will say something else like _great job_</p>
                <Button onClick={() => {saveData(false, false)}} disabled={!changeDetected || userData.status === 'saved'}>Save for Later</Button>
                
                <Typography style={{color: 'red'}}>{entryError}</Typography>
                {loadingLocal ? <>Committing...<br /><LinearProgress /></> : null}
            </>
            : 
            <>
              <Typography>Fulfillment Complete</Typography>
              <Typography>{userData.materialOrders?.[0]?.fulfillmentName}</Typography>
              <Typography>{userData.materialOrders?.[0]?.fulfillmentDateTime}</Typography>

              {userData.bottleRelinquishment === 'Pick Up at Laboratory' && userData.status === 'fulfillment-complete' ?
                <Button disabled={loadingLocal} style={{ width: '100%', marginTop: '10px'}} variant='contained' color='success' onClick={() => completeFulfillment('fulfillment-complete-picked-up')} >Mark as Picked Up</Button>
              : null }

             {userData.status === 'fulfillment-complete-picked-up' ?
                <Typography style={{fontWeight: 'bold', marginTop: '10px'}}>Client Picked Up!</Typography>
              : null } 

              {loadingLocal ? <>Committing...<br /><LinearProgress /></> : null}
            </>
            } 
            </Paper>
            

            <br />
            <ProjectNotes userInput={userData} />  

            </Grid>
        </>
        : null}



        </Grid>
        </>
        }

        
      </Box>
    )

}

export default MaterialOrderFulfillment;
