const dateHandlers = {
    standardDate: function (received) {

        const inputDate = new Date(received)
        // takes fs date/time format to readable format
        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    },
    standardTime: function (received) {

        var today = new Date(received);
        var hh = today.getHours();
        var mm = today.getMinutes();
        
        return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}`;
    }
}


export default dateHandlers;

