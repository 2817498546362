import React from 'react';

// mui
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// utils
import formStyles from '../../../utils/styles/formStyles.js';
import individualValCheck from '../../../utils/functions/individualValCheck.js';
import checkComplexRequired from '../../../utils/functions/checkComplexRequired.js';

const UserTextFieldMulti = ({ disabled, value, refItem, handleInput, sampleIndex, userInput }) => {
        
    // // instead of refItem do "refObject" or something like that    
    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''

    // // mirroring
    const canMirror = value.mirrorKey ? true : false    
    const isMirrored = disabled ? disabled : false
    if (canMirror && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : '' 
    }

    // // build up error text
    const requiredCheck = checkComplexRequired(value.required, userInput, sampleIndex)    
    
    // at some point we will need to validate this. Kinda tough!
    // const inputError = checkRequirements && requiredCheck && useInput === ''    
    // const validateError = value.validate ? checkRequirements && !individualValCheck(value.validate.type, useInput) : false
    // const renderError = inputError || validateError
    // const renderErrorText = [inputError ? "Required Entry" : "" , !inputError && validateError ? `Incorrect Formatting for ${value.validate.label}` : ""].filter(f => f !== "").join(", ")

    
    return (
        <>
            <Grid item xs={value.xs} md={value.md}>
                <TextField   
                    id={"sample-"+value.input_key+"TextField"}
                    label={<span>{value.label}(click enter to add more lines)<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span>}                            
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={formStyles.dataInput}
                    size="small"
                    disabled={isMirrored}
                    value={useInput}
                    onChange={(e) => handleInput(e, value.input_key)}        
                    multiline            
                />
            </Grid>

        

        </>


        


    );
}

export default UserTextFieldMulti;
