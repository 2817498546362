import React from 'react';

// mui
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';

const AdminStandard = ({ selected, updateValue }) => {
    
    const [newKey, setNewKey] = React.useState(false)

    const toggleChecked = () => {
        if (selected.adminStandard !== true || newKey) {
            updateValue(false, 'adminStandard', true)
            setNewKey(false)
        } else {
            updateValue(false, 'adminStandard', false)            
        }
    }
    
    return (
        <>
            <Typography variant="h5"  style={{float: "Left"}}>Standard Admin Item</Typography>
            <br /><br />
            <FormControlLabel
                label="On All Admin Receipts, including Couriers"
                control={
                    <Checkbox
                        checked={selected.adminStandard}
                        onChange={toggleChecked}
                    />
                }
            />
            <FormHelperText id="component-helper-text">
                This is an indicator for if this field should be present on ALL ADMIN RECEIPTS. For example, initials, temp, both are considered Standard. All others will only appear on the Admin Receive.
            </FormHelperText>
            

        </>                              
    );

}

export default AdminStandard;
