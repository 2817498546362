import React from "react";

// components
import GenericAlert from "../../dialogues/GenericAlert";
import QueryControls from "./QueryControls.js";

// mui
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Grid, Typography } from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScienceIcon from "@mui/icons-material/Science";
import Container from "@mui/material/Container";
import OutlinedInput from '@mui/material/OutlinedInput';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const MainToolbar = ({ resetFilter, setFilter, serviceLines, setFilters, filters, setArchiveData, getData, archiveData, loading, statuses, submissionTypes }) => {

  const [input, setInput] = React.useState('')

  const handleSubmit = () => {
      setFilter('submissionID', input)
  }

  const handleClear = () => {
      setInput('')
      resetFilter('submissionID')
  }
  
  const enterListen = (e) => {
      if (e.key === "Enter") {
          handleSubmit()
      }
  }
  const [updateOpen, setUpdateOpen] = React.useState(false);

  const currentDeptFilter = filters.find((f) => f.key === "serviceLine");
  const currentRecFilter = filters.find((f) => f.key === "receive");
  const currentStatusFilter = filters.find((f) => f.key === "status");
  const currentSubmissionTypeFilter = filters.find((f) => f.key === "submissionType");

  return (
    <Container
      sx={{
        width: "90%",
        backgroundColor: "#afdfff",
        margin: "auto",
        padding: "15px",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
    <GenericAlert open={updateOpen} setOpen={setUpdateOpen} />
      
      <Grid container spacing={1} sx={{ marginBottom: "5px" }}>
        
        <Grid item xs={12} md={3}>
          <Typography variant="h5">Admin Summary & Tools</Typography>
          <Button
            variant="contained"
            endIcon={<AnnouncementIcon />}
            color="success"
            onClick={() => {
              setUpdateOpen(true);
            }}
          >
            Update Information!
          </Button>
          <List sx={{ width: '100%', display: { xs: 'none', md: 'inline-block' } }}>
            <ListItem style={{padding: '0px'}}>
              <ListItemAvatar style={{}}>
                <Avatar style={{height: '30px', width: '30px'}}>
                <LocalShippingIcon style={{fontSize: '0.75rem'}} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<span style={{fontSize: '0.75rem'}}>Relinquishment</span>} secondary={<span style={{fontSize: '0.75rem'}}>Order requested Pick Up or Sampling</span>}/>
            </ListItem>
            <ListItem style={{padding: '0px'}}>
              <ListItemAvatar>
                <Avatar style={{fontSize: '0.75rem', height: '30px', width: '30px'}}>
                  S
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<span style={{fontSize: '0.75rem'}}>Subcontract</span>} secondary={<span style={{fontSize: '0.75rem'}}>Order includes subcontracted testing</span>}/>
            </ListItem>
            <ListItem style={{padding: '0px'}}>
              <ListItemAvatar>
                <Avatar style={{fontSize: '0.75rem', height: '30px', width: '30px'}}>
                  P
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<span style={{fontSize: '0.75rem'}}>Preparation</span>} secondary={<span style={{fontSize: '0.75rem'}}>Order includes samples requiring preparation</span>}/>
            </ListItem>
            <ListItem style={{padding: '0px'}}>
              <ListItemAvatar>
                <Avatar style={{height: '30px', width: '30px'}}>
                  <AcUnitIcon style={{fontSize: '0.75rem'}} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<span style={{fontSize: '0.75rem'}}>Cooled</span>} secondary={<span style={{fontSize: '0.75rem'}}>Sample container(s) are stored in R/F44</span>}/>
            </ListItem>
            
          </List>
          
        </Grid>

        <Grid item xs={12} md={9}>
          
          <Typography variant="h5">Project Filters</Typography>
          <QueryControls archiveData={archiveData} setArchiveData={setArchiveData} getData={getData} loading={loading}/>
          

          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6">Departments</Typography>          
              <ButtonGroup variant="outlined" orientation="vertical" sx={{ width: "100%" }}>
                {serviceLines ? 
                <>
                {
                  serviceLines.map(sl => {
                    return (
                      <Button
                        style={{fontSize: '0.6rem'}}
                        key={'filter-'+sl.serviceLine}
                        disabled={loading}
                        endIcon={<ScienceIcon />}
                        variant={
                          currentDeptFilter?.value === sl.serviceLine
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setFilter("serviceLine", sl.serviceLine);
                        }}
                      >
                        {sl.serviceLine}
                      </Button>
                    )
                  })
                }
                </>            
                : "Loading Filters..."}

                <Button
                  style={{fontSize: '0.6rem'}}
                  endIcon={<RefreshIcon />}
                  onClick={() => {
                    resetFilter("serviceLine");
                  }}
                  disabled={!filters.find(f => f.key === 'serviceLine') || loading}
                >
                  All Service Lines
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h6">Receipts</Typography>          
              <ButtonGroup variant="outlined" orientation="vertical" sx={{ width: "100%" }}>
                <Button
                    style={{fontSize: '0.6rem'}}
                    endIcon={<ScienceIcon />}
                    variant={
                      currentRecFilter?.value === "received"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setFilter("receive", "received");
                    }}
                    disabled={loading}
                  >
                    Received
                </Button>
                <Button
                    style={{fontSize: '0.6rem'}}
                    endIcon={<ScienceIcon />}
                    variant={
                      currentRecFilter?.value === "not received"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setFilter("receive", "not received");
                    }}
                    disabled={loading}
                  >
                    Not Yet Received
                </Button>
                <Button
                  style={{fontSize: '0.6rem'}}
                  endIcon={<RefreshIcon />}
                  onClick={() => {
                    resetFilter("receive");
                  }}
                  disabled={!filters.find(f => f.key === 'receive') || loading}
                >
                  All Received
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h6">Status</Typography>          
              <ButtonGroup variant="outlined" orientation="vertical" sx={{ width: "100%" }}>
                {statuses.map((status, i) => {
                  return (
                    <Button
                      key={status + '-filter-button-'+i}
                      style={{fontSize: '0.6rem'}}
                      endIcon={<ScienceIcon />}
                      variant={
                        currentStatusFilter?.value === status
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setFilter("status", status);
                      }}
                      disabled={loading}
                    >
                      {status}
                  </Button>
                  )
                })}

                <Button
                  style={{fontSize: '0.6rem'}}
                  endIcon={<RefreshIcon />}
                  onClick={() => {
                    resetFilter("status");
                  }}
                  disabled={!filters.find(f => f.key === 'status') || loading}
                >
                  All Statuses
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h6">Submission Types</Typography>          
              <ButtonGroup variant="outlined" orientation="vertical" sx={{ width: "100%" }}>
                {submissionTypes.map((submissionType, i) => {
                  return (
                    <Button
                      key={submissionType + '-filter-button-'+i}
                      style={{fontSize: '0.6rem'}}
                      endIcon={<ScienceIcon />}
                      variant={
                        currentSubmissionTypeFilter?.value === submissionType
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setFilter("submissionType", submissionType);
                      }}
                      disabled={loading}
                    >
                      {submissionType}
                  </Button>
                  )
                })}

                <Button
                  style={{fontSize: '0.6rem'}}
                  endIcon={<RefreshIcon />}
                  onClick={() => {
                    resetFilter("submissionType");
                  }}
                  disabled={!filters.find(f => f.key === 'submissionType') || loading}
                >
                  All Submission Types
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                endIcon={<RefreshIcon />}
                onClick={() => {
                  setFilters([]);
                }}
                disabled={filters?.length === 0 || loading}
                style={{width: '100%', marginTop: '3px', fontSize: '0.6rem'}}
                variant="outlined"
              >
                Reset All Filters
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <OutlinedInput 
                disabled={loading} 
                style={{fontSize: '0.7rem'}}
                fullWidth
                id="standard-basic" 
                placeholder="Submission ID Filter..." 
                variant="standard" 
                value={input} 
                onKeyDown={enterListen} 
                onChange={e => setInput(e.target.value)} 
                helperText="Filter for Form ID here!"
                endAdornment={
                  <ButtonGroup>
                    <Button style={{fontSize: '0.6rem'}} disabled={loading} onClick={handleSubmit} >Submit</Button>
                    <Button style={{fontSize: '0.6rem'}} disabled={loading} onClick={handleClear} >Clear</Button>
                  </ButtonGroup>
                }
              />

              
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    </Container>
  );
};

export default MainToolbar;
