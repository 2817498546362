import React from 'react';

// mui
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import RefreshIcon from "@mui/icons-material/Refresh";

const QueryControls = ({ getData, archiveData, setArchiveData, loading }) => {
    
    return (
      <ButtonGroup variant="contained" orientation="horizontal" style={{width: '100%'}}>  
        <Button style={{width: '51%', fontSize: '0.6rem'}} variant={archiveData ? 'outlined' : 'contained'} startIcon={<CloudDownloadIcon />} disabled={loading} onClick={() => setArchiveData(false)}>Active Orders</Button>
        <Button style={{width: '51%', fontSize: '0.6rem'}} variant={archiveData ? 'contained' : 'outlined'} startIcon={<RestoreFromTrashIcon />} disabled={loading} onClick={() => setArchiveData(true)}>Archived Orders</Button>
        <Button startIcon={<RefreshIcon style={{fontSize: '1rem'}} />} onClick={getData} disabled={loading} />
      </ButtonGroup>
        
    )

}

export default QueryControls;
