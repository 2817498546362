import * as React from 'react';

// mui
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const MaterialOrdersTestingParameters = ({ data, currentForm, updateForm }) => {

    // disregard list tells the form to SKIP over the specified tests when in this particular material order

    const testingForm = data.forms.sections.find(f => f.type === 'testing')
    const fullServiceLineTests = testingForm.testing_groups.map(t => t.tests).flat()
    
    const handleSelect = (test) => {
        if (currentForm.disregardTests?.find(t => t === test.LIMS_ID)) {
            updateForm('disregardTests', [...currentForm.disregardTests.filter(t => t !== test.LIMS_ID)])
        } else {
            updateForm('disregardTests', [...currentForm.disregardTests || [], test.LIMS_ID])
        }
    }

  return (
    <div style={{ marginTop: '15px' }}>
        <Typography variant="h5">Testing Settings</Typography>
        <Typography variant="p">This will let you determine if you want to hide/show testing specific to material orders.</Typography>

        <TableContainer sx={{ maxHeight: "400px", overflowY: "scroll", maxWidth: '600px' }}  >
            <Table stickyHeader size="small" aria-label="a dense table">
                    
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>Included</TableCell>
                        <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>Test</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                        
                    {fullServiceLineTests.map((row, i) => {
                        // get ref item from db if its a db thing
                        const displayItem = data.testingNew[row.type + 's'].find(t => t.LIMS_ID === row.LIMS_ID)
                        const disregard = !currentForm.disregardTests?.find(t => t === row.LIMS_ID)

                        return (
                            <TableRow
                                key={row.LIMS_ID + "-" + i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align='center' component="th" scope="row" style={{ padding: '0px'}}> 
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={disregard}
                                                onChange={() => handleSelect(row)}
                                            />
                                        }
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ padding: '0px'}}>
                                    {displayItem.DISPLAYNAME}
                                </TableCell>
                            </TableRow>
                        )
                    })}

                </TableBody>

            </Table>
        </TableContainer>

        
    </div>
  )
}

export default MaterialOrdersTestingParameters;
