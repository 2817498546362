import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MDLDisplay = ({ open, setOpen, data }) => {
  const listScopes = data.testing ? [...new Set(data.testing[open.type + 's']?.filter(t => t.LIMS_ID === open.LIMS_ID).map(t => t.SCOPE_NAME))] : false

  const [filter, setFilter] = React.useState(listScopes ? listScopes.filter(t => !!t)[0] : false)

  const test = data.testing[open.type + 's']?.filter(t => t.LIMS_ID === open.LIMS_ID).filter(t => {

    const isNull = filter === 'Other Items'
    
    let check = filter ? t.SCOPE_NAME === filter : true
    if (isNull) {
      check = !t.SCOPE_NAME
    }
    return check
  });

  const title = test?.length ? test[0].DISPLAYNAME : false

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={!!open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpen(false)
          setFilter(false)
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title} Minimum Detection Level Details</DialogTitle>
        <DialogContent style={{paddingTop: '55px'}}>
        <Grid container spacing={1}>
            
            {open.type === 'spectra' ? 
              <Grid item xs={12} md={4} style={{ padding: '0px',}}>
                <Typography>Contained Testing</Typography>
                <ButtonGroup
                  orientation="vertical"
                  aria-label="vertical outlined button group"        
                  fullWidth            
                >
                  {listScopes?.sort().map(s => {
                    const isNull = !s
                    const name = isNull ? 'Other Items' : s
                    return (
                      <Button key={name} onClick={() => setFilter(name)} variant={filter === name ? 'contained' : 'outlined'}>{name}</Button>
                    )
                  })}
                </ButtonGroup>

              </Grid>
            : null}

            <Grid item xs={12} md={open.type === 'spectra' ? 8 : 12} style={{ padding: '0px 5px' }}>
              <TableContainer component={Paper} style={{ height: '500px', width: '100%'}}>
                <Table sx={{ width: '100%' }} size="small" aria-label="a dense table"  stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Analyte</TableCell>
                      <TableCell align="right">LOQ</TableCell>
                      <TableCell align="right">Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {test?.map((row, i) => {
                      return (
                        <TableRow
                          key={row.ANALYTE + '-' + i}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.ANALYTE} <span style={{fontStyle: "italic", fontSize: "12px"}}>by {row.METHODN}</span>
                          </TableCell>
                          <TableCell align="right">{row.LOQ}</TableCell>
                          <TableCell align="right">{row.UNIT}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          
          </Grid>
        </DialogContent>
            
              
        <DialogActions>          
          <Button onClick={( )=> {setOpen(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MDLDisplay;
