import React from 'react';

// components
import SamplingWorksheetsMain from './SamplingWorksheetsMain.js';

const SamplingWorksheets = ({ data, userData, setUserData, references }) => {

    return (
      <>
      {references ? 
      <>      
        <SamplingWorksheetsMain data={data} userData={userData} setUserData={setUserData} references={references} />
      </>
      :
      <>
        Loading Data...
      </>
      }
      </>
    )

}

export default SamplingWorksheets;
