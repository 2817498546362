import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import { IconButton, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import Table from '@mui/material/Table';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';

const styles = {
    headerCell: {
        fontWeight: 'bold',
        fontSize: '9px',
        paddingTop: '0px',
        paddingBottom: '0px',
        border: 'none',
        backgroundColor: '#DDDDDD'
    },
    cell: {
        fontSize: '9px',
        paddingTop: '0px',
        paddingBottom: '0px',
        border: 'none',
        borderBottom: '0.5px dashed grey',
        backgroundColor: '#EEEEEE'
    }
}

const IndividualAnalytesSelector = ({ active, data, updateValue }) => {

    const [selectorName, setSelectorName] = React.useState('')
    
    const saveName = () => {
        updateValue('individualAnalytesName', selectorName === '' ? 'Other Analytes' : selectorName)
    }

    const removeAll = () => {
        updateValue('individualAnalytes', [])
    }

    const removeOne = (val) => {
        const newActive = cloneDeep(active)
        newActive.individualAnalytes = newActive.individualAnalytes.filter(ia => ia !== val)
        updateValue('individualAnalytes', newActive.individualAnalytes)
    }

    return (
    <Card >   
        <CardContent>
            <Typography variant='h5'>Additional Testing Options</Typography>

            <Typography>Purpose: Provide a way for clients to select individual analytes from a list instead of listing them individually.</Typography>
            <Typography>Use the <span style={{fontWeight: 'bold'}}>Parameter Methods</span> menu to add analytes into the selector.</Typography>
            
            {!active.individualAnalytes?.length ? <Typography style={{fontWeight: 'bold'}}>This component will not display because the analyte list is empty.</Typography> : 
            <>
                        <FormControl variant="standard" style={{width: "100%", padding: '15px'}}>
                    
                    <Input 
                        value={selectorName !== '' ? selectorName : (active.individualAnalytesName || selectorName)}
                        onChange={(e) => {
                            setSelectorName(e.target.value);
                        }}
                        placeholder='Component Name'
                        endAdornment={
                            <InputAdornment position="end">
                              <IconButton color="success" disabled={active.individualAnalytesName === selectorName} onClick={saveName}><SaveIcon /></IconButton>
                            </InputAdornment>
                          }
                    />

                    <FormHelperText id="component-helper-text1">
                        What name this item will have on the form.
                    </FormHelperText>
                    <FormHelperText id="component-helper-text2">
                        If the table below is empty, this option will not be displayed.
                    </FormHelperText>
                    
            </FormControl>  
            <TableContainer style={{maxHeight: "500px", width: "100%"}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.headerCell}>Poduct Code</TableCell>
                            <TableCell style={styles.headerCell}>Analyte</TableCell>
                            <TableCell style={styles.headerCell}>Method</TableCell>
                            <TableCell style={styles.headerCell}>LOQ</TableCell>
                            <TableCell style={styles.headerCell}>Unit</TableCell>
                            <TableCell style={styles.headerCell}>
                                <Button style={{fontSize: "9px"}} onClick={removeAll}>
                                    Remove All
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {active?.individualAnalytes?.map((a, k) => {
                        const analyte = data.testingNew.parameterMethods.find(pm => pm.LIMS_ID === a)
                        return (
                            <TableRow key={a+k}>

                                <TableCell style={styles.cell}>{analyte?.PRODUCTCODE}</TableCell>
                                <TableCell style={styles.cell}>{analyte?.ANALYTE}</TableCell>
                                <TableCell style={styles.cell}>{analyte?.METHODN}</TableCell>
                                <TableCell style={styles.cell}>{analyte?.LOQ || 'Not Specified' || 'Not Specified'}</TableCell>
                                <TableCell style={styles.cell}>{analyte?.UNIT}</TableCell>
                                <TableCell style={styles.cell}>
                                    <Button style={{fontSize: "9px"}} onClick={() => removeOne(a)}>
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
            
            }


        </CardContent>

        
    </Card>
        
    )

}

export default IndividualAnalytesSelector;
