import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import FormAPI from '../../../utils/API/api-form.js';

const GeneralSamplingData = ({ data, userData, setUserData }) => {

  const [inputMode, setInputMode] = React.useState(false)
  const [inputs, setInputs] = React.useState({})
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  
  const handleSave = async () => {            

    setError(false)   
    setLoading(true)
    // update userData, merge in new data for existing data
    const newUserData = cloneDeep(userData)
    newUserData.samplingPerson = inputs

    // send userData to back-end for saving
    try {
      const res = await FormAPI.saveClientSubmission({data: {
        userJSON: newUserData
      }})

      // handle errors here
      if (res.status === 200) {            
           
        setLoading(false)
        setUserData(res.data)
        setInputMode(false)

      } else {
          setLoading(false)
          setError(true)
      }  
    } catch (err) {
        setLoading(false)
        setError(true)
    }
    
  }

  const handleInput = (val, key) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key] = val
    setInputs(newInputs)    
  }

  const startEditMode = () => {
    setInputs({
      name: userData?.samplingPerson?.name ? userData.samplingPerson.name : "",
      date: userData?.samplingPerson?.date ? userData.samplingPerson.date : ""
    })
    setInputMode(true)
  }


    return (
      <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
        <Typography variant="h5" gutterBottom>Samping Event: {userData.submissionID}</Typography>              
        <Typography variant="h6" >Client: {userData.companyName}</Typography>
        <Typography variant="h6" >Contact: {userData.contactFirstName} {userData.contactLastName}</Typography>              
        <Typography variant="h6" >{userData.companyAddress1}</Typography>
        <Typography variant="h6" >{userData.companyCity}, {data.references.states.find(s => s.LIMS_ID === userData.companyState)?.STATE_SHORTHAND || userData.companyState} {userData.companyZip}</Typography>
        <Divider style={{marginTop: "15px", marginBottom: "15px"}} />

        {inputMode ? 
                <Box
                component="form"
                autoComplete="off"        
              >


            <TextField 
              label={"Sampler Name"}
              value={inputs.name ?? ''}
              onChange={(e) => handleInput(e.target.value, "name")}
              onKeyPress={(e) => e.key === 'Enter' && handleSave()}
              sx={{ width: '100%', marginBottom: "10px" }} 
            />
            


            {/* This duplicate is here because no matter what, on MS Edge, enter key sends ? to URL (post) */}
            <TextField 
              label={"Sampler Name"}
              value={inputs.name ?? ''}
              onChange={(e) => handleInput(e.target.value, "name")}
              sx={{ width: '100%', marginBottom: "10px", display: "none"}} 
            />
            <TextField 
              label="Sampling Date"
              type="date"        
              value={inputs.date ?? ''}       
              onChange={(e) => handleInput(e.target.value, "date")}   
              sx={{ width: '100%', marginBottom: "10px" }} 
              InputLabelProps={{
                shrink: true,
              }}          
            />



          <Button variant="contained" onClick={() => setInputMode(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          
          </Box>
        :
          <>
          
          <Typography variant="h6" >{userData?.samplingPerson?.name ? "Sampler: " + userData?.samplingPerson?.name : "Name not assigned"}</Typography>
          <Typography variant="h6" >{userData?.samplingPerson?.date ? "Sampling Date: " + userData?.samplingPerson?.date : "Date not assigned"}</Typography>
          
          <Button variant="contained" onClick={startEditMode}>Submit/Edit Name & Date</Button>

          </>
        }

        {error ?                 
          <Alert severity="error" style={{ margin: "15px" }}>Connection to Server Failed! Server not responding. Try &apos;Save&apos; again. If this error persists please contact the administrator.</Alert>
        : null} 
      
        {loading ? 
        <Box sx={{ width: '90%', margin: "15px" }}>          
            <LinearProgress />
            <Typography variant="h6">Now Saving...</Typography>
        </Box>   
        : null }
      </Box>
    )

}

export default GeneralSamplingData;
