import React from 'react';

// components
import TemplateProjectWarning from '../../dialogues/TemplateProjectWarning.js';

// mui
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

// styles
import '../../../utils/styles/styleSheet.css';
import APIQueries from '../../../utils/API/api-queries.js';

const TemplateSubmission = ({ userInput, setUserInput, setMode, data, globalLoading, setGlobalLoading }) => {

    const [templateHolder, setTemplateHolder] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)

    const getTempalte = async () => {
        setLoading(true)
        setError(false)
        setGlobalLoading(true)
        const template = await APIQueries.getTempalte(userInput)

        if (template.error) {
            setError(true)
            setGlobalLoading(false)
        } else if ([...template.data.lostInputs, ...template.data.changedInputs, ...template.data.lostTests, ...template.data.changedTests].length > 0) {
            setTemplateHolder(template)
            setLoading(false)
        } else {
            setMode("form")
            setUserInput(template.data.userInput)
            window.scrollTo(0, 0);
            const submissionCheck = template.data.userInput.submissionType !== 'samples' ? `?materials=${template.data.userInput.submissionType}` : ''
            window.history.pushState('page2', 'Title', '/' + template.data.userInput.serviceLine + submissionCheck);
            setLoading(false)
        }

    }

    return (
        <>  
            <TemplateProjectWarning open={templateHolder} setOpen={setTemplateHolder} setMode={setMode} setUserInput={setUserInput} data={data} />


            <div style={{padding: "5px", width: "100%", margin: "auto", marginTop: "15px", minHeight: '25px' }}>
                
                {error ?                 
                  <Alert severity="error" style={{ maxWidth: '400px', margin: 'auto', marginBottom: '10px' }}>An error occured with the service, we apologize for the inconvenience. Please try again. If the error persists please contact the laboratory for assistance.</Alert>
                : null} 

                <Tooltip title="Create a new project using the same data provided from this submission!">
                <button
                    disabled={loading || globalLoading}
                    className={userInput.materialOrders?.length > 0 ? "templateBtnAlt" : "templateBtn"}
                    onClick={getTempalte}>
                        {loading ? 'Generating Template...' : null}   
                        {loading ? <LinearProgress /> :                         
                        <>
                        {userInput.materialOrders?.length > 0 ? 'Duplicate Request' : 'Duplicate Submission'}
                        </>
                        }                        
                </button>
                </Tooltip>
            </div>
        </>

    )

}

export default TemplateSubmission
