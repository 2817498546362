import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import BiotechIcon from '@mui/icons-material/Biotech';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import FeedIcon from '@mui/icons-material/Feed';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const headerIcons = [
    {
        key: "BusinessIcon",
        component: <BusinessIcon className="formHeaderIcon"/>
    },
    {
        key: "AccountCircleIcon",
        component: <AccountCircleIcon className="formHeaderIcon"/>
    },
    {
        key: "MarkunreadMailboxIcon",
        component: <MarkunreadMailboxIcon className="formHeaderIcon"/>
    },
    {
        key: "BiotechIcon",
        component: <BiotechIcon className="formHeaderIcon"/>
    },
    {
        key: "LocalShippingIcon",
        component: <LocalShippingIcon className="formHeaderIcon"/>
    },
    {
        key: "FeedIcon",
        component: <FeedIcon className="formHeaderIcon"/>
    },
    {
        key: "BorderColorIcon",
        component: <BorderColorIcon className="formHeaderIcon"/>
    },
    {
        key: "PlaylistAddIcon",
        component: <PlaylistAddIcon className="formHeaderIcon"/>
    }
]

export default headerIcons
