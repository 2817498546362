import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SamplingEventInputs = ({ data, sampleID, samplingEventInputs, setSamplingEventInputs }) => {

    const refInputs = samplingEventInputs.find(sam => sam.id === sampleID)

    const handleInput = (e, key, sid) => {
        const newInputs = cloneDeep(samplingEventInputs)
        newInputs.sort((a, b) => (a.id > b.id ? 1 : -1))
        let newSampleInput = samplingEventInputs.find(sam => sam.id === sid)
        if (newSampleInput) {
            newSampleInput[key] = e.target.value
            newInputs.splice(sid, 1, newSampleInput)
        } else {
            newSampleInput = {id: sid}
            newSampleInput[key] = e.target.value
            newInputs.push(newSampleInput)
        }
        setSamplingEventInputs(newInputs)
    }    

    return (
        <>

            {
                Object.keys(data.samplingEvent.samplerInputs).map(function(key, i) {
                    const refItem = data.samplingEvent.samplerInputs[key]
                    switch(refItem.type) {
                        case 'input':  
                            return (
                                <td key={key + "-input-" + i + "-" + sampleID} align="center">
                                    <Input 
                                        value={refInputs ? refInputs[key] ?? " " : " "} 
                                        onChange={(e) => {handleInput(e, key, sampleID)}} label={refItem.label}
                                        style={{fontSize: "0.7em", minWidth: "125px"}}
                                    />
                                </td>
        
                            )
                        case 'select':  
                            return (
                                <td key={key + "-input-" + i + "-" + sampleID} align="center">
                                    <Select 
                                        value={refInputs ? refInputs[key] ?? " " : " "} 
                                        onChange={(e) => {handleInput(e, key, sampleID)}}
                                        style={{fontSize: "0.7em", minWidth: "125px"}}
                                    >
                                        {refItem.options.map(item => {
                                            return <MenuItem key={key + "-input-" + i + "-" + sampleID + "-" + item} value={item}>{item}</MenuItem>
                                        })}
                                        
                                    </Select>
                                </td>
        
                            )
                   
                            
                        default:
                            return (
                                <td key={key + "-input-" + i + "-" + sampleID} align="center">
                                    Not Developed: {key}
                                </td>
        
                            )



                    
                        }
                    
                })
            }

        </>
    )

}

export default SamplingEventInputs
