import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Alert } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GenericAlert = ({ open, setOpen }) => {
    
  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Updates</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description1" component="p">
            <b>Q4 2023 Updates</b>
            <br /><br />
            <b>Database</b> Live: all data provided by database, including client submission saved prior to this update.
            <Alert>Note, this migration may cause some issues on the COCs and may arouse questions from clients. Please assure clients that their originals are stored in our LIMS and can be retrieved at any time. The ECOC&apos;s version can still be templated and it will alert them if something is different from that submission.</Alert>

            <b>LIMS Testing</b>: all testing selected derives from LIMS direclty, no more duplicate/inconsistent mapping of testing by name or by content. Same goes for Matrix and Units.
            <Alert severity='error'>Its important to note Sampling Event References are still based on JSON and are not easily updated by non-development team. If you notice any issues with the sampling event giving errors alert the dev team immediately. This is a work in progress.</Alert>
            
            <b>Admin Page</b>: even more data provided, and more is easily added, just ask! A new version for Mobile view is also availalbe for those working on smaller screens. Filters also improved, including search by ID.
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description2" component="p">
            <br /><br />
            Future development
            <br /><br />

            Several items considered high priority are in the works and will be coming as soon as they are ready

            <ul>
              <li>Improved COC, including a true &quot;download PDF&quot; feature and imports to LIMS</li>
              <li>Authentication for staff log in to this page which would allow for more tracability for work done and more security.</li>
              <li>Move sampling event data (duplicate rules, etc) into db and provide a manager for this.</li>
            </ul>
            
            <br />
            <br />
            Thank you everyone for helping build this project up!
          </DialogContentText>
        </DialogContent>
            
              
        <DialogActions>          
          <Button onClick={( )=> {setOpen(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenericAlert;
