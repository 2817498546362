import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const tableStyles = {
    cell: {
        paddingTop: '0px',
        paddingBottom: '0px',
    }
}

const SubItems = ({ data, selected, updateValue }) => {
    
    const toggleChecked = () => {
        if (!selected.input_unit) {
            const newSubitems = {
                    source: "units",
                    value: []
                }
            updateValue(false, 'input_unit', newSubitems)
        } else {
            updateValue(false, 'input_unit', false, 'delete')
        }
    }
    
    const handleSelection = (value) => {
        const check = selected.input_unit?.value?.indexOf(value)
        const newSubitems = cloneDeep(selected.input_unit)
        if (check > -1) {
            newSubitems.value.splice(check, 1);
        } else {
            newSubitems.value.push(value)
        }
        updateValue(false, 'input_unit', newSubitems)

    }
    
    return (
        <div style={{maxHeight: '500px', overflow: 'scroll'}}>     
            <Typography variant="h5"  style={{float: "Left"}}>Units Selector</Typography>
                <br /><br />             
            <FormHelperText id="component-helper-text">
                Users may provide numeric data for entries such as &quot;density&quot; or &quot;amount provided&quot; which require units to also be required and submitted with that result.
            </FormHelperText>
            <FormControlLabel
                label="Include Units Selection?"
                control={
                    <Checkbox
                        checked={!!selected.input_unit || false}
                        onChange={toggleChecked}
                    />
                }
            />

            {selected.input_unit ? 
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            
                        <TableHead>
                            <TableRow>
                                <TableCell style={tableStyles.cell}>Included</TableCell>
                                <TableCell style={tableStyles.cell}>Unit</TableCell>
                                <TableCell style={tableStyles.cell}>Name</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                                
                        {data.references.units.map((row, i) => {
                            const checked = selected.input_unit?.value?.indexOf(row.LIMS_ID) > -1
                            return (
                                <TableRow
                                    key={row.LIMS_ID + "-" + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={tableStyles.cell} component="th" scope="row">
                                        {row.UNIT}                 
                                    </TableCell>
                                    <TableCell style={tableStyles.cell} component="th" scope="row">
                                        {row.UNIT_NAME}                 
                                    </TableCell>
                                    <TableCell style={tableStyles.cell} component="th" scope="row">
                                        <FormControlLabel
                                            style={tableStyles.cell}
                                            control={
                                                <Checkbox
                                                    checked={checked || false}
                                                    onChange={() => handleSelection(row.LIMS_ID)}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                        </TableBody>

                    </Table>
                </TableContainer>
            : null }
            
            
            <FormHelperText id="component-helper-text">
                Choose which units will be selectable for this form item. This list comes from LIMS, for any changes please review LIMS database.
            </FormHelperText>
        </div>

    );

}

export default SubItems;
