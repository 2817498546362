import React from 'react';

// mui
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';

// utils
import formStyles from '../../../utils/styles/formStyles.js';
import checkComplexRequired from '../../../utils/functions/checkComplexRequired.js';

const UserSelect = ({ data, value, refItem, disabled, handleInput, sampleIndex, checkRequirements, userInput }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''
    
    const requiredCheck = checkComplexRequired(value.required, userInput, sampleIndex)
    
    const isMirrored = disabled ? disabled : false
    if (value.mirrorKey && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : '' 
    }

    const renderError = checkRequirements && requiredCheck && useInput === ''    
    
    const handleInputCheck = (e) => {
        // special case for flipping this specific case. Could be generalized for "sampling" later on.
        // maybe think about de-serviceLineing -sampling
        if ((refItem.serviceLine === "cannabis" || refItem.serviceLine === "cannabis-sampling") && value.input_key === "samplesDelivery") {            
            if (e.target.value === "Sampling Event Request") {                
                window.history.pushState('page2', 'Title', '/cannabis-sampling');
            } else {                
                window.history.pushState('page2', 'Title', '/cannabis');
            }
        }

        
        if (typeof e.target.value === 'object') {
            handleInput(false, value.input_key, e.target.value.join(':isArray:'))
        } else {
            handleInput(e, value.input_key)
        }
    }

    return (
        <Grid item xs={value.xs} md={value.md}>
            <FormControl sx={{ width: '100%' }} size="small" error={renderError} >    
            <InputLabel shrink id="demo-simple-select-label">{value.label}<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></InputLabel>  
                <Select
                    id={"sample-"+value.input_key}
                    value={value.isMultiple ? useInput.split(':isArray:') : useInput}
                    multiple={value.isMultiple}
                    disabled={disabled}   
                    style={formStyles.dataInput}
                    label={value.label}
                    onChange={handleInputCheck}    
                    input={<OutlinedInput notched label={value.label} />}        
                >
                    {value.selection?.value.map((o, i) => {
                        const displayItem = value.selection.source === 'specified' ? o : data.references[value.selection.source]?.find(item => item.LIMS_ID === o)?.DISPLAYNAME
                        return <MenuItem key={value.input_key+i} value={o} id={sampleIndex ? o+i+sampleIndex : o+i}>{displayItem || o}</MenuItem>
                    })}
                
                </Select>

                {renderError ? <FormHelperText>Required Entry</FormHelperText> : null}

            </FormControl>
        </Grid>
    );
}

export default UserSelect;
