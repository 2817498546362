import React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';

const styles = {
  cell: { maxWidth: "1em", padding: "1px", fontSize: "0.8em", backgroundColor: "white", border: "1px solid black" },
  cellHeader: { maxWidth: "1em", fontWeight: "bold", padding: "1px", fontSize: "0.8em", borderBottom: "1px solid black" },
  cellFooterHeader: { maxWidth: "1em", fontWeight: "bold", padding: "1px", fontSize: "0.8em", borderTop: "2px solid black", borderBottom: "2px solid black", backgroundColor: "lightGreen" },
  cellFooter: { maxWidth: "1em", fontWeight: "bold", padding: "1px", fontSize: "0.8em", borderTop: "2px solid black", borderBottom: "2px solid black", backgroundColor: "lightGreen" },
}

const SampleWSIncrements = ({ specifiedData }) => {
    const sigFigs = specifiedData.rangeRef.sampleUnit === "each" ? 0 : 4
    const unitName = specifiedData.rangeRef.sampleUnit === "each" ? "unit" : specifiedData.rangeRef.sampleUnit
    const incTarget = (specifiedData.targetMass / specifiedData.rangeRef.increments).toFixed(sigFigs)
    const totalMass = specifiedData.samplingData?.increments?.map(inc => inc.sampleMass ? inc.sampleMass * 1 : 0).reduce((a, b) => a + b, 0).toFixed(sigFigs)
    
        return (
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        
                        <TableRow>                       
                        
                            <TableCell style={styles.cellHeader} align="center">Increment ID</TableCell>      
                            <TableCell style={styles.cellHeader} align="center">Batch Container</TableCell>         
                            <TableCell style={styles.cellHeader} align="center">Increment Zone</TableCell>          
                            <TableCell style={styles.cellHeader} align="center">Increment Mass Taken</TableCell>                 
                            <TableCell style={styles.cellHeader} align="center">Target Increment Amount</TableCell>      
                            <TableCell style={styles.cellHeader} align="center">Check</TableCell>    

                        </TableRow>



                    </TableHead>

                    <TableBody>

                        {specifiedData?.samplingData?.increments?.sort((a, b) => a.id - b.id).map((inc, i) => {
                                                        
                            return (
                                <TableRow key={i}>
                                    <TableCell style={styles.cell} align="center" >
                                        {i + 1}
                                    </TableCell>                               
                                    <TableCell style={styles.cell} align="center">
                                        {inc.container}
                                    </TableCell>                        
                                    <TableCell style={styles.cell} align="center" >
                                        {inc.zone}
                                    </TableCell>                            
                                    <TableCell style={styles.cell} align="center">
                                        {inc.sampleMass ?? " "}
                                    </TableCell>  

                                    <TableCell style={styles.cell} align="center">{(specifiedData.targetMass / specifiedData.rangeRef.increments).toFixed(sigFigs)} {unitName}</TableCell>
                                    <TableCell style={styles.cell} align="center">{inc.sampleMass && inc.sampleMass / incTarget >= 1 ? <CheckIcon color="success" /> : null}</TableCell>
                                </TableRow>
                            )
                        })}


                        <TableRow >

                            <TableCell style={styles.cellFooter} align="right" colSpan={3}>
                                
                            </TableCell> 
                            <TableCell style={styles.cellFooterHeader} align="center" >
                                {totalMass ? 
                                <>Total {sigFigs === 0 ? "Units" : "Mass"} Taken: {totalMass} {unitName}</>
                                :
                                <>Not Taken</>
                                }
                            
                            </TableCell> 
                            <TableCell style={styles.cellFooterHeader} align="center" >
                                Minimum Amount Required: {specifiedData.targetMass} {specifiedData.rangeRef.sampleUnit}
                            </TableCell> 
                            <TableCell style={styles.cellFooterHeader} align="center" >
                                {totalMass / specifiedData.targetMass >= 1 ? <CheckIcon /> : null}
                            </TableCell> 
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    )

}

export default SampleWSIncrements;
