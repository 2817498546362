import * as React from 'react';

// mui
import Typography from '@mui/material/Typography';

const PackageList = ({ data, testingList }) => {

    const getSpectraList = (test) => {
        const fullTest = data.testing.spectras.filter(t => t.LIMS_ID === test.LIMS_ID);
        const uniques = [...new Set(fullTest.map(t => t.SCOPE_NAME || t.ANALYTE))]
        return <span>{uniques.join(' • ')}</span>
    }

    const renderList = testingList?.filter(t => t.type === 'spectra').map((t, i) => { 
        const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
        const details = getSpectraList(test) 
        return (
            <li key={"package-list-"+t.productCode+"-"+i} style={{padding: "0px", margin: "0px", textAlign: 'left', listStyleType: 'none'}}>
                <Typography style={{marginLeft: "10px", fontSize: "0.9vw"}}>
                    <u>{test.DISPLAYNAME}</u>: {details}
                </Typography>
            </li>
        )
    })

    const individuals = testingList?.filter(t => t.individualName).map((t, i) => { 
        return (
            <li key={"package-list-individuals-"+i} style={{padding: "0px", margin: "0px", textAlign: 'left', listStyleType: 'none'}}>
                <Typography style={{marginLeft: "10px", fontSize: "0.9vw"}}>
                    <u>{t.individualName}{t.index ? (" " + t.index) : ""}</u>: {t.activeTests.map((at, k) => {
                        const test = data.testing[at.type + 's']?.find(tt => tt.LIMS_ID === at.LIMS_ID);
                        return <span key={at.LIMS_ID + 'ind-list'+i+k}>{test.DISPLAYNAME}{k === t.activeTests.length - 1 ? "" : ", "}</span>
                    })}
                </Typography>
            </li>
        )
    })

  return (
    <div style={{marginTop: "10px", width: "100%", textAlign: 'left'}}>
        {renderList.length > 0 ?
            <>
                <Typography variant="p" style={{marginTop: "15px", fontSize: "0.9vw", fontWeight: "bold"}}>
                    Package Details
                </Typography>
                <ul>
                    {renderList}
                </ul>
            </>
        : null}

    {individuals.length > 0 ?
        <>
            <Typography variant="p" style={{marginTop: "15px", fontSize: "0.9vw", fontWeight: "bold"}}>
                Specified List Selections
            </Typography>
            <ul>
                {individuals}
            </ul>
        </>
    : null}
    </div>
  );
}

export default PackageList;


