import * as React from 'react';
import { cloneDeep } from 'lodash';
import validator from 'validator';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import APIQueries from '../../utils/API/api-queries';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// use this to template project from MATERIAL to SAMPLE submission
const GenerateTemplate = ({ open, setOpen, userInput, setUserData, data, setGlobalLoading, setTemplateHolder, setMode }) => {

  const [specs, setSpecs] = React.useState({count: 1, testing: [], type: 'materials-to-samples'})
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleNumber = (e) => {
    if (validator.isInt(e.target.value) && e.target.value > 0) {
      const newSpecs = cloneDeep(specs)
      newSpecs.count = e.target.value
      setSpecs(newSpecs)
    }
  }

  const handleCheck = (test) => {
    const newSpecs = cloneDeep(specs)

    if (newSpecs.testing.includes(test.LIMS_ID)) {
      // remove on untick
      newSpecs.testing = newSpecs.testing.filter(t => t !== test.LIMS_ID)
    } else {
      // add on tic      
      const incomingFull = data.testing[test.type + 's']?.filter(t => t.LIMS_ID === test.LIMS_ID).map(t => t.PM_LIMS_ID);
      
      // filter out testing with overlaps
      newSpecs.testing.forEach(existing => {  
        // // existing test full profile (have to go thru each type because of a lack of)
        let existingFull = data.testing['parameterMethods']?.filter(t => t.LIMS_ID === existing).map(t => t.PM_LIMS_ID);
        
        if (existingFull.length < 1) {
          existingFull = data.testing['parameterScopes']?.filter(t => t.LIMS_ID === existing).map(t => t.PM_LIMS_ID);
        }

        if (existingFull.length < 1) {
          existingFull = data.testing['spectras']?.filter(t => t.LIMS_ID === existing).map(t => t.PM_LIMS_ID);
        }

        // overlap check
        const filteredArray = existingFull.filter(value => incomingFull.includes(value));
        if (filteredArray.length > 0) {

            // remove existing if overlap exists
            newSpecs.testing = newSpecs.testing.filter(t => t !== existing)
        }
      })

      // add test
      newSpecs.testing = [...newSpecs.testing, test.LIMS_ID]
    }

    setSpecs(newSpecs)
  }

  const handleCreate = async () => {

    setLoading(true)
    setError(false)
    setGlobalLoading(true)
    const template = await APIQueries.getTempalte(userInput, specs)    

    if (template.data.userInput) {
      if (template.error) {
        setError(true)
        setGlobalLoading(false)
      } else if ([...template.data.lostInputs, ...template.data.changedInputs, ...template.data.lostTests, ...template.data.changedTests].length > 0) {
        setTemplateHolder(template)
        setLoading(false)
        setOpen(false)
      } else {
        setMode("form")
        setUserData(template.data.userInput)
        window.scrollTo(0, 0);
        window.history.pushState('page2', 'Title', '/' + template.data.serviceLine);
        setLoading(false)
        setOpen(false)
      }
    } else {
      setError(true)
    }

  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Creating New Sample Order</DialogTitle>
        <DialogContent>
          {!loading ? 
          <>
            <DialogContentText id="alert-dialog-slide-description1" component="div">
              <p>Sample Amount and testing can be adjusted on form page</p>
              <b>Sample Quantity</b>            
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-controlled"
                  label="Samples"
                  value={specs.count}
                  onChange={handleNumber}
                />
              </Box>
            </DialogContentText>

            {userInput.materialOrders[0].activeTests.length > 0 ?
              <>
                <Divider style={{ width: "90%", margin: "auto", marginTop: "5px", marginBottom: "5px" }} />
                <DialogContentText id="alert-dialog-slide-description1" component="p">
                  <b>Testing</b>
                  <p>Select which tests should be attached to your samples.</p>
                  <FormGroup>
                    {userInput.materialOrders[0].activeTests?.filter(at => !at.individual).map((t) => {
                      const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
                      return <FormControlLabel key={t.LIMS_ID} control={<Checkbox checked={specs.testing.includes(t.LIMS_ID)} onChange={() => handleCheck(t)} />} label={test.DISPLAYNAME} />
                    })}
                  </FormGroup>
                </DialogContentText>
              </>
            : null} 
            </> 
            : null}       
            {error ?                 
              <Alert severity="error" style={{ maxWidth: '250px', margin: 'auto', marginBottom: '10px' }}>An error occured with the service, we apologize for the inconvenience. Please try again. If the error persists please contact the laboratory for assistance at <b>(503) 254-1794</b> or email us at <a href="mailto:info@columbialaboratories.com" rel="noreferrer" target="_blank">info@columbialaboratories.com</a>.</Alert>
            : null} 

            {loading ? <Typography>Generating New Submission...</Typography> : null}   

            {loading ? 
              <CircularProgress color="success" style={{ margin: 'auto' }} /> 
            : null }      

        </DialogContent>
        <DialogActions>          
          <Button onClick={()=> {setOpen(false)}}>Close</Button>
          <Button disabled={specs.count < 1 || specs.testing.length < 1} onClick={handleCreate}>Create New Submission</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenerateTemplate;
