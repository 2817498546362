import React from "react";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';

// styles
import "../../../../utils/styles/styleSheet.css";

const PrepareYourSamplingEvent = () => {

  return (
    <>
    <Grid container style={{ margin: "auto", marginBottom: "35px" }}>
      
      <Grid item xs={12} md={6} sx={{ borderRight: {xs: '0px', md: '2px solid black'}  }}>
      <div 
        style={{width: "100%", height: "20px", backgroundColor: "black", color: 'white', padding: "3px"}}
      >
        <Typography sx={{float: "left"}}>Prepare Your Site For Sampling</Typography>
      </div>
      <List dense={true}>
      <ListItem>
          <ListItemIcon>
            <Battery0BarIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Ensure your sampling site is accessible and water is on at the time of appointment</Typography>}
          />
        </ListItem>
      </List>
      </Grid>
      

      <Grid item xs={12} md={6} >
      <div 
        style={{width: "100%", height: "20px", backgroundColor: "black", color: 'white', padding: "3px"}}
      >
        <Typography sx={{float: "left"}}>Billing Information</Typography>
      </div>
      <List dense={true}>
      <ListItem>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Invoices will be emailed 1-2 business days after sampling appointment is completed</Typography>}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={{ fontSize: '1.1em '}}>Payment is due prior to report release unless net terms have been established.</Typography>}
          />
        </ListItem>


      </List>
      
      </Grid>


    </Grid>
  </>
  );
};

export default PrepareYourSamplingEvent;
