import React from 'react';
import validator from 'validator';

// mui
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// styles
import '../../../utils/styles/styleSheet.css';

const TestIncrementor = ({ handleIncrementTest, test, selectedCheck }) => {

    const handleDirectInput = (e) => {

        if (validator.isInt(e.target.value)) {
            const curVal = (selectedCheck.count || 0)
            const incVal = parseInt(e.target.value)
            const diffVal = incVal - curVal            

            if (incVal < 1000) {
                handleIncrementTest(test, diffVal)
            }
        }
        
    }

    return (

    <div 
        style={{ position: 'relative', background: 'linear-gradient(to bottom, #DDDDDD, #888888)', marginRight: '15px', minWidth: '70px', height: '30px', overflow: 'inheret !important', borderRadius: '14px'}}
    >
<Grid container spacing={1} style={{position: 'absolute', left: 3, top: -19 }}>

    <Grid item xs={6} component={Stack} direction="column" justifyContent="center" style={{fontSize: '1.0rem', padding: '20px 0px 0px 12px', marginRight: '-6px', marginTop: '-4px'}}>
        Qty
    </Grid>
    
    <Grid item xs={6}>
    <Stack spacing={0} style={{width: '24px', marginTop: '-2px'}}>
            <button onClick={() => handleIncrementTest(test, 1)} disabled={selectedCheck.count >= 999} style={{ cursor: 'pointer', border: '0px', fontSize: '1.5rem', backgroundColor: 'RGBA(0,0,0,0)', width: '26px', padding: '0px', height: '20px'}}>
                <KeyboardArrowUpIcon fontSize="inherit" />
            </button>
            <input
                style={{ borderRadius: '3px', width: '23px', height: '20px', margin: '5px 0px -1px 0px', fontSize: '0.9rem', backgroundColor: 'RGBA(255,255,255,0.7)', border: '0px grey solid', textAlign: 'center' }}
                value={selectedCheck.count || 0} onChange={handleDirectInput} defaultValue={0}
            />
            <button onClick={() => handleIncrementTest(test, -1)} disabled={(!selectedCheck.count || selectedCheck.count < 1)} style={{ marginTop: '3px', cursor: 'pointer', border: '0px', fontSize: '1.5rem', backgroundColor: 'RGBA(0,0,0,0)', width: '26px', padding: '0px 0px 0px 0px', height: '10px' }}>
                <KeyboardArrowDownIcon fontSize="inherit" />
            </button>

        </Stack>
    </Grid>

</Grid>

</div>
    
        
    )

}

export default TestIncrementor;
