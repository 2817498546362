import checkComplexRequired from "./checkComplexRequired"
import dependencyCheck from "./dependencyCheck"

const iterateRequirements = (userInput, section, sampleIndex) => {
    
    const failures = []
    section.sub_sections.forEach(ss => {

        // check this is a section that is rendered
        let checkRequirement = ss.requirements?.length > 0 ? false : true        
        
        // if sample, send parent data and sampele data
        const refInput = {...userInput, ...(sampleIndex > -1 ? userInput.samples[sampleIndex] : {}) }
        ss.requirements.forEach(r => {
            if (refInput[r.key] === r.value) {checkRequirement = true}
        })
        
        // process here
        if (checkRequirement) {
            ss.inputs?.filter(input => input.required)?.forEach(input => {
                // don't bother if the dependency fails        
                const depCheck = input.dependency ? dependencyCheck(input.dependency, userInput) : true
                // if required, must have input
                const checkResult = checkComplexRequired(input.required, refInput, sampleIndex) && !refInput[input.input_key]

                if (checkResult && depCheck) {                 
                    const mainLabel = `${ss.header}: ${input.label}`
                    const sampleLabel = sampleIndex > -1 ? ` Sample ${sampleIndex + 1}, ` : ``
                    failures.push(`${sampleLabel}${mainLabel}`)
                }                

            })
        }

        // units here
        ss.inputs?.filter(input => input.input_unit)?.forEach(input => {
            // if the input is filled and missing its unit, require it
            // required or not, it has to be there. Requirements on the parent item will handle failure to pass
            // parent input is required regardless of input
            const depCheck = input.dependency ? dependencyCheck(input.dependency, userInput) : true
            const checkResult = checkComplexRequired(input.required, refInput, sampleIndex)
            if (checkResult && depCheck && !refInput[input.input_key + "Unit"]) { 
                const mainLabel = `${ss.header}: ${input.label}'s Unit`
                const sampleLabel = sampleIndex > -1 ? ` Sample ${sampleIndex + 1}, ` : ``
                failures.push(`${sampleLabel}${mainLabel}`)                
            }
        })

    })
    
    return failures
}

export default iterateRequirements;
