import * as React from 'react';

const SamplingEventInputHeaders = ({ data }) => {

    return (
        <>

        {
            Object.keys(data.samplingEvent.samplerInputs).map(function(key) {
                
                const refItem = data.samplingEvent.samplerInputs[key]
                
                return (
                    <td key={key + "-header"} align="center">
                        {refItem.label}
                    </td>
                )
            })
        }

        </>
    )

}

export default SamplingEventInputHeaders
