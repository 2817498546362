import * as React from 'react';

// mui
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';

// for now, this works, but future, make this look into the data for a key called "COC Header Location"
const renderGroupKeys = [
    'RealEstateInfo',
    'testingParameters',
    'pickUpLocationInformation',
    'samplingLocationInformation'
]

const ProjectInformation = ({ userInput, data }) => {
    const renderGroups = renderGroupKeys.map(rg => {
        
        const clientData = data.forms.sections.find(f => f.type === 'client')?.sub_sections?.filter(f => rg === f.sectionKey)[0]
        const datum = []
        clientData?.inputs?.filter(f => userInput[f.input_key]).forEach(input => {
            datum.push({
                label: input.label,
                value: userInput[input.input_key]
            })
        })

        return {
            label: clientData?.header,
            values: datum
        };
    }).filter(f => f.values?.length > 0)

    const labUseInfoData = userInput?.signatures?.filter(s => s.type === 'receive' && s.staff === 'admin')?.sort((a,b) =>  new Date(b.dateTime) - new Date(a.dateTime))[0]
    const labUseKeys = ['prelogStorage', 'sampleCondition', 'sampleConditionDescription', 'checkIncluded', 'checkNumber']

    const labUseInfo = labUseKeys.map(lu => {
        const result = lu.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return labUseInfoData?.[lu] ? {
            key: lu,
            header: finalResult,
            value: labUseInfoData[lu]
        } : false
    }).filter(lu => lu)

  return (
        
    <TableContainer>
        <Table>
            
    <TableBody >
        {renderGroups.map((rg, i) => {
            return (
                <React.Fragment key={"COC-Side-2-"+rg.label+"-"+i}>
                <TableRow>
                    <td className="contactCell">
                        <Typography className="contactInputleader" style={{fontWeight: "bold"}}>{rg.label}</Typography>     
                    </td>
                </TableRow>
                
                {rg.values?.map(pi => {
                    return (
                        <TableRow key={'pi-header-'+pi.label}>
                            <td className="contactCell">
                                <Typography className="contactInputleader">
                                                                        
                                    {pi.label.toLowerCase().indexOf('city') > -1 ? 
                                        <>
                                            City, State, Zip: <span className="contactInputText">
                                            {pi.value}, {data.references?.states?.find(item => item.LIMS_ID === rg.values.find(f => f.label.toLowerCase().indexOf('state') > -1)?.value)?.STATE_SHORTHAND || rg.values.find(f => f.label.toLowerCase().indexOf('state') > -1)?.value} {rg.values.find(f => f.label.toLowerCase().indexOf('zip') > -1)?.value}
                                            </span>
                                        </>
                                    : null}
                                    
                                    {pi.label.toLowerCase().indexOf('city') < 0 && pi.label.toLowerCase().indexOf('state') < 0 && pi.label.toLowerCase().indexOf('zip') < 0 ? 
                                        <>{pi.label}: <span className="contactInputText">{pi.value}</span></>
                                    : null}
                                                                    
                                </Typography>  
                            </td>
                        </TableRow>
                    )
                })}
                


                </React.Fragment>
            )
        })

        }

        {labUseInfo.length ? 
        <>
            <TableRow>
                <td className="contactCell">
                    <Typography className="contactInputleader" style={{fontWeight: "bold"}}>Receipt Information</Typography>     
                </td>
            </TableRow>
            <TableRow>
                <td className="contactCell">
                    {labUseInfo.map((lu, j) => {
                        return (
                            <Typography key={lu.key + '-' + j} className="contactInputleader">
                                {labUseInfo?.prelogStorage ? 
                                    <>{lu.header}: {lu.value.replaceAll(';', ', ')}</>
                                : 
                                    <>{lu.header}: {lu.value}</>
                                }                                
                            </Typography>  
                        )
                    })}
                </td>
            </TableRow>
        </>
        : null}
        

    </TableBody>
        </Table>
    
    </TableContainer>
    
  );
}

export default ProjectInformation;
