import * as React from 'react';

// mui
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Grid from '@mui/material/Grid';

// utils
import '../../../utils/styles/styleSheet.css'

const SamplesPagination = ({ maxPages, pageSamples, setPageSamples }) => {
    

    const handleFirstPageButtonClick = () => {
      setPageSamples(0);
      window.scrollTo(0, 0)
      
    };
  
    const handleBackButtonClick = () => {
      setPageSamples(pageSamples - 1);
      window.scrollTo(0, 0)
      
    };
  
    const handleNextButtonClick = () => {
      setPageSamples(pageSamples + 1);
      window.scrollTo(0, 0)
      
    };
  
    const handleLastPageButtonClick = () => {
      setPageSamples(maxPages);
      window.scrollTo(0, 0)
      
    };    
    
        
  return (
    <>
<Grid container spacing={0} >

<Grid item md={12}>
  <Typography style={{color: "#888888", fontWeight: "bold"}}>Samples Page: {pageSamples + 1} of {maxPages}</Typography>
</Grid>


<Grid item md={12}>
  <IconButton
    style={{color: pageSamples === 0 ? "#DDDDDD" : "#888888"}}
    onClick={handleFirstPageButtonClick}
    disabled={pageSamples === 0}
    aria-label="first page"
  >
    <FirstPageIcon />        
  </IconButton>
  <IconButton
    style={{color: pageSamples === 0 ? "#DDDDDD" : "#888888"}}
    onClick={handleBackButtonClick}
    disabled={pageSamples === 0}
    aria-label="next page"
  >
    <KeyboardArrowLeft />     
  </IconButton>
  <IconButton
    style={{color: pageSamples === maxPages - 1 ? "#DDDDDD" : "#888888"}}
    onClick={handleNextButtonClick}
    disabled={pageSamples === maxPages - 1}
    aria-label="previous page"
  >
    <KeyboardArrowRight />       
  </IconButton>
  <IconButton
    style={{color: pageSamples === maxPages - 1 ? "#DDDDDD" : "#888888"}}
    onClick={handleLastPageButtonClick}
    disabled={pageSamples === maxPages - 1}
    aria-label="last page"
  >
    <LastPageIcon />       
  </IconButton>
</Grid>


</Grid>
     
        </>
  );
}

export default SamplesPagination
