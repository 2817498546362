import React from 'react';

// components
import Signature from '../dialogues/Signature';
import SaveSubmission from '../dialogues/SaveSubmission';
import SamplingSubmission from '../dialogues/SamplingSubmission';

// mui
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveIcon from '@mui/icons-material/Save';
import { Grid } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import SendIcon from '@mui/icons-material/Send';

// utils
import "../../utils/styles/cocStyle.css"

const FormSignatures = ({ data, userInput, setUserData, setMode, setGlobalLoading, samplingMode }) => {
  const [signOpen, setSignOpen] = React.useState(false)
  const [saveOpen, setSaveOpen] = React.useState(false)
  const [samplingSubOpen, setSamplingSubOpen] = React.useState(false)

  const signatures = userInput.signatures?.filter(s => s.type === 'relinquish').sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});

  const initialSignature = signatures ? signatures[0] : false

  React.useEffect(() => {

    if (!userInput.submissionID) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }

  }, [userInput]);

  return (
    <div style={{marginTop: "5px", marginLeft: "20px", marginRight:"5px", marginBottom:"5px", width:'100%'}}>

      <Signature data={data} open={signOpen} setOpen={setSignOpen} userInput={userInput} setUserData={setUserData} samplingMode={samplingMode} /> 
      <SaveSubmission data={data} samplingMode={samplingMode} open={saveOpen} setOpen={setSaveOpen} userInput={userInput} setUserData={setUserData} />
      <SamplingSubmission data={data} samplingMode={samplingMode} open={samplingSubOpen} setOpen={setSamplingSubOpen} userInput={userInput} setUserData={setUserData} setGlobalLoading={setGlobalLoading}/>
        
      {initialSignature ? null :
        <Grid container spacing={2} style={{width: '100%'}}>
          
          {/* show buttons if its unsaved or saved, but no further */}
          {!userInput.status || userInput.status === 'saved' || userInput.status === 'saved-form' ?
          <>
              <Grid item xs={12} md={2}  >
                <Button onClick={() => setMode("form")} startIcon={<UndoIcon style={{fontSize: '1rem'}} />} variant='contained' style={{ backgroundColor: 'white', border: '1px solid rgba(38, 168, 137, 1)', color: 'rgba(38, 168, 137, 1)', width: '100%', marginTop:'40px'}} >
                    Revise              
                </Button>            
              </Grid>

            <Grid item xs={12} md={2}>
              <Button onClick={() => setSaveOpen(true)} variant='contained' endIcon={<SaveIcon style={{fontSize: '1rem'}} />} style={{ backgroundColor: 'white', border: '1px solid rgba(38, 168, 137, 1)', color: 'rgba(38, 168, 137, 1)', width: '100%', marginTop:'40px'}} color="success">              
                  Save for Later       
              </Button>
            </Grid>
            
            <Grid item xs={0} md={6}></Grid>

            {userInput.serviceLine === 'cannabis-sampling' ? 
              <Grid item xs={12} md={2}>
                <Button 
                    endIcon={<SendIcon style={{fontSize: "1.6em"}} />} 
                    variant="contained" 
                    className="relButton"
                    style={{ backgroundColor: 'rgba(38, 168, 137, 1)', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}}
                    onClick={() => setSamplingSubOpen(true)}
                >
                    Save & Submit Sampling Request
                </Button>
              </Grid>
            : null }

            {userInput.serviceLine !== 'cannabis-sampling' && !samplingMode ?
              <Grid item xs={12} md={2}>
                <Button onClick={() => setSignOpen(true)} variant='contained' endIcon={<BorderColorIcon style={{fontSize: '2rem'}} />} style={{ backgroundColor: 'rgba(38, 168, 137, 1)', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}} color="success">              
                    Save & Relinquish              
                </Button>  
              </Grid>
            : null }

            {userInput.serviceLine !== 'cannabis-sampling' && samplingMode ?
              <Grid item xs={12} md={2}>
                <Button onClick={() => setSignOpen(true)} className="relButton" variant='contained' endIcon={<BorderColorIcon style={{fontSize: '2rem'}} />} style={{ backgroundColor: 'rgba(38, 168, 137, 1)', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}} color="success">              
                  Save & Submit Sampling Request              
                </Button>  
              </Grid>
            : null }




          </>
          : null}

            {userInput.serviceLine === 'cannabis-sampling' && userInput.status === 'samplingComplete' ? 
              <Grid item xs={12} md={12}>
                <Button onClick={() => setSignOpen(true)} variant='contained' endIcon={<BorderColorIcon style={{fontSize: '2rem'}} />} style={{ backgroundColor: 'rgba(38, 168, 137, 1)', width: '100%', height:'75px', fontSize: '1.5rem', fontWeight: 'bold'}} color="success">              
                    Save & Relinquish              
                </Button>  
              </Grid>
            : 
              null
            }
        </Grid>
      }

    </div>         
  );
}

export default FormSignatures;
