import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Loading = () => {
  return (
    <Box sx={{ width: '100%' }} >
        <Paper sx={{ width: '100%' }} elevation={2} className="formCanvas"  >
        <Grid container spacing={1} >                
        
        <Grid item xs={12} md={12} className="formHeaderContainer" >
        <Typography component='h6' variant='h6'>Submitting Project</Typography>
        
        
      </Grid></Grid>
      <LinearProgress />
      </Paper>
    </Box>
  );
}

export default Loading;
