import { cloneDeep} from 'lodash';

const assignTestToGroup = (incomingTest, materialOrder, data) => {
    const targetTest = cloneDeep(incomingTest)
    // list each available parent
    const parentTests = materialOrder?.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === 'parent')

    // get a list of the bottles for this particular test
    const bottlesList = data.testing[targetTest.type + 's']?.filter(t => t.LIMS_ID === targetTest.LIMS_ID).map(t => t.BOTTLE_ID).filter(Boolean)
    const bottlesUnique = [...new Set(bottlesList)]
    
    // full list of test's parameter-methods (to avoid overlap)
    const incomingFullTest = data.testing[targetTest.type + 's']?.filter(t => t.LIMS_ID === targetTest.LIMS_ID).map(t => t.PM_LIMS_ID);
    
    // helper that lets us know we found a parent while iterating parents
    let consolidateTarget = false

    parentTests?.forEach(parent => {
        // all child tests grouped with parent             
        const parentChildren = materialOrder.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === parent.LIMS_ID)

        // compare the incoming test with ALL of the group's parameterMethods. If there are any overlaps, do not consolidate!
        const fullGroupParameterMethods = [parent, ...parentChildren].map(gt => {
            return data.testing[parent.type + 's']?.filter(t => t.LIMS_ID === gt.LIMS_ID).map(t => t.PM_LIMS_ID);
        }).flat()
        const overlappingAnalytes = incomingFullTest.filter(value => fullGroupParameterMethods.includes(value)).length > 0;

        // compare the incoming test with the group's parent bottles. If there are any overlaps, do not consolidate. 
        // parent only because we are OK with top-down checks only, no bottom-ups, so to speak. If Test 2 could be a parent to Test 1 we're not bothering checking that here.
        const parentBottlesList = data.testing[parent.type + 's']?.filter(t => t.LIMS_ID === parent.LIMS_ID).map(t => t.BOTTLE_ID).filter(Boolean)
        
        // check all incoming bottles with potential parent bottles. If overlap = incoming that means parent is amendable for new child
        const overlappingBottles = bottlesUnique.filter(value => parentBottlesList.includes(value));

        // if all potential child's bottles are overlapped fully in potential parent AND this potential child does not already have a parent AND there's no overlapping parameter methods...
        if (overlappingBottles.length === bottlesUnique.length && !consolidateTarget && !overlappingAnalytes) {
            // assign the consolidation target!
            consolidateTarget = parent.consolidateTarget === 'parent' ? parent.LIMS_ID : parent.consolidateTarget
        }
    })

    // set the consolidate target. If it didn't find one, the child becomes a parent!   
    targetTest.consolidateTarget = consolidateTarget ? consolidateTarget : 'parent'
    return targetTest
}

export default assignTestToGroup;
