import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:'#EEEEEE',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// to use this set open as an object with these keys: 
// setOpen({
//   header: 'header',
//   body: 'body',
//   options: array,
//   initOption: array[0]
// })

const GenericSelect = ({ open, setOpen, submitEntry }) => {

  const [selection, setSelection] = React.useState(false)

  const handleCancel = () => {    
    setOpen(false);
    setSelection(false)
  };

  const handleSave = () => {
    setOpen(false);
    setSelection(false)
    submitEntry(selection ? selection : open.initOption)
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{open.header}</DialogTitle>
        <DialogContent >

          <Item >       
              <DialogContentText id="alert-dialog-slide-description">
                {open.body}
              </DialogContentText>    
              <FormControl fullWidth>
                  {!open.options ?
                  null
                  :
                  <Select
                      labelId="demo-simple-select-label"
                      style={{width: "100%"}}
                      id="demo-simple-select"
                      value={selection || open.initOption}
                      onChange={(e) => {
                        setSelection(e.target.value);
                      }}
                  >
                      {open.options?.map((item, i) => {
                        return <MenuItem key={item+i} value={item}>{item}</MenuItem>
                      })}               
                  </Select>
                  }

                  <FormHelperText id="component-helper-text">
                      {open.helperText}
                  </FormHelperText>
                  
                  </FormControl>
          </Item>
        </DialogContent>
            
              
        <DialogActions>
          <Button onClick={handleSave}>Confirm Selection</Button>
          <Button onClick={handleCancel}>Go Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenericSelect;
