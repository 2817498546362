import React from 'react';

// mui
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const UserTextArea = ({ value, handleInput, refItem }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''
    // just FYI this is a static thing, called in SampleDataForm right now... needs to be moved into normal form data at some point. DO NOT FORGET TO DO THIS!

    // just FYI this will be patched in a great way in "phase 4" but I'll be fixing this quickly for current phase 3 production 
    
    return (
        <Grid item xs={value.xs} md={value.md} >
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                label={value.label}
                sx={{width: "100%"}}
                multiline
                maxRows={13}
                onChange={((e) => handleInput(e, value.input_key))}
                value={useInput}
            />
        </Grid>


        


    );
}

export default UserTextArea;
