import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import UserInput from './userInputs/UserInput';
import UserSelect from './userInputs/UserSelect.js'
import UserSelectAutoComplete from './userInputs/UserSelectAutoComplete';
import UserDatePicker from './userInputs/UserDatePicker';
import UserTimePicker from './userInputs/UserTimePicker';
import UserCheckBoxes from './userInputs/UserCheckBoxes';
import UserTextArea from './userInputs/UserTextArea';
import UserTextFieldMulti from './userInputs/UserTextFieldMulti';
import UserRadioGroup from './userInputs/UserRadioGroup.js'

// utils
import dependencyCheck from '../../utils/functions/dependencyCheck';

const InputObjRender = ({ sourceDataObj, objKey, data, userInput, disabled, handleInput, sampleIndex, checkRequirements, setUserInput, mode, filteredInputs }) => {
    
    const killKey = (key) => {        
        
        if (sampleIndex > -1) {            
            const foundSample = userInput.samples[sampleIndex]

            if (foundSample && foundSample[key]) {
                const newInput = cloneDeep(userInput)
                const clonedSample = newInput.samples[sampleIndex]
                delete clonedSample[key]
                setUserInput(newInput)
            }
        }
        
    }

    const useInputs = filteredInputs || sourceDataObj.inputs

    return (
    <>
    {sourceDataObj ? Object.entries(useInputs).map(([key, value]) => {
            
            let useInput = sampleIndex > -1 ? {...userInput.samples.find(s => s.id === sampleIndex), ...userInput} : userInput
            if (useInput.materialOrders?.length) {
                useInput = {...useInput, ...useInput.materialOrders[0]}
            }
            const renderCheck = value.dependency ? dependencyCheck(value.dependency, useInput) : value.type      

            if (!renderCheck) {
                killKey(key)
            }   

            const renderType = renderCheck ? value.type : false            
            const useKey = sampleIndex > -1 ? key + '-' + sampleIndex : key + "-main"            

            switch(renderType) {
                case 'textArea':                     

                    return (
                        <React.Fragment key={value.input_key}>
                            <UserTextArea 
                                handleInput={handleInput} 
                                value={value}
                                refItem={useInput} 
                            />
                        </React.Fragment>
                    )

                case 'checkBoxes': 

                    return (
                        <React.Fragment key={value.input_key}>
                            <UserCheckBoxes 
                                value={value} 
                                refItem={useInput} 
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                handleInput={handleInput} 
                                checkRequirements={checkRequirements}
                                sampleIndex={sampleIndex}
                            />
                        </React.Fragment>
                    )

                    case 'datePicker': 

                    return (
                        <React.Fragment key={value.input_key}>
                            <UserDatePicker 
                                key={useKey}
                                data={data}
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                value={value} 
                                refItem={useInput} 
                                handleInput={handleInput} 
                                sampleIndex={!!sampleIndex} 
                                checkRequirements={checkRequirements}
                            />
                        </React.Fragment>
                    )
                    
                    case 'timePicker': 

                    return (
                        <React.Fragment key={value.input_key}>
                            <UserTimePicker 
                                key={useKey}
                                data={data}
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                value={value} 
                                refItem={useInput} 
                                handleInput={handleInput} 
                                sampleIndex={!!sampleIndex} 
                                checkRequirements={checkRequirements}
                            />
                        </React.Fragment>
                    )
                    case 'multiInput': 

                        return (
                            <React.Fragment key={value.input_key}>
                                <UserTextFieldMulti
                                    userInput={userInput}
                                    disabled={!!disabled[objKey] || disabled === 'force'}
                                    value={value} 
                                    refItem={useInput} 
                                    handleInput={handleInput} 
                                    sampleIndex={sampleIndex} 
                                />
                            </React.Fragment>
                        )
                    case 'input': 

                    return (
                        <React.Fragment key={value.input_key}>
                            <UserInput 
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                value={value}
                                refItem={useInput}
                                handleInput={handleInput}
                                sampleIndex={sampleIndex}
                                checkRequirements={checkRequirements}
                                userInput={userInput}
                                data={data}

                            />
                        </React.Fragment>
                    )


                    case 'select': 
                    
                    return (
                        <React.Fragment key={value.input_key}>
                            <UserSelect
                                data={data}
                                value={value}
                                refItem={useInput}
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                handleInput={handleInput}
                                sampleIndex={sampleIndex}
                                checkRequirements={checkRequirements}
                                userInput={userInput}
                            />                         
                        </React.Fragment>
                    )
                    case 'radioGroup': 
                    
                    return (
                        <React.Fragment key={value.input_key}>
                            <UserRadioGroup
                                data={data}
                                value={value}
                                refItem={useInput}
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                handleInput={handleInput}
                                sampleIndex={sampleIndex}
                                checkRequirements={checkRequirements}
                                userInput={userInput}
                            />                         
                        </React.Fragment>
                    )
                    case 'selectAutoComplete':        
                    
                    return (
                        <React.Fragment key={value.input_key}>
                            <UserSelectAutoComplete                                
                                sampleIndex={sampleIndex} 
                                handleInput={handleInput}
                                disabled={!!disabled[objKey] || disabled === 'force'}
                                refItem={useInput} 
                                value={value || false}
                                checkRequirements={checkRequirements}
                                userInput={userInput}
                                data={data}
                            />                  
                        </React.Fragment>
                    )
                    default:
                        
                        return null
                }


            })
    : null}
    </>
    );
}

export default InputObjRender;
