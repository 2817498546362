import React from 'react';

// components
import PreliminaryOrSaved from './Headers/PreliminaryOrSaved.js';
import PostSubmissionReview from './Headers/PostSubmissionReview.js';
import MaterialsSubmissionReview from './Headers/MaterialsSubmissionReview.js';
import MaterialsSubmissionSubmitted from './Headers/MaterialsSubmissionSubmitted.js';
import SubmissionError from './Headers/SubmissionError.js';
import LabUse from '../dialogues/LabUse.js';

// mui
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// styles
import '../../utils/styles/styleSheet.css';

const ReviewHeader = ({ error, setMode, userInput, setUserInput, data, relinquishMode, setRelinquishMode, setError, setLoading, entryMode, globalLoading, setGlobalLoading, samplingMode }) => {

    const [labUseOpen, setLabUseOpen] = React.useState(false)

    return (
        <Paper elevation={2} className="formCanvasReview"  >

            <LabUse userInput={userInput} open={labUseOpen} setOpen={setLabUseOpen} setUserInput={setUserInput}/> 
 
            <SubmissionError error={error} userInput={userInput} data={data} setRelinquishMode={setRelinquishMode} labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} />
            
            <Grid container spacing={1}>

            <>
                {( userInput.submissionType === 'samples' || !userInput.submissionType || userInput.submissionType.includes('samplingEvent') ) && entryMode === "samples" ? 
                <>
                    {!userInput.status || userInput.status === "saved" || userInput.status === "saved-form" ? 
                        <PreliminaryOrSaved                                                                            
                            userInput={userInput}
                            data={data}
                            labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} 
                            setUserInput={setUserInput} 
                            setMode={setMode} 
                            setLoading={setLoading} 
                            setError={setError} 
                            relinquishMode={relinquishMode} 
                            setRelinquishMode={setRelinquishMode}
                            setGlobalLoading={setGlobalLoading}
                            samplingMode={samplingMode}
                        /> 
                    : null }

                    {!!userInput.status && userInput.status !== "saved"  && userInput.status !== "saved-form" ? 
                        <PostSubmissionReview      
                            setMode={setMode}                                                                      
                            userInput={userInput}
                            data={data}
                            labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} 
                            setUserInput={setUserInput}
                            setLoading={setLoading} 
                            setError={setError} 
                            relinquishMode={relinquishMode} 
                            setRelinquishMode={setRelinquishMode}
                            setGlobalLoading={setGlobalLoading}
                            samplingMode={samplingMode}                            
                        /> 
                    : null }
                </>
                : null}
                

                {userInput.submissionType !== 'order' && entryMode !== "samples" ? 
                <>
                    {!userInput.status || userInput.status === "saved" || userInput.status === "saved-form" ? 
                        <MaterialsSubmissionReview                                                                            
                            userInput={userInput}
                            data={data}
                            setUserInput={setUserInput} 
                            setMode={setMode} 
                            setLoading={setLoading} 
                            setError={setError} 
                            relinquishMode={relinquishMode} 
                            setRelinquishMode={setRelinquishMode}
                            setGlobalLoading={setGlobalLoading}
                        /> 
                    : 
                        <MaterialsSubmissionSubmitted                                                                            
                            userInput={userInput}
                            data={data}
                            setUserInput={setUserInput} 
                            setMode={setMode} 
                            setLoading={setLoading} 
                            setError={setError} 
                            relinquishMode={relinquishMode} 
                            setRelinquishMode={setRelinquishMode}
                            setGlobalLoading={setGlobalLoading}
                            globalLoading={globalLoading}
                        /> 
                    }
                </>
                : null}


                </>
                                

            </Grid>              
            
        </Paper>
    );
}

export default ReviewHeader;
