const ratioCalculator = (rule, mass) => {
        
    let returnVal = 0
    if (rule.rule === "ratio") {
        // currently this is just for veg at CFL so if expanding out this should be generalized
        
        // two assumptions: that mass is in kg and the output expects g
        // we can make this dynamic but just know that's why you're seeing this specific math happening.
        returnVal = rule.value * mass * 1000 / 100
        
        // return val has a threshold, if its < the lab's necessary amount then we send the lab's value. This isn't a reference anywhere yet, just build in here.
        if (returnVal < 15) {
            returnVal = 15
        }

    }


    return returnVal.toFixed(3)

}
export default ratioCalculator;
