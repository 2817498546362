import React from 'react';

// components
import SampleAmount from '../dialogues/SampleAmount';
import SaveSubmission from '../dialogues/SaveSubmission';

// mui
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';
import Alert from '@mui/material/Alert';

// styles
import '../../utils/styles/styleSheet.css';

const Submission = ({ data, checkRequirements, userInput, handleSubmit, setUserInput, submissionErrors, mirrored, samplingMode }) => {

    const [saveOpen, setSaveOpen] = React.useState(false)

    const [samAmtOpen, setSamAmtOpen] = React.useState(false)

    return (
    <Paper elevation={2} className="formCanvasSubmit">

    <SaveSubmission samplingMode={samplingMode} data={data} open={saveOpen} setOpen={setSaveOpen} userInput={userInput} setUserData={setUserInput} formMode={'form'} mirrored={mirrored} mode={'samples'} />

    <Grid container spacing={1} >   
        <Grid item xs={12} md={12}>
            
            {checkRequirements ? 
                
                <Alert style={{textAlign: 'left'}} severity="error">There are errors or missing information in the form(s) above (see below for more detail). Please resolve these before continuing.
                
                    <ul style={{textAlign: 'left'}}>
                        {submissionErrors.map((er, i) => {
                            return <li key={er+'-'+i} >{er}</li>
                        })}
                    </ul>

                </Alert>            
                
            : null}   
        </Grid>    

        <Grid item xs={12} md={3}>
            <Button
                className={"saveButton"}                     
                onClick={() => setSaveOpen(true)}
            >
                Save & Complete Later
            </Button>
        </Grid>
        <Grid item xs={12} md={9}>
            <Button 
                endIcon={<TelegramIcon style={{fontSize: "1.5em", height: '50px'}} />} 
                className={"confirmButton"}                     
                onClick={handleSubmit}
            >
                Review Submission
            </Button>
        </Grid>

    </Grid>

    
    <SampleAmount open={samAmtOpen} setOpen={setSamAmtOpen} />

    </Paper>
    );
}

export default Submission;
