import React from 'react';

// components
import AdminMainHeader from './AdminMainHeader';
import RowTools from './RowTools';
import AdminNotes from '../../dialogues/AdminNotes';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Link from "@mui/material/Link";

// utils
import dateHandlers from '../../../utils/functions/dateHandlers';
import { Grid } from '@mui/material';

import 'react-quill/dist/quill.snow.css';

const customLetter = {
  width: '18px',
  lineHeight: '18px',
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  border: '2.75px solid #000',
  marginRight: '10px',
  display: 'inline-block'
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const MainTable = ({ notes, setNotes, formData, setFilter, archiveData, data, updateSavedData, setLoading, loadLimit, setLoadLimit, loading, resetFilter }) => {

    const [notesOpen, setNotesOpen] = React.useState(false)

    const renderData = data
    const findSubContract = (ord) => {
      let subFound = false

      ord?.samples?.forEach(s => {
        s.activeTests?.forEach(at => {
          const foundTest = formData?.testing[at.type + 's']?.find(tt => tt.LIMS_ID === at.LIMS_ID);
          if (foundTest?.ASECTION?.toLowerCase().indexOf('sub') > -1) { subFound = true; }
        })
      })

      return subFound
    }
    const findPrep = (ord) => {
      let prepFound = false

      ord?.samples?.forEach(s => {
        // do something here determining if matrix requires prep
        const foundMatrix = formData?.references?.materials?.find(tt => tt.LIMS_ID === s.matrix);
        if (foundMatrix?.PREPARATION) { prepFound = true }
      })

      return prepFound
    }

    const findCooled = (ord) => {
      // this is really specific to CFL. Target this as one of many spots where we need generalization later on.
      const labUseInfo = ord?.signatures?.filter(s => s.type === 'receive' && s.staff === 'admin')?.sort((a,b) =>  new Date(b.dateTime) - new Date(a.dateTime))[0]
      return labUseInfo?.prelogStorage?.indexOf('R44') > -1 || labUseInfo?.prelogStorage?.indexOf('F44') > -1 || labUseInfo?.prelogStorage?.indexOf('R99') > -1 || labUseInfo?.prelogStorage?.indexOf('R19') > -1
    }

    return (
      <>
      <AdminNotes open={notesOpen} setOpen={setNotesOpen} notes={notes} setNotes={setNotes} />

      <TableContainer component={Paper} sx={{ width: "90%", margin: "auto", marginBottom: "50px", display: { xs: 'none', md: 'none', lg: 'flex'}}}>

          <Table size="small" aria-label="a dense table">
          
            <AdminMainHeader resetFilter={resetFilter} loading={loading} setFilter={setFilter} />


            
            <TableBody>
            {loading ? <TableRow><TableCell>Loading...</TableCell></TableRow> :
            <>
              {renderData.map((row, i) => {
                const rowNotes = notes?.filter(n => n.clientID === row.submissionID)
                const recentNote = rowNotes[rowNotes.length - 1]
                const displayNote = recentNote?.text.replace(/<[^>]*>?/gm, '').substring(0, 50) || <BorderColorIcon style={{ opacity: '0.25', fontSize: '1rem'}} />

                return (
                <TableRow
                  key={row.submissionID + '-' + i}
                  sx={{ fontSize: ".7rem", '&:last-child td, &:last-child th': { border: 0 } }}
                  className={'admin-'+row.serviceLine}
                >
                  <TableCell style={{fontSize: "0.9rem"}} component="th" scope="row" align="left" className={'admin-'+row.serviceLine+'-cell'}>
                    {decodeURIComponent(row.submissionID)}
                    {row.carryover ? 
                      <Button
                        component={Link}
                        href={`http://10.100.10.54:3000/${row.serviceLine}/${row.submissionID}`}
                        style={{ fontSize: "0.75em", color: "black", backgroundColor: "pink" }}
                        target="_blank"
                      >
                        VIEW ORIGINAL
                    </Button>
                    : null}
                  </TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">

                  <RowTools updateSavedData={updateSavedData} setLoading={setLoading} row={row} />


                  </TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{padding: '0px'}}>
                    <Button onClick={() => setNotesOpen(row)} style={{color: 'black', justifyContent: "flex-start", height: '100%', width: '100%', padding: "0px"}}>
                      <Typography align='left' style={{fontSize: ".65rem"}} className={'admin-'+row.serviceLine+'-cell'}>{displayNote}</Typography>
                    </Button>

                  </TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{row.priority}</TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{dateHandlers.standardDate(row.createdAt)}</TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{dateHandlers.standardDate(row.updatedAt)}</TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{row.serviceLine}</TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">

                    <Stack direction="row" alignItems="center" gap={1}>
                      <span>{row.samplesDelivery}</span>
                      
                    </Stack>

                  </TableCell>
                  
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{row.status}</TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">
                    <div style={{ display: 'flex'}}>
                    
                      {row.samplesDelivery?.toLowerCase().indexOf('sampling') > -1 || row.samplesDelivery?.toLowerCase().indexOf('pick-up') > -1 ? <LocalShippingIcon style={{ marginRight: '10px'}} /> : null}
                      {findSubContract(row) ? <div style={customLetter}>S</div> : null}
                      {findPrep(row) ? <div style={customLetter}>P</div> : null}
                      {findCooled(row) ? <AcUnitIcon /> : null}
                      {row.submissionType === 'samplecontainerrequest' ? <div style={customLetter}>B</div> : null}
                    </div> 
                  </TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{fontSize: ".65rem"}} align="center">{row.samples?.length}</TableCell>
                  
                  {/* OK these two need to be updated, we need to use TYPE on signatures */}
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{textAlign: 'left', whiteSpace: 'nowrap', fontSize: ".65rem"}} align="center">
                    <ul>
                      {row.signatures.filter(s => s.type === "relinquish")?.map((s, i) => {
                        return <li key={s.name+"-"+s.dateTime + "-a" + i}>{s.name} {dateHandlers.standardDate(s.dateTime)} {dateHandlers.standardTime(s.dateTime)}</li>
                      })}
                    </ul>                    
                  </TableCell>
                  <TableCell className={'admin-'+row.serviceLine+'-cell'} style={{textAlign: 'left', whiteSpace: 'nowrap', fontSize: ".65rem"}} align="center">
                    <ul>
                      {row.signatures.filter(s => s.type === "receive")?.map((s, i) => {
                        return <li key={s.name+"-"+s.dateTime + "-b" + i} style={{fontWeight: s?.staff === 'admin' ? 'bold' : 'normal'}}>{s.name} {dateHandlers.standardDate(s.dateTime)} {dateHandlers.standardTime(s.dateTime)}</li>
                      })}
                    </ul>

                  </TableCell>
                  
                </TableRow>
              )
                    }
              )}

              {archiveData && renderData.length ? 
                <TableRow>
                <TableCell colSpan={50}>
                  <Button variant="contained" style={{width: "100%"}}
                    onClick={() => {
                      setLoadLimit(loadLimit + 100)
                    }}
                  >Load More...</Button>
                </TableCell>              
                </TableRow>
              : null}
              
              {!renderData.length ? 
                <TableRow>
                <TableCell colSpan={50}>
                  Nothing matching the selected filters.
                </TableCell>              
                </TableRow>
              : null}
              </>
              }
            </TableBody>

           
          </Table>
              

                    
      </TableContainer>

      {loading ? <p style={{margin: 'auto'}}>Loading Data...</p> : 
        <Grid container spacing={2} sx={{padding: '10px', display: { md: 'flex', xs: 'flex', lg: 'none'}}}>
          {renderData.map((row, i) => {
              const rowNotes = notes?.filter(n => n.clientID === row.submissionID)
              const recentNote = rowNotes[rowNotes.length - 1]
              const displayNote = recentNote?.text.replace(/<[^>]*>?/gm, '').substring(0, 50) || <BorderColorIcon style={{ opacity: '0.25', fontSize: '1rem'}} />
            return(
            <Grid item xs={12} md={6} key={row.submissionID + '-grid-' + i}>
              <Card sx={{ minWidth: 275 }} >
              <CardContent className={'admin-'+row.serviceLine}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {row.serviceLine.charAt(0).toUpperCase() + row.serviceLine.slice(1)}
                </Typography>
                <Typography variant="h5" component="div">
                  {decodeURIComponent(row.submissionID)}
                </Typography>
                <Typography color="text.secondary">
                  Created: {dateHandlers.standardDate(row.createdAt)} {bull} Last Updated: {dateHandlers.standardDate(row.updatedAt)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Status: {row.status} {bull} Samples: {row.samples?.length}
                </Typography>
                <Typography variant="body2">               
                  <Stack direction="column" alignItems="center" gap={1}>
                  Relinquishment Type: <span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{row.samplesDelivery}</span>
                  Priority: {row.priority}
                  <div style={{ display: 'flex'}}>
                    
                    {row.samplesDelivery?.toLowerCase().indexOf('sampling') > -1 || row.samplesDelivery?.toLowerCase().indexOf('pick-up') > -1 ? <LocalShippingIcon style={{ marginRight: '10px'}} /> : null}
                    {findSubContract(row) ? <div style={customLetter}>S</div> : null}
                    {findPrep(row) ? <div style={customLetter}>P</div> : null}

                  </div> 
                  
                  </Stack>
                </Typography>
                <Typography variant="body2">
                  <Grid container>
                    <Grid item xs={6}>
                    {row.signatures.filter(s => s.type === "relinquish").length > 0 ? "Reqlinquishments:" : null}                  
                      <ul>
                        {row.signatures.filter(s => s.type === "relinquish")?.map((s, i) => {
                          return <li key={s.name+"-"+s.dateTime + "-a" + i}>{s.name} {dateHandlers.standardDate(s.dateTime)} {dateHandlers.standardTime(s.dateTime)}</li>
                        })}
                      </ul> 
                    </Grid>
                    <Grid item xs={6}>
                    {row.signatures.filter(s => s.type === "relinquish").length > 0 ? "Receipts:" : null}
                      <ul>
                        {row.signatures.filter(s => s.type === "receive")?.map((s, i) => {
                          return <li key={s.name+"-"+s.dateTime + "-b" + i}>{s.name} {dateHandlers.standardDate(s.dateTime)} {dateHandlers.standardTime(s.dateTime)}</li>
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
              <CardActions className={'admin-'+row.serviceLine}>
                <RowTools updateSavedData={updateSavedData} setLoading={setLoading} row={row} />  
                
                  <Button onClick={() => setNotesOpen(row)} style={{color: 'black', justifyContent: "flex-start"}}>
                      <Typography align='left' style={{fontSize: '0.8em'}}>{displayNote}</Typography>
                    </Button>
              </CardActions>
              </Card>
            </Grid>
          )})}
              {archiveData && renderData.length ? 
                  <Grid item xs={12}>
                  <Card sx={{ minWidth: 275, height: '100px', fontSize: '18px' }} >
                  <Button variant="contained" style={{width: "100%", height: '100%'}}
                      onClick={() => {
                        setLoadLimit(loadLimit + 100)
                      }}
                    >Load More...</Button>
                  </Card>             
                </Grid>
              : null}
              
              {!renderData.length ? 
                <Grid item xs={12}>
                  <Card sx={{ minWidth: 275, height: '100px', fontSize: '18px' }} >
                    Nothing matching the selected filters.
                  </Card>             
                </Grid>
              : null}
        </Grid>
      }

      <p style={{color: "#EEEEEE"}}>:)</p>
      </>        
    )

}

export default MainTable;
