import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import BusinessIcon from '@mui/icons-material/Business';
import ParkIcon from '@mui/icons-material/Park';

const styles = {
    headerCell: {
        fontWeight: 'bold',
        fontSize: '16px',
        paddingTop: '0px',
        paddingBottom: '0px',
        border: 'none',
        backgroundColor: '#DDDDDD'
    },
    cell: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        border: 'none',
        borderBottom: '0.5px dashed grey',
        backgroundColor: '#EEEEEE'
    }
}
const TestingSelector = ({ data, active, updateValue }) => {
    const [selected, setSelected] = React.useState('analytes')
    const [subSel, setSubSel] = React.useState(false)
    const [filter, setFilter] = React.useState('')
    const [filterPC, setFilterPC] = React.useState('')

    const spectraList = [...new Set(data?.testingNew?.spectras
        .filter(s => s.SPECTRA?.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        .filter(s => s.ACTIVE === 'j')
        .filter(s => filterPC === '' ? true : s.PRODUCTCODE?.toLowerCase().indexOf(filterPC.toLowerCase()) > -1)
        .map(s => s.LIMS_ID))]

    const scopeList = [...new Set(data?.testingNew?.parameterScopes
        .filter(s => s.SCOPE?.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        .filter(s => s.ACTIVE === '1')
        .filter(s => filterPC === '' ? true : s.PRODUCTCODE?.toLowerCase().indexOf(filterPC.toLowerCase()) > -1)
        .map(s => s.LIMS_ID))]

    const methodList = [...new Set(data?.testingNew?.parameterMethods
        .filter(s => s.ANALYTE?.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        .filter(s => s.ACTIVE === '1')
        .filter(s => filterPC === '' ? true : s.PRODUCTCODE?.toLowerCase().indexOf(filterPC.toLowerCase()) > -1)
        .map(s => s.METHOD_ID))]


    const addToGroup = (id, type, number) => {
        const newTest = {
            type: type,
            LIMS_ID: id,
            LIMS_NUMBER: number
        }
        updateValue("addTest", newTest)
    }

    const selectAll = (val, filter) => {
        const method = data.testingNew.parameterMethods.filter(pm => pm.METHOD_ID === val.METHOD_ID).filter(pm => filter === 'all' ? pm : pm.PRODUCTCODE).map(pm => pm.LIMS_ID)
        const newActive = cloneDeep(active)

        if (!newActive.individualAnalytes) {newActive.individualAnalytes = []}

        newActive.individualAnalytes = [...new Set([...newActive.individualAnalytes, ...method])]
        updateValue('individualAnalytes', newActive.individualAnalytes)

    }

    const removeAll = (val) => {
        const method = data.testingNew.parameterMethods.filter(pm => pm.METHOD_ID === val.METHOD_ID).map(pm => pm.LIMS_ID)      
        const newActive = cloneDeep(active)
        newActive.individualAnalytes = newActive.individualAnalytes.filter(value => !method.includes(value))
        updateValue('individualAnalytes', newActive.individualAnalytes)
    }

    const addInidividual = (analyte) => {
        const newActive = cloneDeep(active)
        if (!newActive.individualAnalytes) {newActive.individualAnalytes = []}
        newActive.individualAnalytes = [...new Set([...newActive.individualAnalytes, analyte])]
        updateValue('individualAnalytes', newActive.individualAnalytes)
    }

    // need to have filters on each column, sadly, make life easier, at least add product code.
    // kill filter when clicking new category
    // also, not here, but when saving the matrix list becomes null, so fix that when we work on matrix which will no doubt be modeled after this
    
    return (
        <>
            <div style={{height: "1000px", overflowY: "auto", boxSizing: "border-box", padding: "15px"}}>
                <ButtonGroup style={{width: "100%", marginBottom: "5px", overflow: "auto", backgroundColor: "rgba(245, 245, 245, 0.95)"}}>
                    <Button style={{ borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em" }} variant={selected === 'packages' ? "contained" : 'outlined'} onClick={() => setSelected('packages')} >Spectra</Button>
                    <Button style={{ borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em" }} variant={selected === 'scopes' ? "contained" : 'outlined'} onClick={() => setSelected('scopes')}>Parameter Scopes</Button>
                    <Button style={{ borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em" }} variant={selected === 'analytes' ? "contained" : 'outlined'} onClick={() => setSelected('analytes')}>Parameter Methods</Button>
                </ButtonGroup>
                    
                {spectraList && selected === 'packages' ? 
                <TableContainer >
                <Table>

                <TableHead>
                    <TableRow styule={styles.headerRow}>
                        <TableCell style={styles.headerCell}>Product Code
                            <br />
                            <TextField id="outlined-basic" label="Filter" variant="standard" value={filterPC} onChange={(event) => {setFilterPC(event.target.value)}}/>
                        </TableCell>
                        <TableCell style={styles.headerCell}>
                            <span>Name</span>
                            <br />
                            <TextField id="outlined-basic" label="Filter" variant="standard" value={filter} onChange={(event) => {setFilter(event.target.value)}}/>
                        </TableCell>
                        <TableCell style={styles.headerCell} colSpan={2}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {spectraList.map((s, i) => {
                        const test = data?.testingNew?.spectras?.find(sp => sp.LIMS_ID === s)
                        const detected = active.tests.find(t => t.LIMS_ID === test.LIMS_ID)
                        const analyteList = data?.testingNew?.spectras.filter(s => s.SPECTRA?.toLowerCase().indexOf(filter.toLowerCase()) > -1)?.filter(ps => ps.LIMS_ID === subSel)
                        const clientList = data.references?.spectraClients?.filter(psc => psc.LIMS_TEST_ID === s)

                        return (
                            <React.Fragment key={'spectraList'+i}>
                            <TableRow>
                                <TableCell style={styles.cell}>{test.PRODUCTCODE}</TableCell>
                                <TableCell style={styles.cell}>{test.SPECTRA}</TableCell>
                                <TableCell align="right" style={styles.cell}>
                                {subSel !== test.LIMS_ID ?
                                    <Button onClick={() => setSubSel(test.LIMS_ID)}
                                    endIcon={
                                        <>
                                            {clientList?.length ? <BusinessIcon /> : null}
                                        </>
                                    }
                                    >Show Details</Button>
                                    :
                                    <Button onClick={() => setSubSel(false)}>Hide Details</Button>
                                }
                                </TableCell>
                                <TableCell align="right" style={styles.cell}>
                                    <Button onClick={() => addToGroup(test.LIMS_ID, 'spectra', test.LIMS_NUMBER)} endIcon={detected ? null : <AddIcon />} disabled={detected}>
                                        {detected ?
                                        "Already Selected"
                                        :
                                        "Add"}
                                        
                                    </Button>
                                </TableCell>
                            </TableRow>
                            {subSel === test.LIMS_ID ? 
                            <TableRow>
                                <TableCell colSpan={4}>
                                <TableContainer style={{width: "100%"}}>
                                <Table style={{width: "100%"}}>
                                    {clientList.length ? 
                                        <>
                                            <TableHead>                                        
                                                <TableRow>
                                                    <TableCell style={styles.headerCell}>Client Name</TableCell>
                                                    <TableCell style={styles.headerCell}>Passkey</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {clientList.map((a, k) => {
                                                return (
                                                    <TableRow key={'client-type-'+k}>
                                                        <TableCell style={styles.cell}>{a?.CLIENT_NAME}</TableCell>
                                                        <TableCell style={styles.cell}>{a?.CLIENT_PASSKEY}</TableCell>
                                                    </TableRow>
                                                )
                                            })}                                      
                                            </TableBody>
                                        </>
                                    : null}
                                    
                                </Table>
                                </TableContainer>
                                </TableCell>
                            </TableRow>
                            : null}
                            {subSel === test.LIMS_ID ? 
                            <TableRow>
                                <TableCell colSpan={4}>
                                <TableContainer style={{width: "100%"}}>
                                <Table style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={styles.headerCell}>Analyte</TableCell>
                                            <TableCell style={styles.headerCell}>Parameter Scope</TableCell>
                                            <TableCell style={styles.headerCell}>Method</TableCell>
                                            <TableCell style={styles.headerCell}>LOQ</TableCell>
                                            <TableCell style={styles.headerCell}>Unit</TableCell>
                                            <TableCell style={styles.headerCell}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {analyteList.map((a, k) => {
                                        return (
                                            <TableRow key={'analyteList'+k}>
                                                <TableCell style={styles.cell}>{a.ANALYTE}</TableCell>
                                                <TableCell style={styles.cell}>{a.SCOPE_NAME}</TableCell>
                                                <TableCell style={styles.cell}>{a.METHODN}</TableCell>
                                                <TableCell style={styles.cell}>{a.LOQ  || 'Not Specified' || 'Not Specified'}</TableCell>
                                                <TableCell style={styles.cell}>{a.UNIT}</TableCell>
                                                <TableCell style={styles.cell}>Add to Group</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </TableCell>
                            </TableRow>
                            : null}
                            
                            </React.Fragment>
                        )
                    })}
                </TableBody>
                
                </Table>
                </TableContainer>
                : null}
                
                {scopeList && selected === 'scopes' ? 
                <TableContainer>
                <Table>

                <TableHead>
                    <TableRow>
                        <TableCell style={styles.headerCell}>Product Code
                            <br />
                            <TextField id="outlined-basic" label="Filter" value={filterPC} variant="standard"  onChange={(event) => {setFilterPC(event.target.value)}}/>
                        </TableCell>
                        <TableCell style={styles.headerCell}>
                            Name
                            <br />
                            <TextField id="outlined-basic" label="Filter" value={filter} variant="standard"  onChange={(event) => {setFilter(event.target.value)}}/></TableCell>
                        <TableCell style={styles.headerCell} colSpan={2}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scopeList.map((p, i) => {
                        const test = data?.testingNew?.parameterScopes?.find(ps => ps.LIMS_ID === p)
                        const analyteList = data?.testingNew?.parameterScopes.filter(s => s.SCOPE?.toLowerCase().indexOf(filter.toLowerCase()) > -1)?.filter(ps => ps.LIMS_ID === subSel)
                        const detected = active.tests.find(t => t.LIMS_ID === test.LIMS_ID)
                        const matrixList = data.references?.parameterScopeMaterials?.filter(psm => psm.PARAMETERSCOPE_LIMS_ID === p)
                        const clientList = data.references?.parameterScopeClients?.filter(psc => psc.LIMS_TEST_ID === p)

                        return (
                            <React.Fragment key={'scopeList'+i}>
                            <TableRow>
                                <TableCell style={styles.cell}>{test.PRODUCTCODE}</TableCell>
                                <TableCell style={styles.cell}>{test.SCOPE}</TableCell>
                                <TableCell style={styles.cell}>
                                {subSel !== test.LIMS_ID ?
                                    <Button
                                        onClick={() => setSubSel(test.LIMS_ID)}
                                        endIcon={
                                            <>
                                                {clientList?.length ? <BusinessIcon /> : null}
                                                {matrixList?.length ? <ParkIcon /> : null}
                                            </>
                                        }
                                    >
                                        Show Details
                                    </Button>
                                :
                                    <Button onClick={() => setSubSel(false)}>Hide Details</Button>
                                }
                                </TableCell>
                                <TableCell align="right" style={styles.cell}>
                                    <Button 
                                        onClick={() => addToGroup(test.LIMS_ID, 'parameterScope', test.LIMS_NUMBER)}
                                        endIcon={detected ? null : <AddIcon />}
                                        disabled={detected}
                                    >
                                        {detected ?
                                            "Already Selected"
                                            :
                                            "Add"
                                        }                                        
                                    </Button>
                                </TableCell>
                            </TableRow>
                            
                            {subSel === test.LIMS_ID ? 
                            <TableRow>
                                <TableCell colSpan={4}>
                                <TableContainer style={{width: "100%"}}>
                                <Table style={{width: "100%"}}>
                                    {matrixList.length ? 
                                        <>
                                            <TableHead>                                        
                                                <TableRow>
                                                    <TableCell style={styles.headerCell}>Material Specifications</TableCell>
                                                    <TableCell style={styles.headerCell}>Material Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {matrixList.map((a, k) => {
                                                const matrix = data.references.materials.find(mat => mat.LIMS_ID === a.MATERIAL_LIMS_ID)
                                                return (
                                                    <TableRow key={'matrix-type-'+k}>
                                                        <TableCell style={styles.cell}>{matrix?.DISPLAYNAME}</TableCell>
                                                        <TableCell style={styles.cell}>{matrix?.MATERIAL_TYPE}</TableCell>
                                                    </TableRow>
                                                )
                                            })}                                            
                                            </TableBody>
                                        </>
                                    : null}
                                    {clientList.length ? 
                                        <>
                                            <TableHead>                                        
                                                <TableRow>
                                                    <TableCell style={styles.headerCell}>Client Name</TableCell>
                                                    <TableCell style={styles.headerCell}>Passkey</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {clientList.map((a, k) => {
                                                return (
                                                    <TableRow key={'client-type-'+k}>
                                                        <TableCell style={styles.cell}>{a?.CLIENT_NAME}</TableCell>
                                                        <TableCell style={styles.cell}>{a?.CLIENT_PASSKEY}</TableCell>
                                                    </TableRow>
                                                )
                                            })}                                      
                                            </TableBody>
                                        </>
                                    : null}
                                    
                                </Table>
                                </TableContainer>
                                </TableCell>
                            </TableRow>
                            : null}
                            {subSel === test.LIMS_ID ? 
                            <TableRow>
                                <TableCell colSpan={4}>
                                <TableContainer style={{width: "100%"}}>
                                <Table style={{width: "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={styles.headerCell}>Analyte</TableCell>
                                            <TableCell style={styles.headerCell}>Method</TableCell>
                                            <TableCell style={styles.headerCell}>LOQ</TableCell>
                                            <TableCell style={styles.headerCell}>Unit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {analyteList.map((a, k) => {
                                        return (
                                            <TableRow key={'analyte-type-'+k}>
                                                <TableCell style={styles.cell}>{a.ANALYTE}</TableCell>
                                                <TableCell style={styles.cell}>{a.METHODN}</TableCell>
                                                <TableCell style={styles.cell}>{a.LOQ || 'Not Specified'}</TableCell>
                                                <TableCell style={styles.cell}>{a.UNIT}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </TableCell>
                            </TableRow>
                            : null}
                            </React.Fragment>
                        )
                    })}
                </TableBody>
                
                </Table>
                </TableContainer>
                : null}
            
                {methodList && selected === 'analytes' ? 
                <TableContainer>
                    
                    <Table>

                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.headerCell}>Product Code
                            <br />
                            <TextField id="outlined-basic" label="Filter" variant="standard" value={filterPC} onChange={(event) => {setFilterPC(event.target.value)}}/>
                            </TableCell>
                            <TableCell style={styles.headerCell}>Name
                                <br />
                                <TextField id="outlined-basic" label="Filter" variant="standard"  onChange={(event) => {setFilter(event.target.value)}}/></TableCell>
                            <TableCell style={styles.headerCell}></TableCell>
                            <TableCell style={styles.headerCell}>Individual Analytes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {methodList.map((p, i) => {
                            
                            const test = data?.testingNew?.parameterMethods?.find(ps => ps.METHOD_ID === p)
                            const analyteList = data?.testingNew?.parameterMethods.filter(s => s.ANALYTE?.toLowerCase().indexOf(filter.toLowerCase()) > -1)?.filter(ps => ps.METHOD_ID === subSel)
                            const checkList = data?.testingNew?.parameterMethods.filter(pm => pm.METHOD_ID === test.METHOD_ID).map(pm => pm.LIMS_ID)
                            const detectedAll = active.individualAnalytes?.filter(value => checkList.includes(value)).length > 0;
                            
                            return (
                                <React.Fragment key={'methodList'+i}>
                                <TableRow>
                                    <TableCell style={styles.cell}>{test.ASECTION}</TableCell>
                                    <TableCell style={styles.cell}>{test.METHODN}</TableCell>
                                    <TableCell style={styles.cell}>
                                    {subSel !== test.METHOD_ID ?
                                        <Button onClick={() => setSubSel(test.METHOD_ID)}>Show Analytes</Button>
                                        :
                                        <Button onClick={() => setSubSel(false)}>Hide Analytes</Button>
                                    } 
                                    
                                    </TableCell>
                                    <TableCell style={{...styles.cell, paddingTop: "0px", paddingBottom: "0px"}} >
                                        
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical contained button group"
                                        variant="text"
                                        style={{padding: "0px"}}
                                    >
                                        <Button onClick={() => selectAll(test, 'all')} style={{fontSize: "0.8em", padding: "0px"}}>Add all</Button>
                                        <Button onClick={() => selectAll(test, 'pc')} style={{fontSize: "0.8em", padding: "0px"}}>Add all with Product Code</Button>
                                        {detectedAll ? <Button color="error" variant="outlined" onClick={() => removeAll(test)} style={{fontSize: "0.8em", padding: "0px"}}>Remove all</Button> : null}
                                    </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                {subSel === test.METHOD_ID ? 
                                <TableRow>
                                    <TableCell colSpan={4}>
                                    <TableContainer style={{width: "100%"}}>
                                    <Table style={{width: "100%"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={styles.headerCell}>Poduct Code</TableCell>
                                                <TableCell style={styles.headerCell}>Analyte</TableCell>
                                                <TableCell style={styles.headerCell}>Method</TableCell>
                                                <TableCell style={styles.headerCell}>LOQ</TableCell>
                                                <TableCell style={styles.headerCell}>Unit</TableCell>
                                                <TableCell style={styles.headerCell}>Group</TableCell>
                                                <TableCell style={styles.headerCell}>Ind. Selector</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {analyteList.map((a, k) => {
                                            const detected = active.tests.find(t => t.LIMS_ID === a.LIMS_ID)
                                            const detectedInd = active.individualAnalytes?.find(ia => ia === a.LIMS_ID)
                                            const matrixList = data.references.parameterMethodMaterials.filter(psm => psm.PARAMETERMETHOD_LIMS_ID === a.LIMS_ID)
                                            return (
                                                <>
                                                <TableRow key={'analyteListAlt'+k}>
                                                    <TableCell style={styles.cell}>{a.PRODUCTCODE}</TableCell>
                                                    <TableCell style={styles.cell}>{a.ANALYTE}</TableCell>
                                                    <TableCell style={styles.cell}>{a.METHODN}</TableCell>
                                                    <TableCell style={styles.cell}>{a.LOQ || 'Not Specified' || 'Not Specified'}</TableCell>
                                                    <TableCell style={styles.cell}>{a.UNIT}</TableCell>
                                                    <TableCell style={styles.cell}>
                                                        <Button onClick={() => addToGroup(a.LIMS_ID, 'parameterMethod', a.LIMS_NUMBER)} endIcon={detected ? null : <AddIcon />} disabled={detected}>
                                                            {detected ?
                                                            "Already Selected"
                                                            :
                                                            "Add to Group"
                                                            }
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell style={styles.cell}>
                                                        <Button disabled={detectedInd} onClick={() => addInidividual(a.LIMS_ID, 'parameterMethod', a.LIMS_NUMBER)} endIcon={detectedInd ? null : <AddIcon />}>
                                                            {detectedInd ?
                                                            "Already Selected"
                                                            :
                                                            "Add to Ind. Selector"
                                                            }
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                {matrixList.length ? 
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                        <TableContainer style={{width: "100%"}}>
                                                        <Table style={{width: "100%"}}>
                                                            <TableHead>                                        
                                                                <TableRow>
                                                                    <TableCell colSpan={2} style={styles.headerCell}>Material Specifications for {a.ANALYTE} by {a.METHODN}</TableCell>
                                                                    <TableCell style={styles.headerCell}>Material Type</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                            {matrixList.map((a, k) => {
                                                                const matrix = data.references.materials.find(mat => mat.LIMS_ID === a.MATERIAL_LIMS_ID)
                                                                return (
                                                                    <TableRow key={'matrix-type-'+k}>
                                                                        <TableCell colSpan={2} style={styles.cell}>{matrix?.DISPLAYNAME}</TableCell>
                                                                        <TableCell style={styles.cell}>{matrix?.MATERIAL_TYPE}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                            </TableBody>
                                                        </Table>
                                                        </TableContainer>
                                                        </TableCell>
                                                    </TableRow>
                                                : null}
                                            </>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                    </TableCell>
                                </TableRow>
                                : null}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                    
                    </Table>
                </TableContainer>
                : null}
            </div>
        </>
    );

}

export default TestingSelector;
