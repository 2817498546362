import cloneDeep from 'lodash/cloneDeep';

const applySamplingDuplicates = (obj, refs, otherRefs) => {
  try {
    const newArr = []
    let sampleCount = 0
    obj.samples.forEach(sample => {
        // matrix and unit may come from lims, otherwise its just the value itself
        // both must exist in the samplingData files somewhere, if not there's a problem. 
        // so long as they never change the names should be good, but maybe it'd be nice if it checked for this before even starting
        const unitName = otherRefs.units.find(u => u.LIMS_ID === sample.batchSizeUnit)?.DISPLAYNAME || sample.batchSizeUnit;
        const unitRef = refs.massUnitConversions.find(unit => unit.unit === unitName)

        const matrixName = otherRefs.materials.find(u => u.LIMS_ID === sample.matrix)?.DISPLAYNAME || sample.matrix;

        const matrixGroup = refs.matrixConversions.find(g => g.options.indexOf(matrixName) > -1);

        if (!matrixGroup) {
          throw new Error('Error on SAMPLING DUPLICATES. Failed to find this matrix in matrixConversions database: ' + matrixName)
        }
        
        const amountKgs = Math.round(unitRef.factor * sample.batchSize.replace(/,/g, '') * 100) / 100
        
        const incrementsTable = refs.samplingDuplicates[matrixGroup.name];     
        
        const rangeRefExceeded = amountKgs > incrementsTable[incrementsTable.length - 1].max
        const rangeRef = rangeRefExceeded ? incrementsTable[incrementsTable.length - 1] : incrementsTable.find(range => amountKgs >= range.min && amountKgs <= range.max)
        
        const duplicateCount = rangeRef ? rangeRef.replicates + rangeRef.duplicates + 1 : 5
        
        for (let i = 0; i < duplicateCount; i++) {
          const newDuplicate = cloneDeep(sample)
          newDuplicate.id = sampleCount
          switch(i) {
              case 0:
                // Primary
                newDuplicate.samplingDuplicate = "Primary"
                break;
              case 1:
                // First is Duplicate
                newDuplicate.samplingDuplicate = "Duplicate"
                break;
              default:
                // 3 or more are Replicates
                newDuplicate.samplingDuplicate = "Replicate " + (i - 1)
                break;
            }
            if (rangeRef.duplicates < 1) {
              delete newDuplicate.samplingDuplicate
            }

            newArr.push(newDuplicate)
          
          sampleCount++
        }
        
        // Field Blanks (apparently not needed yet, but hurray, i did it)
        sampleCount = 0
        const fieldBlanks = rangeRef.fieldBlanks
        let idRef = newArr.length
        if (fieldBlanks > 0) {
          for (let i = 0; i < fieldBlanks; i++) {

            const newDuplicate = cloneDeep(sample)
            newDuplicate.id = sampleCount + idRef
            switch(i) {
              case 0:
                // Primary
                newDuplicate.samplingDuplicate = "Field Blank"
                break;
              default:
                // in case there's more field blanks needed
                newDuplicate.samplingDuplicate = "Additional Field Blank " + (i)
                break;
            }
          
            newArr.push(newDuplicate)
          
            sampleCount++

          }
        }
        

      // Trip Blanks => Currently just like Field Blank but only do Solvents test and ONLY if solvents is present (see source obj)
      sampleCount = 0
      const transportBlank = rangeRef.transportBlank
      idRef = newArr.length
      if (transportBlank) {
        for (let i = 0; i < transportBlank.count; i++) {

          const newDuplicate = cloneDeep(sample)   
          // kill testing here

          newDuplicate.testing = []
          transportBlank.cases.forEach(c => {
            if (c.type === 'test') {
              // find the test in the values array, if any are a mark, that test gets pushed, if nothing, then do not make field blank
              
              c.values.forEach(v => {
                const foundTest = sample.activeTests.find(t => t.productCode === v)
                if (foundTest) {
                  newDuplicate.testing.push(foundTest)
                }
              })
              if (newDuplicate.testing.length > 0) {
                newDuplicate.id = sampleCount + idRef
                newDuplicate.samplingDuplicate = `Transport Blank${i > 0 ? '-' + i : ""}`
                newArr.push(newDuplicate)
                sampleCount++
              }
            }
          })



        

        }
      }

    })

    // update IDs here
    newArr.forEach((s, i) => {
      s.id = i;
    })

    

    const newObject = cloneDeep(obj)
    newObject.samples = newArr
    newObject.duplicated = true

    return newObject
  } catch (err) {
    console.log(err.toString())
    return err.toString()
  }


    
}

export default applySamplingDuplicates;
