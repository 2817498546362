import * as React from 'react';

// components
import LabUseEntry from './LabUse/LabUseEntry';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InventoryIcon from '@mui/icons-material/Inventory';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DialogContentText, Grid, Typography } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LinearProgress from '@mui/material/LinearProgress';


// utils
import FormAPI from '../../utils/API/api-form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LabUse = ({ data, userInput, open, setOpen, setUserInput, samplingMode }) => {

  const [mode, setMode] = React.useState("home")
  const [error, setError] = React.useState(false)

  const handleSaveEntries = async (saveData) => {
    setMode("loading")             
    setError(false)        
    try {
      const res = await FormAPI.saveClientSubmission({data: {
        userJSON: saveData ? saveData : userInput
      }})
      
      
      if (res.status === 200) {        
        setUserInput(res.data)
        setOpen(false)
        setMode("home")     
        
  
      } else {        
          setError(true)
          setOpen(false)
          setMode("home")
      }
    } catch (err) {
      setError(true)
      setMode("home")
    }
  }

  const handleClose = () => {    
    setOpen(false)
    setMode("home")
    setError(false)
  }

  const adminReceive = !!userInput?.signatures?.find(s => s.type === 'receive' && s.staff === 'admin')
  const courierRelinquish = adminReceive || !!userInput?.signatures?.find(s => s.type === "relinquish" && s.staff === 'courier')
  const courierReceive = adminReceive || courierRelinquish || !!userInput?.signatures?.find(s => s.type === 'receive' && s.staff === 'courier')

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!error ? 
          <>
          <DialogTitle>
          
          <Typography variant='h4'>
            {mode === 'home' ? 
              "Relinquishment - Receipt Tools"
            : 
            <>
              {mode.indexOf("courier") > -1 ? <LocalShippingIcon /> : <InventoryIcon />} 
              {mode.indexOf("courier") > -1 ? ' Courier ' : ' Admin '} 
              {mode.indexOf("relinquish") > -1 ? 'Relinquishment' : 'Receive'}
            </>
            }
            </Typography>

          </DialogTitle>

          <DialogContent>
            {mode === "home" ? 
            // Main Menu Here
            <>
              {userInput.signatures?.length < 0 ? 
                <DialogContentText>*Samples cannot be received until it has been relinquished by client. An open field may be applied to the COC for printing and manual entry.</DialogContentText>
                  : 
                null
              }
              
              {userInput.final ? 
                null
              :
                <DialogContentText>**Lab Use Entries cannot be saved until Relinquishment is complete. Any Lab Use entries will be saved with the &quot;Final Submission&quot; action.</DialogContentText>
              }
              <Grid container spacing={2} style={{width: '100%'}}>
                <Grid item xs={12} md={6}  >
                  <Button startIcon={<BorderColorIcon style={{fontSize: '5em'}} />} variant='outlined' style={{ width: '100%', height: '200px'}} disabled={true}>
                    Client Relinquished
                  </Button>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button disabled={courierReceive || samplingMode} startIcon={<LocalShippingIcon style={{fontSize: '5em'}} />} variant='outlined' style={{ width: '100%', height: '200px'}} onClick={() => setMode("receive-courier")} color="success">
                    Receive Samples as Courier
                  </Button>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Button disabled={courierRelinquish} startIcon={<LocalShippingIcon style={{fontSize: '5em'}} />} variant='outlined' style={{ width: '100%', height: '200px'}} onClick={() => setMode("relinquish-courier")} color="success">Relinquish Samples as Courier</Button>  
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Button disabled={false} startIcon={<InventoryIcon style={{fontSize: '5em'}} />} variant='outlined' style={{ width: '100%', height: '200px'}} onClick={() => setMode("receive-admin")}>Receive Samples as Admin</Button>  
                </Grid>

              </Grid>
                
                
                

              <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
              </DialogActions>
              

            </> : null}


            {/* Variable Things Here */}
{/* How often was this being used? Not at all I think.
            {mode === "open" ? 
              <LeaveOpen setMode={setMode} userInput={userInput} setOpen={setOpen} setUserInput={setUserInput} mode={mode}/>
            :null} 
*/}

            {mode !== "loading" && mode !== "home" ? 
              <LabUseEntry data={data} handleSaveEntries={handleSaveEntries} setMode={setMode} userInput={userInput} setOpen={setOpen} setUserInput={setUserInput} mode={mode}/>
            :null}


            
            {mode === "loading" ? 
            <>
            <DialogTitle>{"Saving Lab Use Entries..."}</DialogTitle>
            <LinearProgress />
            </>
            :null}

          </DialogContent>
          </>


        : 

        // Error Here
        <>
        <DialogTitle>{"There was an error saving this entry! Refresh the page and try again."}</DialogTitle>
        <DialogActions>
            <Button variant="outlined" onClick={() => {
              window.location.reload();
            }}>Refresh Page</Button>
            <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
        </DialogActions>
          </>
        }



        
        
      </Dialog>
    </div>
  );
}

export default LabUse;
