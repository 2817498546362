const checkComplexRequired = (rule, reference, sampleID) => {

    let reqCheck = false
    if (rule?.key === '1') {
        // default
        reqCheck = true
    } else if (rule?.key) {
        const keyCheck = reference[rule.key]
        if (keyCheck, rule.value.indexOf(keyCheck) > -1) {
            reqCheck = true
        } else {            
            // samples in particular go here            
            if (sampleID > -1) {
                // samples go here!
                const refSample = reference.samples?.find(s => s.id === sampleID)
                const sampleKeyCheck = refSample[rule.key]           
                if (sampleKeyCheck && rule.value.indexOf(sampleKeyCheck) > -1) {                    
                    reqCheck = true
                }
            }
        }
    }
    return reqCheck
//     (  )   (   )  )
//     ) (   )  (  (
//     ( )  (    ) )
//     _____________
//    <_____________> ___
//    |   you       |/ _ \
//    |     might     | | |
//    |    need       |_| |
// ___|       this  |\___/
// /    \___________/    \
// \_____________________/

}

export default checkComplexRequired;
