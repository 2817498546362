import React, { useEffect } from 'react';
import ReactJson from 'react-json-view'
import { cloneDeep } from 'lodash';

// components
import AdminLogin from './Admin/Standard/AdminLogin';

// mui
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// utils
import FormAPI from '../utils/API/api-form';
import APIQueries from '../utils/API/api-queries';

const Developer = () => {
    
    const [loading, setLoading] = React.useState(false)
    const [permission, setPermission] = React.useState(false)
    const [jsonData, setJsonData] = React.useState(false)

    useEffect(() => {
      const localPermission = JSON.parse(localStorage.getItem('developer'))
      if (localPermission?.permission) {
        if (localPermission.date === new Date().getDate()) {
          setPermission(true)
        } else {
          localStorage.removeItem('developer');
        }
        
      }
      
    }, [permission])



    return (
    <>   
        {loading ? 
        
        "Loading" 
        
        : 
        
        <>

          {permission ?           
            <>
              <h1>Welcome to the very cool page.</h1>
              <iframe src="https://giphy.com/embed/MFlnQT0rT6Gzajf4FY" className="giphy-embed" allowFullScreen></iframe>
              <iframe src="https://giphy.com/embed/QuxqWk7m9ffxyfoa0a" className="giphy-embed" allowFullScreen></iframe>
              <br />
              <Button
                variant="contained"
                color="success"
                onClick={async () => {
                  const response = await FormAPI.LIMSParamMet({labCode: '1301'})
                  setJsonData(response);
                }}
              >
                Update LIMS Data
              </Button> 
                <br /><br />
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const resTesting = await FormAPI.ecocTesting({labCode: '1301'})
                const resRefs = await FormAPI.ecocReferences({labCode: '1301'})
                  const data = {
                    references: resRefs,
                    testing: resTesting
                  }
                  setJsonData(data)
              }}
            >
              Testing and References
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                // set up ready for future development of creating new items
                // need a form or a modal or something
                // commenting now because its not a common request yet
                const serviceLine = 'food'
                // const fullData = await APIQueries.getFullData('cfl', serviceLine, true, true, false, ["samplingEvent"])    
                
                // const saveResponse = await FormAPI.putFormUpdates({
                //   labCode: "cfl",
                //   serviceLine: serviceLine,
                //   data: form
                // })
                // setJsonData(saveResponse)
              }}
            >
              P
            </Button>
            
            <br /><br />
                <br /><br />
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const resForm = await FormAPI.getLIMSLogs({labCode: 'cfl'})
                setJsonData(resForm)
              }}
            >
              LIMS Logs
            </Button>


            <Button
            variant="contained"
            color="success"
            disabled={true}
            onClick={async () => {
              await FormAPI.createServiceLine({labCode: 'cfl'})
            }}
            >

            createServiceLine
            </Button>
            </>
          : 
            // get access here
            <AdminLogin setPermission={setPermission} setLoading={setLoading} target={'developer'} loading={loading} />
          }

        </>
        
        }

        
        {jsonData ? 
          <ReactJson style={{textAlign: 'left'}} src={jsonData} />
        : null}

        
    </>
        
    )

}

export default Developer;
