import FormAPI from "./api-form"

const APIQueries = {
      getFullData: async (labCode, serviceLine, testing, references, submissionID, sampling) => {
        
        try {
          const resForm = serviceLine ? await FormAPI.getForms({labCode: labCode, serviceLine: serviceLine}) : 'ignore'        
          const resTesting = testing ? await FormAPI.ecocTesting({labCode: '1301'}) : 'ignore'
          const resReferences = references ? await FormAPI.ecocReferences({labCode: '1301'}): 'ignore'
          const resSampling = sampling ? await FormAPI.getSamplingReferences({folder: 'samplingReferences', references: sampling}) : 'ignore'
          const resClientSubmissionData = submissionID ? await FormAPI.getClientSubmission({submissionID: submissionID, serviceLine: serviceLine, labCode: 'cfl'}) : 'ignore'

          const returnData = {}

          if (resForm !== 'ignore' && resForm.status !== 200) {throw new Error('Failed To Get' + 'Form Data')} 
          else if(resForm !== 'ignore') {returnData.form = resForm.data}

          if (resTesting !== 'ignore' && resTesting.status !== 200) {throw new Error('Failed To Get' + 'Testing Data')} 
          else if(resTesting !== 'ignore') {returnData.testing = resTesting.data}

          if (resReferences !== 'ignore' && resReferences.status !== 200) {throw new Error('Failed To Get' + 'References Data')} 
          else if(resReferences !== 'ignore') {returnData.references = resReferences.data}

          if (resSampling !== 'ignore' && resSampling.status !== 200) {throw new Error('Failed To Get' + 'Sampling Data')} 
          else if(resSampling !== 'ignore') {returnData.sampling = resSampling.data}

          if (resClientSubmissionData !== 'ignore' && resClientSubmissionData.status !== 200) {throw new Error('Failed To Get' + 'Clinet Submission Data')} 
          else if(resClientSubmissionData !== 'ignore') {returnData.clientSubmissionData = resClientSubmissionData.data}

          return returnData
        } catch (err) {
          console.log(err)
          return err
        }
        
      },
      getTempalte: async (userInput, specifications) => {
        try {
          const template = await FormAPI.getSubmissionTemplate({submissionID: userInput.submissionID, serviceLine: userInput.serviceLine, labCode: 'cfl', specifications: specifications || false})
          
          if (template.data) { 
            return template   

          } else {
            throw new Error('API Failed to send template.')
              
          }
        } catch (err) {
          // something went wrong...
          console.log(err)
          return {
            error: err
          }
        }        
    }

}

export default APIQueries
