import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

const SamplingWSToolbar = ({ userData, handleSave, error, loading, samplingInput, currSam, updateCurrSam, references, specifiedData, setSamplingInput }) => {

    const totalSamples = userData.samples.length

    const generateIncrements = () => {

      // first check if this sample has data already
      
      if (specifiedData.samplingData.increments?.length > 0) {
        // EXISTING
        const sampleTemplate = cloneDeep(specifiedData.samplingData)
        setSamplingInput(sampleTemplate)

      } else {
        // NEW
        const refZones = references.incrementZones[specifiedData.samplingData.dimension]
        const containerRatio = specifiedData.sampleData.containerCount <= specifiedData.rangeRef.increments ? specifiedData.sampleData.containerCount / specifiedData.rangeRef.increments : 1
        const sampleTemplate = {
          id: specifiedData.sampleData.id,
          increments: []
        }

        for (let index = 0; index < specifiedData.rangeRef.increments; index++) {
          const randInc = Math.floor(Math.random() * (refZones.length - 0)) + 0;
          
          sampleTemplate.increments.push({
            id: index,
            zone: refZones[randInc],
            container: Math.ceil(containerRatio * (index + 1))
          })
        
        }

        setSamplingInput(sampleTemplate)

        
      }
      

    }

    const refSample = userData.samples.find(s => s.id === currSam - 1)
    const refSamplingData = userData.samplingEventInputs.find(s => s.id === currSam - 1)

    return (
      <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
        <Typography variant="h5" gutterBottom>
          {refSamplingData?.columbiaSampleID} {refSample?.samplingDuplicate}
        </Typography>      
        <Divider style={{marginTop: "15px", marginBottom: "15px"}} />
        <Typography variant="h6" gutterBottom>
          Sample {currSam} of {totalSamples}
        </Typography>
        
        <ButtonGroup disabled={samplingInput?.id > -1} variant="contained" aria-label="outlined primary button group">
          <Button disabled={currSam <= 1} onClick={() => {updateCurrSam(-1)}}>Back</Button>              
          <Button disabled={currSam >= totalSamples} onClick={() => {updateCurrSam(1)}}>Forward</Button>              
        </ButtonGroup>

        <Button onClick={generateIncrements} disabled={samplingInput?.id > -1 || userData.status === "samplingComplete"} variant="contained" color="success" startIcon={<SaveIcon />} style={{marginLeft: "5px"}}>Start</Button>
        <br />
        
        <ButtonGroup disabled={!samplingInput} variant="contained" aria-label="outlined primary button group" style={{marginTop : "5px"}}>
          {/* <Button onClick={handleSave} disabled={!samplingInput} variant="contained" color="error" >Exit without Saving</Button> */}
          <Button onClick={handleSave} disabled={!samplingInput} variant="contained" color="success" startIcon={<SaveIcon />} >Save</Button>
        </ButtonGroup>
        
        
        
        {loading ? 
          <Box sx={{ width: '90%', margin: "15px" }}>          
              <LinearProgress />
              <Typography variant="h6">Now Saving...</Typography>
          </Box>   
        : null }

        {error === "saveError" ?                 
          <Alert severity="error">Save Failed. Server unable to save this work. Try &quot;	Save&quot;	 again. If this error persists please contact the administrator.</Alert>
        : null} 

        {error === "connectionError" ?                 
          <Alert severity="error">Connection to Server Failed. Server not responding. Try &quot;	Save&quot;	 again. If this error persists please contact the administrator.</Alert>
        : null} 

      </Box>
    )

}

export default SamplingWSToolbar;
