import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TableContainer } from '@mui/material';

const tableStyles = {
    cell: {
        paddingTop: '0px',
        paddingBottom: '1px',
    }
}
const UserSelect = ({ updateValue, selected, data }) => {

    const [newItem, setNewItem] = React.useState(false)
    const [userInput, setUserInput] = React.useState('')
    const [editMode, setEditMode] = React.useState(false)

    const handleMove = (index, dir) => {

        const newOptions = cloneDeep(selected.selection)
        const targetItem = newOptions.value[index]
        const newIndex = index + dir
        if (newIndex > -1 && newIndex < newOptions.value.length) {
        
            newOptions.value.splice(index, 1);

            if (dir !== 0) {
                newOptions.value.splice(newIndex, 0, targetItem);  
            }            
            
            updateValue(false, 'selection', newOptions)
        }
    }

    const handleNewItem = () => {
        const newOptions = cloneDeep(selected.selection)
        newOptions.value.push(userInput)
        updateValue(false, 'selection', newOptions)
        setUserInput('')
        setNewItem(false)
    }
    
    const saveEdited = (index) => {
        const newOptions = cloneDeep(selected.selection)
        newOptions.value[index] = userInput
        updateValue(false, 'selection', newOptions)
        setUserInput('')
        setEditMode(false)
    }

    const handleChangeType = (value) => {
        const newOptions = {
            source: value,
            value: []
        }
        updateValue(false, 'selection', newOptions)
    }

    const toggleItem = (value) => {
        const check = selected.selection?.value?.indexOf(value)
        const newOptions = cloneDeep(selected.selection)
        if (check > -1) {
            newOptions.value.splice(check, 1);
        } else {
            newOptions.value.push(value)
        }
        updateValue(false, 'selection', newOptions)
    }

    const selectAll = () => {
        const newOptions = cloneDeep(selected.selection)
        newOptions.value = data.references[selected.selection?.source].map(item => item.LIMS_ID);
        updateValue(false, 'selection', newOptions)
    }
    const selectNone = () => {
        const newOptions = cloneDeep(selected.selection)
        newOptions.value = [];
        updateValue(false, 'selection', newOptions)
    }

    return (                     
        <div style={{width: "100%", padding: "10px" }}>                           
            
            <Typography variant="h5" style={{float: "Left"}}>Select Options</Typography>
            <br /><br />
            <Typography variant="p" style={{float: "Left"}}>Specify what items should be in the drop-down, the order of those items, and add/remove existing items.</Typography>
            <br /><br />
                <Select
                    style={{width: "90%"}}
                    id="demo-simple-select"
                    value={selected.selection?.source || false}
                    label="Selection Source"
                    onChange={(e) => {
                        handleChangeType(e.target.value)
                    }}
                >
                    <MenuItem value={'specified'}>Specified List (Custom List)</MenuItem>
                    <MenuItem value={'materials'}>Material / Matrix (LIMS)</MenuItem>
                    <MenuItem value={'states'}>States (LIMS)</MenuItem>
                    <MenuItem value={'thresholds'}>Thresholds (LIMS)</MenuItem>
                </Select>

            <br /><br />
            <Typography variant="p" style={{float: "Left"}}>Specified List is a fully customizable list.</Typography>            
            <br />
            <Typography variant="p" style={{float: "Left"}}>All other tables which come from LIMS can be managed in LIMS. Any missing columns please contact the developer team.</Typography>
            
            <TableContainer style={{maxHeight: "800px", overFlowY: "scroll"}}>
                {selected.selection?.source === 'specified' ?
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        
              <TableHead>
                  <TableRow>
                      <TableCell>Order</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Tools</TableCell>
                  </TableRow>
              </TableHead>

              <TableBody>
                      
                  {selected?.selection?.value?.map((row, i) => (
                      <TableRow
                          key={selected.label + "-" + row}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                          <TableCell component="th" scope="row">
                              {i + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                              {editMode === i ? 
                                  <FormControl variant="standard">
                                  <Input 
                                      value={userInput}
                                      onChange={(e) => {
                                          setUserInput(e.target.value);
                                      }}
                                      style={{width: "400px"}}
                                  />
                                  <FormHelperText id="component-helper-text">
                                      The actual text the client will see for this item
                                  </FormHelperText>
                              </FormControl>
                              : 
                                  <>{row}</>                                    
                              }                                        
                          </TableCell>
                          <TableCell align="right">
                          {editMode === i ? 
                          <>
                                                          
                              <Tooltip title="Save Item">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => saveEdited(i)}>          
                                      <SaveIcon />
                                  </IconButton>
                              </Tooltip>   
                              
                              <Tooltip title="Cancel">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => {
                                      setEditMode(false)
                                      setUserInput('')
                                  }} >          
                                      <ClearIcon />
                                  </IconButton>
                              </Tooltip>   
                          </>
                          : 
                          <>
                              <Tooltip title="Edit Text">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => {
                                      setEditMode(i)
                                      setUserInput(row)
                                  }} >          
                                  <CreateIcon />
                                  </IconButton>
                              </Tooltip>
                                                          
                              <Tooltip title="Move Item Up">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => handleMove(i, -1)} >          
                                  <ArrowUpwardIcon />
                                  </IconButton>
                              </Tooltip>
                              
                              <Tooltip title="Move Item Down">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => handleMove(i, 1)} >          
                                  <ArrowDownwardIcon />
                                  </IconButton>                              
                              </Tooltip>

                              <Tooltip title="Remove From List">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => handleMove(i, 0)}>          
                                  <DeleteIcon />
                                  </IconButton>  
                              </Tooltip> 
                          </>
                          }
                             

                          </TableCell>
                      </TableRow>
                  ))}

                  {newItem ? 
                      <TableRow>
                      <TableCell component="th" scope="row">
                              {selected.selection.value?.length + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                              <FormControl variant="standard">
                                  <Input 
                                      value={userInput}
                                      onChange={(e) => {
                                          setUserInput(e.target.value);
                                      }}
                                      style={{width: "100%"}}
                                  />
                                  <FormHelperText id="component-helper-text">
                                      The actual text the client will see for this item
                                  </FormHelperText>
                              </FormControl>
                          </TableCell>
                          <TableCell align="right">
                                                          
                              <Tooltip title="Save Item">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={handleNewItem}>          
                                  <SaveIcon />
                                  </IconButton>
                              </Tooltip>                  
                              <Tooltip title="Cancel">
                                  <IconButton disabled={false} edge="end" aria-label="delete" onClick={() => {
                                      setNewItem(false)
                                      setUserInput('')
                                  }}>          
                                  <ClearIcon />
                                  </IconButton>
                              </Tooltip>
                              

                          </TableCell>
                      </TableRow>                    
                  :                   
                      <TableRow>
                          <TableCell colSpan={3} align="center">
                              <Tooltip title="Add Item">
                                  <Button variant="outlined" style={{width: "90%"}} onClick={() => setNewItem(true)}>Add New Item</Button>
                              </Tooltip> 

                          </TableCell>
                      </TableRow> 
                  }

              </TableBody>

          </Table>
            :
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        
                        <TableHead>
                            <TableRow>
                                {selected.selection?.source === 'materials' ?
                                <>
                                    <TableCell style={tableStyles.cell}>Material</TableCell>
                                    <TableCell style={tableStyles.cell}>Type</TableCell>
                                </>
                                : null}

                                {selected.selection?.source === 'states' ?
                                <>
                                    <TableCell style={tableStyles.cell}>State</TableCell>
                                    <TableCell style={tableStyles.cell}>Shorthand</TableCell>
                                </>
                                : null}

                                {selected.selection?.source === 'thresholds' ?
                                <>
                                    <TableCell style={tableStyles.cell}>Name</TableCell>
                                    <TableCell style={tableStyles.cell}>Details</TableCell>
                                </>
                                : null}

                                <TableCell style={tableStyles.cell} align="right">
                                    Selected
                                    <br />
                                {selected.selection?.source !== 'specified' ?
                                    <>                                
                                        <Button onClick={selectAll}>Select All</Button>                                
                                        <Button onClick={selectNone}>Select None</Button>
                                    </>
                                : null}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                                
                            {data.references[selected.selection?.source]?.map((row, i) => {
                                const checked = selected.selection?.value?.indexOf(row.LIMS_ID) > -1
                                return (
                                    <TableRow
                                        key={"selectable-item-"+i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {selected.selection?.source === 'materials' ?
                                        <>
                                            <TableCell style={tableStyles.cell}>{row.DISPLAYNAME}</TableCell>
                                            <TableCell style={tableStyles.cell}>{row.MATERIAL_TYPE}</TableCell>
                                        </>
                                        : null}
                                        
                                        {selected.selection?.source === 'states' ?
                                        <>
                                            <TableCell style={tableStyles.cell}>{row.STATE_NAME}</TableCell>
                                            <TableCell style={tableStyles.cell}>{row.STATE_SHORTHAND}</TableCell>
                                        </>
                                        : null}
                                                                  
                                        {selected.selection?.source === 'thresholds' ?
                                        <>
                                            <TableCell style={tableStyles.cell}>{row.DISPLAYNAME}</TableCell>
                                            <TableCell style={tableStyles.cell}>{row.STATE_SHORTHAND}</TableCell>
                                        </>
                                        : null}              
                                        <TableCell style={tableStyles.cell} align="right">
                                            <Checkbox
                                                style={tableStyles.cell}
                                                checked={checked}
                                                onChange={() => toggleItem(row.LIMS_ID)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                                
                            })}

                        </TableBody>

                    </Table>
            }
                </TableContainer>

            <br /><br />
            <Typography variant="p" style={{float: "Left"}}>BE ADVISED: If you change items here referenced in other form entry&apos;s validation or dependency you need go to those items and ensure they are not affected. If you are unsure about this please contact a senior PM or developer for more information.</Typography>

        </div>                        
    );

}

export default UserSelect;
