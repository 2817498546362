import React from 'react';

// mui
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const styles = {
  cell: { padding: "1px", border: "1px black solid", fontSize: "0.8em" }
}

const BalanceTableHeader = () => {
    
    return (
      
            <TableHead>
              <TableRow>
                <TableCell style={styles.cell} align="center">Weight Used (g)</TableCell>
                <TableCell style={styles.cell} align="center">Serial #</TableCell>
                <TableCell style={styles.cell} align="center">Acceptance Limits</TableCell>
                <TableCell style={styles.cell} align="center">Initial Measured</TableCell>
                <TableCell style={styles.cell} align="center">Initial Result</TableCell>
              </TableRow>
            </TableHead>

    )

}

export default BalanceTableHeader;
