import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// utils
import FormAPI from '../../../utils/API/api-form';

const AdminLogin = ({ setPermission, setLoading, target, loading }) => {
  const [userEntry, setUserEntry] = useState('');
  const [wrongPassword, setWrongPassword] = React.useState(false);

  const handleInput = (e) => {
    setUserEntry(e.target.value);
  };

  const getAccess = () => {
    setLoading(true);
    setWrongPassword(false); // Reset error state when a new attempt is made

    FormAPI.getAdminAccess({ key: userEntry, target: target })
      .then((res) => {
        if (!res.data) {
          setWrongPassword(true);
        } else {
          setPermission(true);
          localStorage.setItem(target, JSON.stringify({ permission: true, date: new Date().getDate() }));
        }
        setLoading(false);
      })
      .catch(() => {
        setWrongPassword(true);
        setLoading(false);
      });
  };

  return (
    <Box>
      <TextField
        error={wrongPassword}
        id={wrongPassword ? "filled-error-helper-text" : "basic"}
        label={wrongPassword ? "Error" : "Password"}
        value={userEntry}
        onChange={handleInput}
        helperText={wrongPassword ? "Incorrect password. Try again!" : ""}
        variant={wrongPassword ? "filled" : "outlined"}
        style={{ margin: '4px' }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {getAccess()}
        }}
        type="password"
      />
      <Button disabled={loading} variant="contained" onClick={getAccess}>
        {loading ? 'Loading...' : 'Submit'}
      </Button>
    </Box>
  );
};

export default AdminLogin;
