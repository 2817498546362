import React from 'react';

// mui
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

// utils
const styles = {
    cell: { padding: "1px", fontSize: "0.7em", backgroundColor: "white" },
    cellHeader: { fontWeight: "bold", padding: "1px", fontSize: "0.7em", backgroundColor: "white" },
    cellActive: { padding: "1px", fontSize: "0.7em", backgroundColor: "lightGreen" }
  }

const ReferenceDisplay = ({ targetMass, incrementsTable, rangeRef, samplingData }) => {

    return (
        <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
            <Typography variant="h6" >Increment References</Typography>

            <TableContainer>
        <Table>
            
            <TableRow>
                <TableCell align="center" style={styles.cellHeader} colSpan={3} sx={{width: "120px"}}>Batch Size Range</TableCell>
                
                <TableCell align="center" style={styles.cellHeader}>Required Increments</TableCell>
                <TableCell align="center" style={styles.cellHeader}>Total Target Mass to Take</TableCell>
                <TableCell align="center" style={styles.cellHeader}>Target Mass Per Increment</TableCell>
            </TableRow>

            <TableBody>
                
            {incrementsTable.map((row, i) => {
                const useStyle = i === rangeRef.key ? styles.cellActive : styles.cell
                return (
                    <TableRow key={i} >
                        <TableCell align="center" style={useStyle} >{row.min.toFixed(3)}</TableCell>
                        <TableCell align="center" style={useStyle} >-</TableCell>
                        <TableCell align="center" style={useStyle} >{row.max ? row.max.toFixed(3) + " " + row.rangeUnit: "No Max"}</TableCell>

                        <TableCell align="center" style={useStyle} >{row.increments}</TableCell>
                        <TableCell align="center" style={useStyle} >{targetMass} {row.sampleUnit}</TableCell>
                        <TableCell align="center" style={useStyle} >{(targetMass / row.increments).toFixed(3)} {row.sampleUnit}</TableCell>
                    </TableRow>
                )
            })}
            </TableBody>

        </Table>
        </TableContainer>

        <Typography variant="h6" >Container Dimension: {samplingData.dimension}</Typography>

        </Box>

        
    )

}

export default ReferenceDisplay;
