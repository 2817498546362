import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GenericSave = ({ open, setOpen, handleSave, projectID, snackOpen }) => {
    
  const handleCancel = () => {    
    setOpen(false);
  };

  const handleSelect = () => {
    handleSave(open)    
    setOpen(false);
    snackOpen(true)
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Ready to save project # {projectID}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Saving will over-write project parameters! If you are are sure you want to continue click &quot;Save&quot; below!
          </DialogContentText>
        </DialogContent>
            
              
        <DialogActions>
          <Button onClick={handleSelect}>Confirm Save</Button>
          <Button onClick={handleCancel}>Go Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenericSave;
