import React from "react";

// components
import MaterialsSummary from "../../MaterialsSummary/MaterialsSummary.js";
import Alert from '@mui/material/Alert';
import SaveSubmission from "../../dialogues/SaveSubmission.js";
import SamplingSubmission from "../../dialogues/SamplingSubmission.js";

// mui
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SaveIcon from '@mui/icons-material/Save';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// styles
import "../../../utils/styles/styleSheet.css";

const MaterialsSubmissionReview = ({ userInput, data, setUserInput, setMode, setGlobalLoading, setLoading, relinquishMode, setRelinquishMode, setError }) => {
  
  const [saveOpen, setSaveOpen] = React.useState(false)
  const [samplingSubOpen, setSamplingSubOpen] = React.useState(false)

  return (
      <>
        <SaveSubmission data={data} samplingMode={false} open={saveOpen} setOpen={setSaveOpen} userInput={userInput} setUserData={setUserInput} mode={'materials'} />
        <SamplingSubmission data={data} samplingMode={false} open={samplingSubOpen} setOpen={setSamplingSubOpen} userInput={userInput} setUserData={setUserInput} mode={'materials'} setGlobalLoading={setGlobalLoading} />

        <Grid container spacing={1}>
          <Grid item xs={0} md={3} sx={{ display: { md: 'inline', xs: 'none'} }}>
            <Fab onClick={() => setSaveOpen(true)} variant='extended' color="success" style={{color: 'black' }}>
              Save<SaveIcon style={{fontSize: '1.5rem', marginLeft: '5px'}} />
            </Fab>
            <Fab onClick={() => setMode("form")} variant='extended' style={{color: 'black', marginLeft: '5px', padding: '0 50px 0 50px', border: '3px solid green' }}>
              Revise
            </Fab>
          </Grid>
          <Grid item xs={12} md={6} >
            {userInput.status === 'saved' ? 
              <Typography
                component="h5"
                variant="h5"
                style={{ margin: "auto", fontSize: "2.1em", width: "100%", color: 'green', fontWeight: 'bold' }}
              >
                Saved, Not Submitted!
              </Typography>
            :
              <Typography
                component="h5"
                variant="h5"
                style={{ margin: "auto", fontSize: "2.1em", width: "100%", color: 'green', fontWeight: 'bold' }}
              >
                Not Submitted!
              </Typography>
            }
            <Divider style={{ width: "100%", margin: "auto", marginTop: "30px", marginBottom: "15px", border: '1px solid RGB(50, 50, 50)' }} />
          </Grid>
          <Grid item xs={12} md={3}>
            {!userInput.status?.includes('fulfillment') && !userInput.status?.includes('submitted') ? 
              <Button 
                endIcon={<SendIcon style={{ fontSize: "1.25em" }} />} 
                variant="contained"
                className="matRelButton"
                style={{marginBottom: '15px', marginTop: '5px'}}
                onClick={() => setSamplingSubOpen(true)}
              >
                Submit
              </Button> 
            : null}
          </Grid>
          <Grid item xs={12} md={0} sx={{ display: { md: 'none', xs: 'inline'} }}>
            <Fab onClick={() => setSaveOpen(true)} variant='extended' color="success" style={{color: 'black' }}>
              Save<SaveIcon style={{fontSize: '1.5rem', marginLeft: '5px'}} />
            </Fab>
            <Fab onClick={() => setMode("form")} variant='extended' style={{color: 'black', marginLeft: '5px', padding: '0 50px 0 50px', border: '3px solid green' }}>
              Revise
            </Fab>
          </Grid>

        </Grid>
        {/* Headers */}





        <Typography
          component="p"
          variant="p"
          style={{ margin: "auto", marginBottom: '15px', fontSize: "1.25em", width: "100%" }}
        >
          Please review your submission information, analysis requries, and submit to complete.
        </Typography>

        <br />
        <br />

      {userInput.submissionID && !userInput.signatures?.length < 1 ?
        <Alert severity="error" style={{justifyContent: 'center'}}>Your submission is saved but not submitted. <b>To confirm request click SUBMIT below.</b></Alert>
      : null}

        
      <MaterialsSummary 
        mode={'review'}
        data={data}
        userInput={userInput}
      />

    </>
  );
};

export default MaterialsSubmissionReview;
