import * as React from 'react';

// mui
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const TestingRow = ({ testingList, data }) => {

    return (
        <>
            {
                testingList.map((m, i) => {
                    
                    const test = data.testing[m.type + 's']?.find(t => t.LIMS_ID === m.LIMS_ID);
                    const display = m.other ? "00001 - Other" 
                    :                     
                        (test?.PRODUCTCODE ? test?.PRODUCTCODE + ' - ' : '')                    
                    + test?.DISPLAYNAME
                    const displayInds = m.individualName ? m.individualName + (m.index ? (' ' + m.index) : '') : false
                    return (
                        <TableCell rowSpan={2} key={'test-header-' + (displayInds || display) + '-' + i} className="methodHeaderCell" >
                            <p className="methodHeaderText">{displayInds || display}</p>
                        </TableCell>
                    )
                })
            }
        </>
    );
}

export default TestingRow;


