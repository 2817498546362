import axios from "axios";

const FormAPI = {
  getForms: function(query) {
    return axios.get("/api/get-forms/", { params: { q: query } })
  },
  getFormsHistory: function(query) {
    return axios.get("/api/get-forms-history/", { params: { q: query } })
  },
  getClientSubmission: function(query) {
    return axios.get(`/api/client-submission`, { params: { q: query } });
  },
  getSubmissionTemplate: function(query) {
    return axios.get(`/api/client-submission-template`, { params: { q: query } });
  },
  saveClientSubmission: function(query) {
    return axios.put("/api/client-submission-save", {params: { q: query}})
  },
  submitClientDataEmail: function(query) {
    return axios.put("/api/email-client", {params: { q: query}})
  },
  getAdminAccess: function(query) {    
    return axios.put("/api/admin-access", {params: { q: query}})
  },
  getAdminClientList: function(query) {    
    return axios.get("/api/client-bulk", { params: { q: query } })
  },
  getSamplingReferences: function(query) {    
    return axios.get("/api/sampling-event-references", { params: { q: query } })
  },
  getServiceLines: function(query) {
    return axios.get("/api/service-line-list/", { params: { q: query } })
  },
  ecocTesting: function(query) {
    return axios.get("/api/current-testing-data/", { params: { q: query } })
  },
  ecocReferences: function(query) {
    return axios.get("/api/current-general-references/", { params: { q: query } })
  },
  putFormUpdates: function(query) {
    return axios.put("/api/save-forms/", { params: { q: query } })
  },
  LIMSParamMet: function(query) {
    return axios.get("/api/LIMS-all-references/", { params: { q: query } })
  },
  getLIMSLogs: function(query) {
    return axios.get("/api/LIMS-logs/", { params: { q: query } })
  },
  putAdminNotes: function(query) {
    return axios.put("/api/save-notes/", { params: { q: query } })
  },
  getAdminNotes: function(query) {
    return axios.get("/api/submission-notes/", { params: { q: query } })
  },
  // these will be deleted
  createServiceLine: function(query) {
    return axios.put("/api/db-form-converter/", { params: { q: query } })
  },
  convertClientSubsToNew: function(query) {
    return axios.get("/api/client-form-json-to-db-convert/", { params: { q: query } })
  },
  convertClientSubsToDBList: function(query) {
    return axios.get("/api/bulk-converted-client-data-list/", { params: { q: query } })
  },
  convertClientSubsToDB: function(query) {
    return axios.put("/api/bulk-converted-client-data-to-db/", { params: { q: query } })
  }
};

export default FormAPI
