import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TemplateProjectWarning = ({ open, setOpen, setMode, setUserInput, data }) => {

  const handleConfirm = () => {
    setOpen(false)
    setMode('form')
    setUserInput(open.data.userInput)
    window.history.pushState('page2', 'Title', '/' + open.data.userInput.serviceLine );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={!!open}
        style={{maxWidth: "100vw"}}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}><NotificationsIcon style={{ fontSize: '3rem'}}/>{"We've made some updates!"}</DialogTitle>
        <DialogContent >
        <Alert severity="success" icon={false} style={{marginBottom: '15px'}}>Some items in your original submission might look a little different.<br />If you have any questions about your submission please reach out to your project manager.</Alert>
          
          {open.changedInputs?.length > 0 ? 
            <DialogContentText>             
              <span style={{ display: "flex", alignItems: "center" }}>The following form entries have changed or have been dropped:</span>              
              <ul>
                {open.changedInputs?.map((input, i) => {
                  return <li key={'changedInput-'+input+i}>{input}</li>
                })}
              </ul>
              <ul>
                {open.lostInputs?.map((input, i) => {
                  return <li key={'lostInput-'+input+i}>{input}</li>
                })}
              </ul>
            </DialogContentText>
          : null}

          {open.changedTests?.length > 0 ? 
            <DialogContentText  component={'span'} > 
            <span style={{ display: "flex", alignItems: "center" }}>The following tests entries have changed or have been dropped:</span>               
              
              <ul>
                {open.changedTests?.map((test, i) => {
                  const foundTest = data.testing[test.type + 's']?.find(tt => tt.LIMS_ID === test.LIMS_ID);
                  return <li key={'changedInput-'+foundTest?.DISPLAYNAME+i}>{foundTest?.PRODUCTCODE} {foundTest?.DISPLAYNAME || test}</li>
                })}
              </ul>
              <ul>
                {open.lostTests?.map((test, i) => {
                  const foundTest = data.testing[test.type + 's']?.find(tt => tt.LIMS_ID === test.LIMS_ID);
                  return <li key={'lostTest-'+i+"-"+foundTest?.DISPLAYNAME}>{foundTest?.DISPLAYNAME || test}</li>
                })}
              </ul>
            </DialogContentText>
          : null}
          
          
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleCancel}>Go Back</Button>
          <Button onClick={handleConfirm}>Continue with these changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TemplateProjectWarning;
