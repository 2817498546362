import React from 'react';

// components
import BalanceTableHeader from './BalanceTableHeader';
import BalanceTableRow from './BalanceTableRow';
import BalanceTools from './BalanceTools';

// mui
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';

const BalanceValidator = ({ userData, setUserData, references }) => {

    const [editMode, setEditMode] = React.useState("open")
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [balanceEntries, setBalanceEntries] = React.useState({entries:{},assessments:{}})
    
    return (
      <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", width: '60%', margin: 'auto'}}>

        <Typography variant="h5">Balance Validation</Typography>
        
        {/* Loading */}
        {loading ? 

          <>
            <Box sx={{ width: '100%' }}>          
              <LinearProgress />
              <Typography variant="h6">Now Saving Balance Data...</Typography>
            </Box>          
          </>
        
        :
        <>

        {/* Toolbar */}
        <BalanceTools 
          userData={userData}
          setUserData={setUserData}
          balanceEntries={balanceEntries}
          setBalanceEntries={setBalanceEntries}
          editMode={editMode}
          setEditMode={setEditMode}
          setError={setError}
          error={error}
          setLoading={setLoading}
          references={references}
        />

        
        {/* Working Table */}
        <TableContainer >
          <Table sx={{ marginTop: "15px", backgroundColor: "white" }} size="small" aria-label="a dense table">

            {/* Header */}
            <BalanceTableHeader />

            <TableBody>

              {/* Render Rows Here */}
              {references.samplingEventBalanceCriteria.rows.map((row, i) => {    
                    
                return (
                  <React.Fragment key={'balanceTableRow-'+i}>
                    <BalanceTableRow editMode={editMode} balanceEntries={balanceEntries} setBalanceEntries={setBalanceEntries} keyIndex={i} row={row} references={references} />
                  </React.Fragment>
                )
              })}
                
            </TableBody>
          </Table>
        </TableContainer>
        </>
        
        }
        


        

      </Box>
    )

}

export default BalanceValidator;
