import * as React from 'react';

// mui
import TableCell from '@mui/material/TableCell';

const TestingRow = ({ bottleList }) => {
    return (
        <>
            {
                bottleList?.map((b, i) => {
                    return (
                        <TableCell rowSpan={2} key={'bottle-header-' + i} className={i === 0 ? 'firstBottleHeaderCell' : 'methodHeaderCell'} 
                            style={{borderLeft: i === 0 ? '5 px solid black' : ''}}
                        >
                            <p className="methodHeaderText">{b?.BOTTLE_NAME}</p>
                        </TableCell>
                    )
                })
            }
        </>
    );
}

export default TestingRow;


