import React from "react";

// components
import AdminArchive from "../../dialogues/AdminArchive";

// mui
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Link from "@mui/material/Link";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const RowTools = ({
  updateSavedData,
  setLoading,
  row,
}) => {
  const [archiveDialog, setArchiveDialog] = React.useState(false);

  const handleArchive = (sel) => {
    setLoading(true);
    updateSavedData(sel, true);
  };

  const handleReActive = (sel) => {
    updateSavedData(sel, false);
  };
  const statusColor = () => {
    if (row.status === 'fulfillment-saved' || row.status === 'fulfillment-started' || row.status === 'sampling-saved') {
      return 'orange'
    } else if  ((row.status === 'submitted' || row.status === 'client-signed') && (row.serviceLine === "cannabis-sampling" || row.submissionType?.includes('samplingEvent') || row.submissionType !== "samples")) {
      return 'red'
    } else if  (row.status === 'fulfillment-complete' || row.status === 'sampling-complete') {
      return 'green'
    } else if  (row.status === 'fulfillment-complete-picked-up') {
      return 'grey'
    } else {
      return 'RBGA(0,0,0,1)'
    }
  }

  return (
    <>
      <AdminArchive
        open={archiveDialog}
        setOpen={setArchiveDialog}
        handleArchive={handleArchive}
      />

      <ButtonGroup variant="text" aria-label="text button group" >
      
      <Button
          component={Link}
          href={`${window.location.href
            .replace("admin/", "")
            .replace("admin", "")}${row.serviceLine}/${encodeURIComponent(row.submissionID)}`.replace('?','')}
          style={{ fontSize: "0.75em", color: "black", border: '0px solid black' }}
          target="_blank"
          className={'admin-'+row.serviceLine+'-cell'}
        >
          Client ECOC Page
        </Button>

        <Button
          onClick={
            row.archive
              ? () => handleReActive(row.submissionID)
              : () => setArchiveDialog(row.submissionID)
          }
          style={{ fontSize: "0.75em", color: "black", border: '1px solid transparent' }}
          startIcon={<DeleteIcon style={{ fontSize: "1.5em" }} />}
          className={'admin-'+row.serviceLine+'-cell'}
        >
          {row.archive ? "Re-Active" : "Archive"}
        </Button>
        
        <Button
            component={Link}
            variant={row.submissionType !== "samples" ? 'contained' : 'none'}
            href={`${window.location.href.replace("admin/", "admin")}-${row.serviceLine}/${encodeURIComponent(row.submissionID)}`.replace('?','')}
            style={{ fontSize: "0.75em", color: "black", backgroundColor: statusColor() }}
            startIcon={row.serviceLine === "cannabis-sampling" ? <LocalShippingIcon style={{ fontSize: "1.5em" }} /> : <ContentPasteGoIcon style={{ fontSize: "1.5em" }} />}
            target="_blank"
            disabled={row.status === 'saved-form'}
            className={'admin-'+row.serviceLine+'-cell'}
          >
            {(row.serviceLine === "cannabis-sampling" && row.submissionType === "samples") || row.submissionType.includes('samplingEvent') ? "Sampling Event" : null}
            {row.serviceLine !== "cannabis-sampling" && row.submissionType === "samples" ? "Receipt Page" : null}
            {row.submissionType !== "samples" && !row.submissionType.includes('samplingEvent') ? "Materials Fulfillment" : null}

        </Button>

      </ButtonGroup>
    </>
  );
};

export default RowTools;
