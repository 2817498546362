import React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// utils
import samplingFooterEntries from '../../../../utils/references/samplingFooterEntries';

const styles = {
  cell: { maxWidth: "1em", padding: "1px", fontSize: "0.8em", backgroundColor: "white", border: "1px solid black" },
  cellHeader: { maxWidth: "1em", fontWeight: "bold", padding: "1px", fontSize: "0.8em", borderBottom: "1px solid black" },
}

const SampleWSFooter = ({ samplingInput, handleInputNumeric, handleInput, setAllFootersYes, specifiedData, previewMode }) => {

        return (
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                        <TableCell style={styles.cellHeader} align="center">
                                Tempurature
                            </TableCell>
                        
                        {samplingFooterEntries.map((foot, i) => {
                            return (
                            <TableCell style={styles.cellHeader} align="center" key={foot + i} >{foot}</TableCell>
                            )
                        })}
                        
                        {!previewMode ? 
                            <TableCell style={styles.cellHeader} align="center">
                                Tools
                            </TableCell>
                        : null}

                        

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                        <TableCell style={styles.cell} align="center">

                            {previewMode ? 
                            <>{specifiedData.samplingData.tempurature ? specifiedData.samplingData.tempurature + "°C": null}</>
                            :
                            <TextField
                                value={samplingInput.tempurature ?? " "}
                                style={{width:"100%"}}
                                onChange={(e) => {handleInputNumeric(e.target.value, "tempurature")}}
                                InputProps={{
                                endAdornment: <InputAdornment position="start">°C</InputAdornment>,
                                }}
                            />
                            }


                            </TableCell>
                        {samplingFooterEntries.map((foot, i) => {
                            return (
                            <TableCell style={styles.cell} align="center" key={foot + "-" + i}>
                                {previewMode ? 
                                    <>{specifiedData.samplingData[foot]}</>
                                :
                                <Select 
                                    value={samplingInput[foot] ?? ""}
                                    onChange={(e) => {handleInput(e.target.value, foot)}}
                                    style={{fontSize: "1em"}}
                                    fullWidth
                                >
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    
                                </Select>
                                }
                            </TableCell>
                            )
                        })}

                        {!previewMode ? 
                            <TableCell style={styles.cell} align="center">
                                <Button
                                    onClick={() => setAllFootersYes(samplingFooterEntries)}
                                >
                                Set All as &apos;Yes&apos;
                                </Button>
                            </TableCell>
                        : null}


                        </TableRow>
                        <TableRow>
                            <TableCell style={styles.cellHeader} align="center" colSpan={1 + samplingFooterEntries.length}>
                                Sampling Comments / Observations
                            </TableCell>
                        </TableRow>
                        <TableRow >

                        <TableCell style={styles.cell} align="center" colSpan={2 + samplingFooterEntries.length}>

                        {previewMode ? 
                            <>{specifiedData.samplingData.samplingComments ? specifiedData.samplingData.samplingComments : "None"}</>
                        : 
                            <TextField
                                value={samplingInput.samplingComments ?? " "}
                                onChange={(e) => {handleInput(e.target.value, "samplingComments")}}
                                fullWidth
                                multiline rows={3} 
                            />
                        }

                        </TableCell>
                                

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    )

}

export default SampleWSFooter;
