import * as React from 'react';

// components
import SamplingEventInputHeaders from './SamplingEventInputs/SamplingEventInputHeaders';

// mui
import TableRow from '@mui/material/TableRow';

const SamplesTblHeader = ({ headers, data, samplingEventMode, userInput }) => {

    const sampleData = data.forms.sections.find(f => f.type === 'sample') 
    const inputRefs = sampleData.sub_sections.map(ss => ss.inputs).flat()

    return (
        
        <TableRow>
            
            {/* sampling event headers here */}
            {samplingEventMode ? 
                <SamplingEventInputHeaders data={data} />
            : null}
                
            {headers.map((h, i) => {
                
                const refHeaders = inputRefs
                const refItem = refHeaders.find(f => f.input_key === h)  
                const refItemSampling = data.samplingEvent ? data.samplingEvent.samplerInputs[h] : false

                const samplingForm = data?.forms?.sections?.find(s => s.type === 'samplingEvent' && s.samplingEventKey === userInput.submissionType.split(',')[1])
                const fullInputs = samplingForm?.sub_sections.map(ss => ss.inputs).flat()
                const foundHeader = fullInputs?.find(input => input.input_key === h)?.label

                return <td key={"sampleHeader-"+h+"-"+i} className="cocCell" align="center"><b>
                        
                        {refItem ? refItem.label : h === "id" ? "#" : null}
                        {refItemSampling && refItemSampling.label ? refItemSampling.label : null}
                        {foundHeader ? foundHeader : null}
                        {h === "samplingDuplicate" ? "Sample Type" : null}
                        {h === "Total Mass Taken" ? "Total Mass Taken" : null}

                        </b></td>
            })}

        </TableRow>

    )

}

export default SamplesTblHeader
