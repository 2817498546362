import React from "react";

// components
import MaterialsSummary from "../../MaterialsSummary/MaterialsSummary.js";
import Alert from '@mui/material/Alert';
import SaveSubmission from "../../dialogues/SaveSubmission.js";
import TemplateSubmission from "../SubmissionButtons/TemplateSubmission.js";
import GenerateTemplate from "../../dialogues/GenerateTemplate.js";
import TemplateProjectWarning from "../../dialogues/TemplateProjectWarning.js";

// mui
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';

// styles
import "../../../utils/styles/styleSheet.css";

const MaterialsSubmissionSubmitted = ({ userInput, data, setUserInput, setMode, globalLoading, setGlobalLoading }) => {
  const materialForm = data?.forms?.sections?.find(sec => sec.type === "materialOrder")
  const [saveOpen, setSaveOpen] = React.useState(false)
  const [templateOpen, setTemplateOpen] = React.useState(false)
  const [templateHolder, setTemplateHolder] = React.useState(false)

  return (
    <>
      <SaveSubmission data={data} samplingMode={false} open={saveOpen} setOpen={setSaveOpen} userInput={userInput} setUserData={setUserInput} mode={'materials'}/>
      <GenerateTemplate open={templateOpen} setOpen={setTemplateOpen} userInput={userInput} setUserData={setUserInput} data={data} setGlobalLoading={setGlobalLoading} setTemplateHolder={setTemplateHolder} setMode={setMode} />
      <TemplateProjectWarning open={templateHolder} setOpen={setTemplateHolder} setMode={setMode} setUserInput={setUserInput} data={data} />


      <div style={{ marginBottom: "20px", margin: "auto" }}>
      <img src="/images/cfl-logo-hr.png" alt="cfl-logo" style={{width: "33%", maxWidth: "1500px", marginTop: "15px"}} className="logo-header"/>

     {/* Green Headers */}
     {userInput.status.includes("fulfillment-complete") ?
        <Typography
        component="p"
        variant="p"
        className='materials-header'
        >
          {materialForm?.materialName || 'Material Order'} Fulfilled
        </Typography>
      :
        <Typography
        component="p"
        variant="p"
        style={{ margin: "auto", fontSize: "3em", width: "100%", color: 'rgba(6, 88, 64, 1)', fontWeight: 'bold' }}
        >
          Material Order Pending
        </Typography>
      }
      
      </div>

      <Typography
          component="p"
          variant="p"
          style={{ margin: "auto", fontSize: "1.5em", width: "100%", color: 'rgba(6, 88, 64, 1)', fontWeight: 'bold' }}
        >
          Your {materialForm?.materialName.toLowerCase() || 'Material Order'} (
            <Tooltip title="Use this link to return to the page!">
              <a
                style={{ wordBreak: "break-all", color: 'rgba(6, 88, 64, 1)' }}
                href={`${window.location.protocol}//${window.location.host}/${userInput.serviceLine}/${encodeURIComponent(userInput.submissionID)}`}
              >
                {decodeURIComponent(userInput.submissionID)}
              </a>
          </Tooltip>
          ) has been {userInput.status.includes("fulfillment-complete") ? 'completed' : 'recieved'}
        </Typography>

      <br />
      <br />

      <Grid container spacing={2} >

      {!userInput.status.includes("fulfillment-complete") ?
        <Grid item xs={12}>
          <Typography style={{ fontSize: '1.3em' }} component="p">Thank you for your submission! Your request has been submitted to the team at Columbia Laboratories and will fulfill your request soon with information on what to do next.</Typography>
          <Typography style={{ fontSize: '1.3em' }} component="p">You will receive an email when your request is fulfilled and this page will provide more information at that time.</Typography>
        </Grid>
      :
        null
      }
     
      
     </Grid> 
      
      
      
      

      

      <Divider style={{ width: "90%", margin: "auto", marginTop: "25px", marginBottom: "15px" }} />

        <MaterialsSummary 
          data={data}
          userInput={userInput}
          mode='review'
        />
        
      
    <Grid container spacing={2} >
      <Grid item xs={12} md={6}>
          <TemplateSubmission userInput={userInput} setUserInput={setUserInput} setMode={setMode} data={data} globalLoading={globalLoading} setGlobalLoading={setGlobalLoading} />
      </Grid>
      {userInput.status.includes("fulfillment-complete") ?
        <Grid item xs={12} md={6}>        
          <div style={{padding: "5px", width: "100%", margin: "auto", marginTop: "15px", minHeight: '25px' }}>
            <Tooltip title="Create a new sample submission using the information provided above!">
              <button className="newSubBtn" onClick={() => setTemplateOpen(true)}>Submit Samples</button>
            </Tooltip>
          </div>
        </Grid>
      : null}
      <Grid item xs={12}>
        {/* More alerts can go here if needed */}
        <Alert severity="warning" style={{ fontSize: "1.00em" }}>
          If you did not receive an email within 15 minutes of your submission
          or a phone call regarding this order please contact us immediately at{" "}
          <span style={{ fontWeight: "bold" }}>(503) 254-1794</span> or email us
          at{" "}
          <a href="mailto:info@columbialaboratories.com">
            info@columbialaboratories.com
          </a>
          .
        </Alert>       
      </Grid>
    </Grid>

    </>
  );
};

export default MaterialsSubmissionSubmitted;
