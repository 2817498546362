import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import FormAPI from '../../utils/API/api-form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SamplingSubmission = ({ data, open, setOpen, userInput, setUserData, mode, setGlobalLoading, samplingMode }) => {

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const finalSubmitSampling = async () => {  
    setLoading(true) 
    setGlobalLoading(true)   
    const newInput = cloneDeep(userInput)    
    newInput.status = "submitted"

    let prefix = mode === 'materials' ? 'SCR' : false
    if (samplingMode) {
      const samplingForm = data?.forms?.sections?.find(s => s.type === 'samplingEvent' && s.samplingEventKey === userInput.submissionType.split(',')[1])
      prefix = samplingForm.submissionPrefix || prefix
    }
    
    const res = await FormAPI.saveClientSubmission({data: {
        userJSON: newInput, prefix: prefix
    }})

    if (res.status === 200) {      

        const currentURL = window.location.href;   
        const urlArr = currentURL.split('/')        
        const serviceLine = urlArr[3]
        window.history.pushState('page2', 'Title', `/${serviceLine}/${res.data.submissionID}/`)

        setUserData(res.data)
        setError(false)      
        setLoading(false)        
        setGlobalLoading(false)
        setOpen(false)

        // send email
        try {
          await FormAPI.submitClientDataEmail({ data: res.data, type: mode })         
        } catch (err) {
          console.log(err)
        }       


    } else {
        setLoading(false)
        setGlobalLoading(false)
        setError("saveErr")
    }        
}

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        style={{maxWidth: "100vw"}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Ready to Submit?"}</DialogTitle>

        {loading ? 
            <LinearProgress />
          : 
            <>
            {error ? "We apologize for the inconvenience. There was an error saving your request. Please click below to try again. If the error persists please contact the laboratory right away for further assistance.."
            : 
            <DialogContent>
              
              {mode === 'materials' ? 
              <DialogContentText id="alert-dialog-slide-description">
              Clicking the Submit button below will send your request to the Columbia Laboratories team. Your submission will be fulfullilled and an email confirmation will be sent at that time.
              </DialogContentText>
              : 
              <DialogContentText id="alert-dialog-slide-description">
                Clicking the Submit button below will send your request to the Columbia Laboratories team. A Sampling Coordinator will get in touch as soon as possible to schedule the sampling event.
              </DialogContentText>
              }
              
              <br />
              <DialogContentText id="alert-dialog-slide-description-2">
                You will receive an email with a return link to this form which can be used for future submissions as a template.
              </DialogContentText>
            </DialogContent>
            }

            </>
          
          }
         
       
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
            {error ? 
              <Button onClick={finalSubmitSampling}>Try Again</Button>
            :
              <Button onClick={finalSubmitSampling}>{mode === 'materials' ? 'Yes, Submit Request' : 'Yes, Submit Sampling Event Request'}</Button>
            }          
        </DialogActions>
        
      </Dialog>
    </div>
  );
}

export default SamplingSubmission;
