import React from "react";

// components
import COCRender from "../../COCRender/COCRender.js";
import TemplateSubmission from "../SubmissionButtons/TemplateSubmission.js";
import FormSignatures from "../FormSignatures.js";
import PrepareYourSamples from "./HeaderSections/PrepareYourSamples.js";
import PrepareYourSamplingEvent from "./HeaderSections/PrepareYourSamplingEvent.js";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Tooltip from '@mui/material/Tooltip';

// styles
import "../../../utils/styles/styleSheet.css";

const PostSubmissionReview = ({ labUseOpen, setLabUseOpen, userInput, setUserInput, data, setMode, setGlobalLoading, samplingMode }) => {

  return (
    <>
      <div style={{ marginBottom: "20px", margin: "auto" }}>
        <img src="/images/cfl-logo-hr.png" alt="cfl-logo" style={{width: "33%", marginTop: "15px"}} />
        <Typography
          component="p"
          variant="p"
          style={{ margin: "auto", fontSize: "1.5em", width: "100%", color: 'rgba(6, 88, 64, 1)', fontWeight: 'bold' }}
        >
          Your sample submission (
            <Tooltip title="Use this link to return to the page!">
              <a
                style={{ wordBreak: "break-all", color: 'rgba(6, 88, 64, 1)' }}
                href={`${window.location.protocol}//${window.location.host}/${userInput.serviceLine}/${encodeURIComponent(userInput.submissionID)}`}
              >
                {decodeURIComponent(userInput.submissionID)}
              </a>
          </Tooltip>
          ) has been received!
        </Typography>

        <Typography
          component="p"
          variant="p"
          style={{ margin: "auto", fontSize: "1.25em", width: "80%" }}
        >
          A confirmation email containing your submission details and ID will be sent shortly. This email will also include a link for your reference to access this ECOC again.
        </Typography>
        <br />

        {userInput.samplesDelivery?.trim() === "Schedule Pick-Up" || userInput.samplesDelivery?.trim() === "Sampling Event Request" || samplingMode ? 
          <>        
            <Typography
              component="p"
              variant="p"
              style={{ margin: "auto", fontSize: "1.25em", width: "80%", fontWeight: 'bold' }}
            >
              Our client services team will contact you to schedule your sampling appointment.
            </Typography>
            <br />      
          </>
          : 
          null
          }
      </div>
      
      <br />

        <Divider color="black" style={{ width: "50%", margin: 'auto', marginBottom: '10px', height: '1px'}} />
        <Typography
          component="p"
          variant="p"
          style={{ margin: "auto", fontSize: "1.25em", width: "100%", marginBottom: '20px' }}
        >
          <TemplateSubmission userInput={userInput} setUserInput={setUserInput} setMode={setMode} data={data} setGlobalLoading={setGlobalLoading} />
        </Typography>
          
      {userInput.carryover ? 
      <Alert severity="warning" style={{ marginBottom: '15px' }}>This application was updated on 12-12-2023 and any orders submitted before this date were mapped into a new system and as such some information may appear differently than how it was submitted. If you are using this page as a template for future submissions, please consider using your next order as your current template. Thank you for your patience.
      </Alert> 
      : null}

      {/* Prepare Instructions */}
      {userInput && userInput.serviceLine !== 'cannabis-sampling' && !userInput.submissionType?.includes('samplingEvent') ? <PrepareYourSamples /> : null }
      {userInput && userInput.serviceLine !== 'cannabis-sampling' && userInput.submissionType?.includes('samplingEvent') ? <PrepareYourSamplingEvent /> : null }

      <FormSignatures data={data} userInput={userInput} setUserData={setUserInput} stMode={setMode} style={{width: '100%', marginBottom : '10px'}} setGlobalLoading={setGlobalLoading} samplingMode={samplingMode} />

      <COCRender
        userInput={userInput}
        setMode={setMode}
        setUserData={setUserInput}
        data={data}
        labUseOpen={labUseOpen}
        setLabUseOpen={setLabUseOpen}
        samplingMode={samplingMode}
        showBottles={samplingMode && userInput.status === 'sampling-complete'}
      />

      <Grid item xs={12} md={12}>
        <Alert severity="warning" style={{ fontSize: "1.00em" }}>
          If you did not receive an email within 15 minutes of your submission
          or a phone call regarding this order please contact us immediately at{" "}
          <span style={{ fontWeight: "bold" }}>(503) 254-1794</span> or email us
          at{" "}
          <a href="mailto:info@columbialaboratories.com">
            info@columbialaboratories.com
          </a>
          .
        </Alert>
      </Grid>
    </>
  );
};

export default PostSubmissionReview;
