import validator from 'validator';

const individualValCheck = (validation, entry) => {
  
  if (validation && entry) {
    
            
    switch(validation) {
        // check that there's validation at all, and if so, if it is/is not GOOD
        case "isEmail":
          return validator.isEmail(entry)
          
          case "isNumeric":
            return validator.isNumeric(entry)          
          
          case "isMobilePhone":
            return validator.isMobilePhone(entry)          
              
        default:
          // send back true, true means the value is OK
          return true
    }
  } else {
    return true
  }
}

export default individualValCheck;
