import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

// components
import SampleDataTable from '../Admin/Relinquishment/SampleDataTable.js';
import SampleWSIncrementsPreview from '../Admin/Relinquishment/IncrementTable/SampleWSIncrementsPreview.js';
import SampleWSFooter from '../Admin/Relinquishment/IncrementTable/SampleWSFooter.js';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';


// utils
import "../../utils/styles/COCPrint.css"
import ratioCalculator from '../../utils/functions/ratioCalculator.js';

const Item = styled(Paper)(({ theme }) => ({    
  padding: theme.spacing(0),
  textAlign: 'center',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SamplingEventPrint = ({ data, open, setOpen, userInput, maxPages, references }) => {
  
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const pages = []
  {for (let index = 0; index < maxPages; index++) {
    const sampleData = userInput.samples[index]

    const matrixName = data.references.materials.find(u => u.LIMS_ID === sampleData.matrix)?.DISPLAYNAME || sampleData.matrix;
    const matrixGroup = references.matrixConversions.find(g => g.options.indexOf(matrixName) > -1);    
    const incrementsTable = references.samplingIncrementRanges[matrixGroup.name];    

    const unitName = data.references.units.find(u => u.LIMS_ID === sampleData.batchSizeUnit)?.DISPLAYNAME || sampleData.batchSizeUnit;
    const unitRef = references.massUnitConversions.find(unit => unit.unit === unitName)

    const amountKgs = unitRef.factor * sampleData.batchSize
    const rangeRef = incrementsTable.find(range => amountKgs >= range.min && amountKgs <= range.max) ? incrementsTable.find(range => amountKgs >= range.min && amountKgs <= range.max) : incrementsTable[incrementsTable.length - 1]
    
    const samplingData = userInput.samplingEventInputs.find(inp => inp.id === sampleData.id)
    const targetMass = rangeRef.total.rule ? ratioCalculator(rangeRef.total, amountKgs) : rangeRef.total.toFixed(1)
    
    const specifiedData = {
      sampleData: sampleData,
      samplingData: samplingData,
      matrixGroup: matrixGroup,
      rangeRef: rangeRef,  
      amountKgs: amountKgs,
      targetMass: targetMass
    }

    pages.push(
        
        <React.Fragment 
            key={"SamplingWS-Rendered-"+index}                       
        > 
          <Stack className="section-to-print">

          <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
              <Grid container spacing={0}>

                <Grid item xs={3}>
           
                <Typography variant="h6" gutterBottom>Samping Event: {userInput.submissionID}</Typography>   
                  <Typography variant="body2" gutterBottom >Client: {userInput.companyName}</Typography>
                  <Typography variant="body2" gutterBottom >Contact: {userInput.contactFirstName} {userInput.contactLastName}</Typography>              
                  <Typography variant="body2" gutterBottom >{userInput.companyAddress1}</Typography>
                  <Typography variant="body2" gutterBottom >{userInput.companyCity}, {userInput.companyState} {userInput.companyZip}</Typography>
                  <Divider style={{marginTop: "15px", marginBottom: "15px"}} />

                  <Typography variant="body2" gutterBottom >{userInput?.samplingPerson?.name ? "Sampler: " + userInput.samplingPerson.name : "Name not assigned"}</Typography>
                  <Typography variant="body2" gutterBottom >{userInput?.samplingPerson?.date ? "Sampling Date: " + userInput.samplingPerson.date : "Date not assigned"}</Typography>

                </Grid>


                <Grid item xs={9}>
                  <Typography variant="h6" gutterBottom >Sample Information
                  
                  <span style={{float: "right"}}>

                  Columbia Laboratories.
                  Page {index + 1} of {maxPages}  
                
                  </span>
                  </Typography>  
                  <SampleDataTable specifiedData={specifiedData} />
                  
                  <Typography variant="h6" gutterBottom>Balance Information</Typography>  
                  <TableContainer style={{width: "100%"}}>
                    <Table>
      
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">Weight Serial #</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">Measured Mass, g</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">{userInput.samplingBalanceData?.entries["weightSerial-1"]}</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">{userInput.samplingBalanceData?.entries["1-Mass Observation"]}</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">{userInput.samplingBalanceData?.assessments["1-Mass Observation"] ? "Acceptable" : "Outlier"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">{userInput.samplingBalanceData?.entries["weightSerial-2"]}</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">{userInput.samplingBalanceData?.entries["2-Mass Observation"]}</TableCell>
                        <TableCell style={{ padding: "1px", border: "1px black solid", fontSize: "0.8em" }} align="center">
                        {userInput.samplingBalanceData?.assessments["2-Mass Observation"] ? "Acceptable" : "Outlier"}</TableCell>
                      </TableRow>
                    </TableBody>

                    </Table>
                  </TableContainer>
                </Grid>


              </Grid>
            </Box>
                      
              <Item elevation={0}>
                  <Typography variant="h6" gutterBottom>Sampling Worksheet Results</Typography>  
                <SampleWSIncrementsPreview specifiedData={specifiedData} />
                <SampleWSFooter handleInputNumeric={false} samplingInput={false} specifiedData={specifiedData} handleInput={false} setAllFootersYes={false} previewMode={true} /> 
              </Item>

              <Item elevation={0}>

                
              </Item>

          </Stack>
          {index === maxPages - 1 ? null : <div className="page-break" />}    
        </React.Fragment>
        
        
                                  
           
    )
    
  }}

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        
      >
        
        <DialogContent style={{backgroundColor: "lightgrey"}}>
          

        <div  ref={componentRef} className="print-container" style={{ margin: "0", padding: "0" }}>
          {pages}
        </div>

        </DialogContent>
        <DialogActions style={{backgroundColor: "lightgrey"}}>
          <Button  color="success" endIcon={<LocalPrintshopIcon />} onClick={handlePrint}>Print</Button> 
          <Button endIcon={<ClearIcon />} onClick={handleCancel}>Go Back</Button>         
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SamplingEventPrint;
