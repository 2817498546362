import React from 'react';

// mui
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';

const SampleWSHeader = ({ samplingInput, specifiedData }) => {
    
    const totalMass = samplingInput ? samplingInput.increments.map(inc => inc.sampleMass ? inc.sampleMass * 1 : 0).reduce((a, b) => a + b, 0).toFixed(4)    
    : specifiedData.samplingData?.increments?.map(inc => inc.sampleMass ? inc.sampleMass * 1 : 0).reduce((a, b) => a + b, 0).toFixed(4)

    return (
        <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>

            <Typography variant="h5" style={{color: "green"}}>Sampling Worksheet</Typography>

            
            {totalMass / specifiedData.targetMass >= 1 && !samplingInput ? 
                <>
                    <Typography variant="p"><CheckCircleOutlineIcon style={{color: "green", fontSize: "5em"}} /></Typography>               
                    <Typography variant="h5" style={{color: "green"}}>Sampling Complete</Typography>
                </>
            : "Sampling Partially Complete"}


        </Box>
    )

}

export default SampleWSHeader;
