import React, { useEffect} from 'react';
import { useInView } from 'react-intersection-observer';

// mui

const Footer = () => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1
    });
    
    useEffect(() => {
        if (inView) {
            // setPage(pageRef)
        }

    }, [inView])

    

    return (
        <>
        <span ref={ref}></span>
        <div style={{position: 'absolute', width: "100%" }} >
          <p>
          © Tentamus, Columbia Laboratories 2022, All Rights Reserved
          </p>
  
        </div>
        </>
    )
}

export default Footer
