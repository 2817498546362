const dependencyCheck = (dependency, inputs) => {    
    
    let renderCheck = false
    
    if (dependency.key === "testing" && inputs.activeTests && dependency.value.length) {
        // testing looks deeper than others
        const intersectionArr = inputs.activeTests.filter(value => dependency.value.find(item => item.LIMS_ID === value.LIMS_ID));
        
        if (intersectionArr.length > 0) {renderCheck = true}
    } else if (inputs[dependency.key] && dependency.value.length) {
        // for everythign else
        const intersectionArr = dependency.value.filter(value => [inputs[dependency.key]].flat().includes(value));
        if (intersectionArr.length > 0) {renderCheck = true}
    } else if (!dependency.value.length) {
        // this is if the value list is EMPTY insinuating that empty = NOTHING SELECTED = SHOW THIS
        
        if (!inputs[dependency.key] || !inputs[dependency.key].length) { renderCheck = true }
    }

    return renderCheck
}

export default dependencyCheck;
