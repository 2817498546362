import React from 'react';

// components
import Loading from './Loading.js';
import ReviewHeader from "./ReviewHeader.js"

// mui
import Grid from '@mui/material/Grid';

// styles
import '../../utils/styles/styleSheet.css';

const Review = ( { userInput, setUserInput, data, setMode, entryMode, globalLoading, setGlobalLoading, samplingMode } ) => {
    
    const [loading, setLoading] = React.useState(false)    
    const [error, setError] = React.useState(false)    
    const [relinquishMode, setRelinquishMode] = React.useState(false)

    return (
        <>

        {loading ? 
            <>
            <Grid item xs={0} md={3}>                
            </Grid>
            <Grid item xs={12} md={6}>                
                <Loading error={error} />
            </Grid>   
            <Grid item xs={0} md={3}>                
            </Grid>
            </>        
        :
            <>    
              
                <Grid item xs={0} md={1}>                
                </Grid>
                <Grid item xs={12} md={10}>                
                    {userInput ? 
                        <ReviewHeader 
                            error={error} setMode={setMode} 
                            userInput={userInput} 
                            setUserInput={setUserInput}                     
                            data={data} 
                            relinquishMode={relinquishMode} 
                            setRelinquishMode={setRelinquishMode} 
                            setLoading={setLoading} 
                            setError={setError} 
                            entryMode={entryMode}
                            setGlobalLoading={setGlobalLoading}
                            globalLoading={globalLoading}
                            samplingMode={samplingMode}
                        />
                    : null}
                </Grid>   
                <Grid item xs={0} md={1}>                
                </Grid>

            </>
        }
    
     </>   
    )

}

export default Review;
