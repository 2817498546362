import React from 'react';
import { cloneDeep } from 'lodash';

// components
import TestingSelector from './TestingSelector.js';
import TestGroupMenu from './TestGroupMenu.js';
import IndividualAnalytesSelector from './IndividualAnalytesSelector.js';

// mui
import Button from '@mui/material/Button';
import { Alert, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Tooltip from '@mui/material/Tooltip';


const TestEditor = ({ data, setData }) => {
    const testingData = data.forms.sections.find(sec => sec.type === "testing")
    const [active, setActive] = React.useState(testingData.testing_groups[0])

    const [deleteGroup, setDeleteGroup] = React.useState(false)

    const updateValue = (action, val, id) => {
        const newTestGroup = cloneDeep(active)

        if (action === "order") {
            const currIndex = active.tests.findIndex(p => p.LIMS_ID === id);
            const selectedTest = active.tests[currIndex]
            const newIndex = currIndex + val
            if (newIndex > -1 && newIndex < active.tests.length) {
                newTestGroup.tests.splice(currIndex, 1);
                newTestGroup.tests.splice(newIndex, 0, selectedTest);
            }
            newTestGroup.tests = newTestGroup.tests.map((t, i) => {
                t.order = i
                return t
            })
        }

        if (action === "testGroupOrder") {
            const currIndex = newTestGroup.order - 1
            const newIndex = currIndex + val
            const newTestForm = cloneDeep(testingData)
            newTestForm.testing_groups.splice(currIndex, 1);
            newTestForm.testing_groups.splice(newIndex, 0, newTestGroup);
            newTestForm.testing_groups.forEach((tg, i) => {
                tg.order = i + 1
            })
            
            const newData = cloneDeep(data)
            newData.forms.sections = newData.forms.sections.map(form => {
                return form.type === newTestForm.type ? newTestForm : form
            })
            setData(newData)
        }

        if (action === "delete") {
            newTestGroup.tests = newTestGroup.tests.filter(t => t.LIMS_ID !== id)
        }

        if (action === "addTest") {
            newTestGroup.tests.push(val)
        }

        if (action === "editName") {
            newTestGroup.tempName = val
        }

        if (action === "individualAnalytes") {
            newTestGroup.individualAnalytes = val;
        }
        if (action === "individualAnalytesName") {
            newTestGroup.individualAnalytesName = val;
        }

        if (action === "column") {
            // val false = column 1, true = column 2
            const targetTest = newTestGroup.tests.find(t => t.LIMS_ID === id)
            targetTest.column = !val        
            newTestGroup.tests.sort((a, b) => {
                return a.column - b.column;
            })
        }

        setActive(newTestGroup)
    }

    const handleNewTestGroup = () => {
        const newData = cloneDeep(data)
        const newTestingData = cloneDeep(testingData)
        const newTestGroup = {
            group: "new group",
            tests: []
        }

        newTestingData.testing_groups.unshift(newTestGroup)
        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newTestingData.type ? newTestingData : form
        })
        setData(newData)
        setActive(newTestGroup)
    }

    const detectNew = !!testingData.testing_groups.find(g => g.group === 'new group')

    const handleSetClientTesting = () => {
        const foundGroup = testingData.testing_groups.find(g => g.group === "clientTesting") || {
            group: 'clientTesting',
            order: -1,
            tests: []
        }
        setActive(foundGroup)
    }
    // const quickFix = () => {
    //     const newData = cloneDeep(data)
    //     const newTestingData = cloneDeep(testingData)
    //     newTestingData.testing_groups.forEach(tg => {
    //         tg.tests = tg.tests.map((t, i) => {
    //             if (!t.LIMS_NUMBER) {
    //                 const test = data.testingNew[t.type + 's'].find(item => item.LIMS_ID === t.LIMS_ID)
    //                 console.log('missing!', t.LIMS_ID, test.LIMS_NUMBER)
    //                 t.LIMS_NUMBER = test.LIMS_NUMBER
    //             }
    //             return t
    //         })
    //     })
    
    //     newData.forms.sections = newData.forms.sections.map(form => {
    //         return form.type === newTestingData.type ? newTestingData : form
    //     })
    //     setData(newData)
    // }

    return (
        <>

            <Typography variant="h5">Testing Selector Editor</Typography>
            {/* <Button onClick={quickFix} >Quick Fix</Button> */}
            <div role="presentation" style={{ width: "100%" }}>

                <Paper elevation={3} sx={{ m: '15px', textAlign: 'left', minHeight: "300px" }}>

                    <div style={{ overflow: "auto", backgroundColor: "rgba(245, 245, 245, 0.95)" }}>

                        <ButtonGroup variant="text" aria-label="text button group" >


                            {testingData.testing_groups.filter(g => g.group !== 'clientTesting').map((g, i) => {
                                return (
                                    <Button
                                        key={"chip-" + i}
                                        style={{ borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em" }}
                                        color="success"
                                        variant={active.group === g.group ? "contained" : "text"}
                                        onClick={() => setActive(g)}
                                    >
                                        {g.group}
                                    </Button>
                                )
                            })}
                            
                            <Button
                                key={"chip-create"}
                                disabled={detectNew}
                                style={{ borderRadius: 0, border: "1px black solid", padding: "5px", backgroundColor: "RGB(100,200,100)", color: "white", minWidth: "100px", fontSize: "0.7em" }}
                                color="success"
                                onClick={handleNewTestGroup}
                            >
                                Create Test Group
                            </Button>                                

                            <Button
                                key={"chip-create"}
                                disabled={detectNew}
                                style={{ borderRadius: 0, border: "1px black solid", padding: "5px", color: "white", backgroundColor: "RGB(200,100,100)", minWidth: "100px", fontSize: "0.7em" }}
                                color="success"
                                onClick={handleSetClientTesting}
                            >
                                Client Testing
                            </Button>  
                        </ButtonGroup>

                    </div>

                    {active ? 
                    <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>

                        <TestGroupMenu testingData={testingData} data={data} setData={setData} active={active} setActive={setActive} deleteGroup={deleteGroup} setDeleteGroup={setDeleteGroup} updateValue={updateValue} group={active} />
                        <Typography variant='h6' align="center">Column 1</Typography>
                        <List>
                            {active.tests?.filter(t => !t.column).map((t, j) => {
                                const test = data.testingNew[t.type + 's'].find(item => item.LIMS_ID === t.LIMS_ID)
                                const activeCheck = test?.ACTIVE === 'n' || test?.ACTIVE === '0'
                                const materials = data.references[t.type + 'Materials']?.filter(psm => psm[t.type.toUpperCase() + '_LIMS_ID'] === t.LIMS_ID)
                                const clientRef = data.references[t.type + 'Clients']?.filter(psc => psc.LIMS_TEST_ID === test.LIMS_ID)

                                return (
                                    <>
                                    <ListItem
                                        key={active.id + '-' + t.id + j}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {t.type === 'spectra' ? 'S' : null}
                                                {t.type === 'parameterScope' ? 'PS' : null}
                                                {t.type === 'parameterMethod' ? 'PM' : null}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                (test?.PRODUCTCODE || "No Product Code") 
                                                    + 
                                                ' - '
                                                    + 
                                                test?.DISPLAYNAME
                                                    + 
                                                (active.group === "clientTesting" && clientRef.length ? ' | Client Passkey: ' + clientRef?.map(c => c.CLIENT_PASSKEY) : '')
                                                    +
                                                (active.group === "clientTesting" && !clientRef.length ? ' | No Passkey' : '')
                                            }
                                            secondary={
                                                <>
                                                    {materials?.length ? 
                                                        <Alert style={{width: '80%'}}>Dependant Materials:
                                                            {materials.map((mt, l) => {
                                                                const matrix = data.references.materials.find(mat => mat.LIMS_ID === mt.MATERIAL_LIMS_ID)                                                
                                                                return <span key={mt.LIMS_ID}>{matrix?.DISPLAYNAME}{l === materials.length - 1 ? "" : ", "}</span>
                                                            })}
                                                        </Alert> 
                                                    : null}
                                                    {activeCheck ? 
                                                    <Alert severity="error" style={{width: '80%'}}>This test no longer &quot;Active&quot; in LIMS and will not display on Form.</Alert>
                                                    : null}
                                                </>
                                            }
                                        />
                                        <>
                                                <Tooltip title="Column to appear in">
                                                    <IconButton edge="end" aria-label="delete" onClick={() => updateValue("column", !!t.column, t.LIMS_ID)}>
                                                        {!t.column ? <LooksOneIcon /> : <LooksTwoIcon />}
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton disabled={j === 0} edge="end" aria-label="delete" onClick={() => updateValue("order", -1, t.LIMS_ID)}>
                                                    <ArrowUpwardIcon />
                                                </IconButton>
                                                <IconButton disabled={j === active.tests?.filter(t => !t.column).length - 1} edge="end" aria-label="delete" onClick={() => updateValue("order", 1, t.LIMS_ID)}>
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete" onClick={() => updateValue("delete", false, t.LIMS_ID)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        

                                    </ListItem>
                                    </>
                                )
                            })}
                            {active.tests?.filter(t => !t.column).length < 1 ? "Nothing in Column 1..." : null}

                        </List>
                        <Typography variant='h6' align="center">Column 2</Typography>
                        <List>
                            {active.tests?.filter(t => !!t.column).map((t, j) => {
                                const test = data.testingNew[t.type + 's'].find(item => item.LIMS_ID === t.LIMS_ID)
                                const activeCheck = test?.ACTIVE === 'n' || test?.ACTIVE === '0'
                                const materials = data.references[t.type + 'Materials']?.filter(psm => psm[t.type.toUpperCase() + '_LIMS_ID'] === t.LIMS_ID)
                                const clientRef = data.references.parameterScopeClients?.filter(psc => psc.LIMS_TEST_ID === test.LIMS_ID)

                                return (
                                    <>
                                    <ListItem
                                        key={active.id + '-' + t.id + j}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {t.type === 'spectra' ? 'S' : null}
                                                {t.type === 'parameterScope' ? 'PS' : null}
                                                {t.type === 'parameterMethod' ? 'PM' : null}
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText 
                                                primary={
                                                    (test?.PRODUCTCODE || "No Product Code") 
                                                        + 
                                                    ' - '
                                                        + 
                                                    test?.DISPLAYNAME
                                                        + 
                                                    (active.group === "clientTesting" && clientRef.length ? ' | Client Passkey: ' + clientRef?.map(c => c.CLIENT_PASSKEY) : '')
                                                        +
                                                    (active.group === "clientTesting" && !clientRef.length ? ' | No Passkey' : '')
                                                }
                                                secondary={
                                                <>
                                                {materials?.length ? 
                                                    <Alert style={{width: '80%'}}>Dependant Materials:
                                                        {materials.map((mt, l) => {
                                                            const matrix = data.references.materials.find(mat => mat.LIMS_ID === mt.MATERIAL_LIMS_ID) 
                                                            return <span key={mt.LIMS_ID}>{matrix?.DISPLAYNAME || '> Matrix not found <'}{l === materials.length - 1 ? "" : ", "}</span>
                                                        })}
                                                    </Alert> 
                                                : null}
                                                {activeCheck ? 
                                                    <Alert severity="error" style={{width: '80%'}}>This test no longer &quot;Active&quot; in LIMS and will not display on Form.</Alert>
                                                : null}
                                                </>
                                            }
                                        />
                                        <>
                                                <Tooltip title="Column to appear in">
                                                    <IconButton edge="end" aria-label="delete" onClick={() => updateValue("column", !!t.column, t.LIMS_ID)}>
                                                        {!t.column ? <LooksOneIcon /> : <LooksTwoIcon />}
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton disabled={j === 0} edge="end" aria-label="delete" onClick={() => updateValue("order", -1, t.LIMS_ID)}>
                                                    <ArrowUpwardIcon />
                                                </IconButton>
                                                <IconButton disabled={j === active.tests?.filter(t => !!t.column).length - 1} edge="end" aria-label="delete" onClick={() => updateValue("order", 1, t.LIMS_ID)}>
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete" onClick={() => updateValue("delete", false, t.LIMS_ID)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>

                                    </ListItem>
                                    </>
                                )
                            })}
                            {active.tests?.filter(t => !!t.column).length < 1 ? "Nothing in Column 2..." : null}
                        </List>

                        <Alert severity='warning'>Columns: tests may be rendered on two columns. On small screens/mobile, they will be stacked, column 1 over column 2, followed by the Individual Analytes Selection.</Alert>
                        <Alert severity='warning'>Matrix Dependency: this quality of Parameter Scope (and Spectra if requested, not online at this time) means that if the Scope has matricies specified to it in LIMS then the scope will only display for user if they select that matrix. <b>No specifications will result in always being displayed.</b></Alert>
                        <IndividualAnalytesSelector active={active} data={data} updateValue={updateValue} />


                    </Grid>
                    <Grid item xs={12} md={8}>

                        <TestingSelector data={data} active={active} updateValue={updateValue}  />

                    </Grid>



                    </Grid>
                    : 
                    <>
                    {data.sample.testing_groups?.length > 0 ? 
                    <p>Please Select a Group to edit above...</p>
                    :
                    <div style={{padding: '25px'}}>
                    <p>There are no groups, please create one.</p>
                    
                    </div>}
                    
                    </>}


                </Paper>
            </div>


        </>
    );

}

export default TestEditor;
