import React from 'react';

// mui
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';

// utils
import formStyles from '../../../utils/styles/formStyles.js';
import individualValCheck from '../../../utils/functions/individualValCheck.js';
import checkComplexRequired from '../../../utils/functions/checkComplexRequired.js';

const UserInput = ({ disabled, value, refItem, handleInput, sampleIndex, checkRequirements, userInput, data }) => {
    
    // instead of refItem do "refObject" or something like that    
    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''
    let useInputUnit = refItem && refItem[value.input_key+'Unit'] ? refItem[value.input_key+'Unit'] : ''

    // mirroring
    const canMirror = value.mirrorKey ? true : false    
    const isMirrored = disabled ? disabled : false
    if (canMirror && isMirrored) {
        useInput = refItem[value.mirrorKey] ? refItem[value.mirrorKey] : '' 
    }

    // build up error text
    const requiredCheck = checkComplexRequired(value.required, userInput, sampleIndex)
    const inputError = checkRequirements && requiredCheck && useInput === ''
    const validateError = value.validation ? checkRequirements && !individualValCheck(value.validation.type, useInput) : false
    const renderError = inputError || validateError
    const renderErrorText = [inputError ? "Required Entry" : "" , !inputError && validateError ? `Incorrect Formatting for ${value.validation.label}` : ""].filter(f => f !== "").join(", ")
    
    const subItemRequiredAlert = checkRequirements && !useInputUnit

    return (
        <>
            <Grid item xs={value.xs} md={value.md} key={"sample-"+value.input_key+"TextField"}>
                <TextField   
                    sx={{ width: value.input_unit ? '66.666666% !important' : "100% !important" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    id={"sample-"+value.input_key+"TextField"}                    
                    label={<span>{value.label}<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span>}
                    style={formStyles.dataInput}                              
                    helperText={renderErrorText}
                    error={renderError ? true : false}
                    size="small"
                    disabled={isMirrored}
                    value={useInput}
                    onChange={(e) => handleInput(e, value.input_key)}                        
                />
                    
                {value.input_unit ? 
                    <FormControl sx={{ width: '33.333333333%' }} size="small" error={subItemRequiredAlert} >
                    <InputLabel shrink id="demo-simple-select-label">Unit<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></InputLabel>  
                        <Select
                            id={"sample-"+value.input_key+"TextFieldUnit"} 
                            value={useInputUnit}           
                            disabled={disabled}
                            error={subItemRequiredAlert}
                            style={formStyles.dataInput}
                            onChange={(e) => handleInput(e, value.input_key+"Unit")}
                            input={<OutlinedInput notched label={"Unit"} />}
                        >
                            {value.input_unit?.value?.map((o, i) => {
                                const displayItem = value.input_unit.source === 'specified' ? o : data.references[value.input_unit.source]?.find(item => item.LIMS_ID === o)?.DISPLAYNAME
                                return <MenuItem key={o+i} value={o} id={sampleIndex ? o+i+sampleIndex : o+i}>{displayItem}</MenuItem>
                            })}
                        
                        </Select>

                    {subItemRequiredAlert ? <FormHelperText style={{color: 'red'}}>Required Entry</FormHelperText> : null}
                    </FormControl>
                : null}

            </Grid>
        

        </>
        


    );
}

export default UserInput;
