

import React from 'react';

// components

// mui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ErrorIcon from '@mui/icons-material/Error';

// styles
import '../../../utils/styles/styleSheet.css';

const SubmissionError = ({ error, userInput }) => {    
    
    return (
    <>

    {error === "saveError" ? 
    <>
        <Grid item xs={12} md={12} className="formHeaderContainer"><ErrorIcon className="formHeaderIcon"/><Typography component='h6' variant='h6'>Something Went Wrong! Failed to Save Your Submission!</Typography></Grid>       
        <Grid item xs={12} md={12} className="formHeaderContainer"><Typography component='p' variant='p'>Our system failed to save your submission. Your submission can still be saved by downloading the COC data or printing using the tools on the rendered COC below. Emailing this/these file directly to us at <a href="mailto:info@columbialaboratories.com" rel="noreferrer" target="_blank">info@columbialaboratories.com</a>. We apologize for the inconvenience and will work on resolving the issue for future submissions. Thank you.</Typography></Grid>        
        <Divider style={{width:'100%', margin: "25px"}}/>
    </>
    : null}

    {error === "emailError" ?     
    <>
        <Grid item xs={12} md={12} className="formHeaderContainer"><ErrorIcon className="formHeaderIcon"/><Typography component='h6' variant='h6'>Something Went Wrong! An Email Confirmation Failed To Send!</Typography></Grid>       
        <Grid item xs={12} md={12} className="formHeaderContainer"><Typography component='p' variant='p'>Our system failed to send an email to this address: {userInput.clientEmail}. If this email is in error please contact us immediately at (503) 254-1794 or email us at <a href="mailto:info@columbialaboratories.com" rel="noreferrer" target="_blank">info@columbialaboratories.com</a> with your confirmation code <span style={{fontWeight: "bold"}}>{userInput.submissionID}</span> as well as a copy of the COC below (Please use the print tool at the top-right of the rendered COC below).</Typography></Grid>                
        </>
    : null}
    </>  

    );
}

export default SubmissionError;
