import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:'#EEEEEE',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GenericTextSubmit = ({ open, setOpen, submitEntry, altOpen }) => {
  
  const [input, setInput] = React.useState('')
  const [error, setError] = React.useState(false)

  const handleCancel = () => {    
    setOpen(false);
  };

  const handleSave = async () => {
    const result = await submitEntry(input)    
    if (result !== true) {
      setError(result)
    } else {
      setError(false)
      if (altOpen) {
        altOpen()
      } else {
        setOpen(false);
      }
      
    }
    
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{open.header}</DialogTitle>
        <DialogContent >

          <Item >       
              <DialogContentText id="alert-dialog-slide-description">
                {open.body}
              </DialogContentText>    
              
              <FormControl variant="standard">
              <Input 
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  
              />
                <FormHelperText id="component-helper-text">
                  {open.helperText}
                </FormHelperText>
              
              {error ? <Alert severity="warning">INPUT ERROR: {error}</Alert> : null}

              </FormControl>
          </Item>
        </DialogContent>
            
              
        <DialogActions>
          <Button disabled={input.length < 1} onClick={handleSave}>Confirm Save</Button>
          <Button onClick={handleCancel}>Go Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenericTextSubmit;
