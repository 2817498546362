import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'validator';

// components
import SampleWSFooter from './IncrementTable/SampleWSFooter';
import SampleWSHeader from './IncrementTable/SampleWSHeader';
import SampleWSIncrementsPreview from './IncrementTable/SampleWSIncrementsPreview.js';
import SampleWSIncrements from './IncrementTable/SampleWSIncrements';
import SamplingWSToolbar from './SamplingWSToolbar';
import SampleDataTable from './SampleDataTable';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// utils
import FormAPI from '../../../utils/API/api-form';

const SampleWorksheet = ({ handleExit, samplingInput, setSamplingInput, currSam, userData, handleSave, error, loading, updateCurrSam, references, specifiedData }) => {
   
    const handleInputNumeric = (val, cat) => {
      
      const numCheck = validator.isNumeric(`${val * 1}`)   
        
      if (numCheck) {
        const newInput = cloneDeep(samplingInput)        
        newInput[cat] = val
        setSamplingInput(newInput)
      }
    }

    const handleInput = (val, cat) => {
      const newInput = cloneDeep(samplingInput)        
      newInput[cat] = val
      setSamplingInput(newInput)
    }

    const setAllFootersYes = (footers) => {
      const newInput = cloneDeep(samplingInput)        
      footers.forEach(foot => {
        newInput[foot] = "Yes"
      })
      setSamplingInput(newInput)
    }

    const handleInputIncrement = (val, inc, cat) => {
      if (val === ".") {val = "0."}
      const check = (val * 1)        
      if (validator.isNumeric(`${check}`) ) {
      
        const newInput = cloneDeep(samplingInput)       
        const newInputIndex = newInput.increments?.findIndex(item => item.id === inc);
        const newInc = newInput.increments?.find(increment => increment.id === inc)
        newInc[cat] = val
        newInput.increments?.splice(newInputIndex, 1, newInc)
        setSamplingInput(newInput)
      }

    }

    const handleAddIncrement = async () => {
      // async call to API      
      try {
        

        const query = {   
          folder: "samplingReferences",
          references: ["incrementZones"]
        }

        const referenceData = await FormAPI.getSamplingReferences(query)

        const newInput = cloneDeep(samplingInput)   
        const refZones = referenceData.data.incrementZones[specifiedData.samplingData.dimension]  
        
        const randInc = Math.floor(Math.random() * (refZones.length - 0)) + 0;
  
        newInput.increments.push({
          id: newInput.increments.length,
          zone: refZones[randInc],
          container: 1,
          additional: true
        })
  
        setSamplingInput(newInput)
        

      } catch (err) {
        // do or do not do something here
        console.log(err)
      }
      
    }

    return (
      <Box style={{ backgroundColor: "#cFdFFF", padding: "15px", margin: "15px", borderRadius: "2px", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"}}>
        <Grid container spacing={1}>
          
          <Grid item xs={3}>
            <SampleWSHeader userData={userData} specifiedData={specifiedData} />
          </Grid>
          
          <Grid item xs={4}>
            <SamplingWSToolbar 
              userData={userData} 
              handleSave={handleSave} handleExit={handleExit}
              error={error} 
              loading={loading} 
              samplingInput={samplingInput} 
              currSam={currSam} 
              updateCurrSam={updateCurrSam}                   
              setSamplingInput={setSamplingInput}
              references={references}
              specifiedData={specifiedData}
            />    
          </Grid>

          <Grid item xs={5}>
            <SampleDataTable specifiedData={specifiedData} />
          </Grid>

        </Grid>
        

        {samplingInput ? 
          <>
            {/* Active Inputs */}
            <SampleWSIncrements specifiedData={specifiedData} handleAddIncrement={handleAddIncrement} samplingInput={samplingInput} handleInputIncrement={handleInputIncrement} />
            <SampleWSFooter handleInputNumeric={handleInputNumeric} samplingInput={samplingInput} handleInput={handleInput} setAllFootersYes={setAllFootersYes} previewMode={false} />  
          </>
          
        : 
        <>
          {
            specifiedData.samplingData?.increments?.length > 0 ? 
            <>
              {/* Preview, non-interactables */}
              <SampleWSIncrementsPreview specifiedData={specifiedData} />
              <SampleWSFooter handleInputNumeric={handleInputNumeric} samplingInput={samplingInput} specifiedData={specifiedData} handleInput={handleInput} setAllFootersYes={setAllFootersYes} previewMode={true} />  
            </>
            : 
            "Click START to begin Sampling"
          }
        </>
        }
        
      </Box>
    )

}

export default SampleWorksheet;
