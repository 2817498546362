import * as React from 'react';
import { cloneDeep } from 'lodash';

// components
import FieldsEditor from './FieldsEditor';
import MaterialOrdersTestingParameters from './MaterialOrdersTestingParameters';

// mui
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import KeyIcon from '@mui/icons-material/Key';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PlusOneIcon from '@mui/icons-material/PlusOne';

const SamplingEventFullfillmentForm = ({ data, setData, parentChanged }) => {
   
  const [selectedForm, setSelectedForm] = React.useState(false)
  const [newSubSection, setNewSubSection] = React.useState(false)
  const [selectedInput, setSelectedInput] = React.useState(false)
  const [mode, setMode] = React.useState('form')

  const handleReset = () => {
    setSelectedForm(false)
    setNewSubSection(false)
    setSelectedInput(false)
  }

  const formOptions = data?.forms?.sections?.filter(s => s.type === 'samplingEvent')
  const currentForm = data?.forms?.sections?.find(s => s.sectionID === selectedForm) || false

  const createNew = () => {
    const newSection = {
      labCode: "cfl",
      sectionID: "newSamplingEventForm",
      type: "samplingEvent",
      sub_sections: [{
        requirements: [],
        labCode: "cfl",
        sectionKey: "general",
        order: 1,
        header: "New Form",
        icon: "",
        useAsAbove: false,
        inputs: []
      }],
      samplingEventName: "New Sampling Event",
      samplingEventKey: "newSamplingEvent"
    }

    const newData = cloneDeep(data)
    newData.forms.sections = [...newData.forms.sections, newSection]
    setSelectedForm('newSamplingEventForm')
    setData(newData)
  }

  const saveData = (val) => {

    const clone = cloneDeep(val)

    const newData = cloneDeep(data)
    const newCurrentForm = newData.forms.sections.find(s => s.type === 'samplingEvent')
    newCurrentForm.sub_sections.forEach(ss => {
        ss.inputs = ss.inputs.map(input => {
            return input.input_key === clone.input_key ? clone : input
        })
    })
    setData(newData)
  }

  const updateForm = (key, val) => {
    const newData = cloneDeep(data)
    const updateForm = newData?.forms?.sections?.find(s => s.sectionID === selectedForm)
    updateForm[key] = val
    setData(newData)
  }

  return (
    <>
      <h2>Sampling Event Forms</h2>      
      <p>Each form will generate a new possible route for a simple Sampling Event request form. <b>If at any time a more complex sampling event needs to be created, feel free to reach out to the devlopers!</b></p>

      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={selectedForm}
        onChange={(e) => setSelectedForm(e.target.value)}
        label="Select"
        style={{margin: "0px", width: "100%", height: "100%" }}
      >
        {formOptions.map((ser, i) => {
            return (
              <MenuItem key={"select-"+i} value={ser.sectionID}>{ser.samplingEventName}</MenuItem>
            )
        })}
      </Select>
      <Button onClick={createNew} disabled={selectedForm}>Create new Sampling Event</Button>
      <Button onClick={handleReset} disabled={!selectedForm}>Cancel</Button>

      {selectedForm ? 
      <div style={{ padding: '15px', backgroundColor: 'white' }}>
      <FormControl variant="standard" style={{width: '300px'}}>
        <InputLabel>
          Name (for labeling purposes, when seen on forms, etc)
        </InputLabel>
        <Input
          value={currentForm.samplingEventName}
          onChange={(e) => updateForm('samplingEventName', e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <DriveFileRenameOutlineIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <br />
      <FormControl variant="standard" style={{width: '300px', marginTop: '15px' }}>
        <InputLabel>
          Key (url string reference)
        </InputLabel>
        <Input
          value={currentForm.samplingEventKey}
          onChange={(e) => updateForm('samplingEventKey', e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <KeyIcon />
            </InputAdornment>
          }
        />
        </FormControl>
        <br />
      <FormControl variant="standard" style={{width: '300px', marginTop: '15px' }}>
          <InputLabel>
            Submission Prefix
          </InputLabel>
          <Input
            value={currentForm.submissionPrefix}
            onChange={(e) => updateForm('submissionPrefix', e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <PlusOneIcon />
              </InputAdornment>
            }
          />
        <Button style={{ width: '100%', marginTop: '10px' }} variant={mode === 'form' ? 'contained' : 'outlined'} onClick={() => setMode('form')} >Fulfillment Form Settings</Button>
        <Button style={{ width: '100%', marginTop: '10px' }} variant={mode === 'testing' ? 'contained' : 'outlined'} onClick={() => setMode('testing')} >Testing Adjustments</Button>
      </FormControl>
      
      {mode === 'form' ?
        <FieldsEditor groupSel={newSubSection} setGroupSel={setNewSubSection} data={data} setData={setData} saveData={saveData} currentForm={currentForm} selected={selectedInput} setSelected={setSelectedInput} parentChanged={parentChanged} />        
      : null }
      
      {mode === 'testing' ?
        <MaterialOrdersTestingParameters data={data} currentForm={currentForm} updateForm={updateForm} />
      : null }

      </div>
      : null}

    </>
  );
}

export default SamplingEventFullfillmentForm;
