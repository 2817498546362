import React from 'react';
import validator from 'validator';
import cloneDeep from 'lodash/cloneDeep';

// mui
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const styles = {
  cell: { padding: "1px", border: "1px black solid", fontSize: "0.8em" },
}

const BalanceTableRow = ({ editMode, balanceEntries, setBalanceEntries, keyIndex, row, references }) => {

    const handleInput = (val, cat) => {
      
      // only allow updating if input is numeric (multiply by 1 to row as "." is allowed to exist as an initial entry)
      // also allow serial weight on select
      const row = (val * 1)        
      if (validator.isNumeric(`${row}`) || cat.indexOf("weightSerial") > -1) {
        const newBalanceEntries = cloneDeep(balanceEntries)              
        newBalanceEntries.entries[cat] = val
        setBalanceEntries(newBalanceEntries)  
      }
    
    }    
    
    return (
        <TableRow key={"balance-row-"+keyIndex}>

        <TableCell style={styles.cell} align="center">{row.target}</TableCell>
        
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={balanceEntries.entries["weightSerial-" + row.id] ?? ""}
                onChange={(e) => {handleInput(e.target.value, "weightSerial-" + row.id)}}
                label="Select"
                style={{margin: "0px", width: "100%", height: "100%", }}
            >
                {references.samplingEventBalanceCriteria.serials.map((ser, i) => {
                    return (
                        <MenuItem key={"select"+row.id+"-"+i} value={ser}>{ser}</MenuItem>
                    )
                })}
            </Select>

        <TableCell style={styles.cell} align="center">± {row.range} g</TableCell>
        

        {references.samplingEventBalanceCriteria.columns.map((col, i) => {
            

            return (
                <React.Fragment key={ row.id + "-" + col + "-" + i}>
                    <TableCell style={styles.cell} align="center">
            
                        <TextField
                            id="standard-start-adornment"
                            disabled={editMode !== "open"}
                            align="center"
                            onChange={(e) => {handleInput(e.target.value, row.id + "-" + col)}}
                            value={balanceEntries.entries[row.id + "-"  + col]}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                            }}
                            style={{margin: "0px", width: "100%", height: "100%", }}
                            inputProps={{style: { textAlign: 'center' }}}
                        />

                    </TableCell>
                        
                    {editMode === "open" ? 
                        <TableCell style={styles.cell} align="center"></TableCell> 
                    :
                        <TableCell style={balanceEntries.assessments[row.id + "-" + col] ?  styles.cellPass : styles.cellFail} align="center" >
                            {
                              balanceEntries.assessments[row.id + "-" + col]
                            ? 
                            "Acceptable" : "Outlier"
                        }
                        </TableCell>                   
                    }  
                                
                </React.Fragment>

            )

        })}

      

      </TableRow>
    )

}

export default BalanceTableRow;
