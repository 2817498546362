import React from 'react';

// components
import COCRender from '../../COCRender/COCRender.js';
import SamplingWorksheets from './SamplingWorksheets.js';
import SamplingEventInstructions from './SamplingEventInstructions.js';
import SamplingEventPrint from '../../dialogues/SamplingEventPrint.js';
import LabUse from '../../dialogues/LabUse.js';
import ProjectNotes from '../ProjectNotes.js';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// hide the instructions or change them to something else

const Relinquishment = ({ loading, userData, setUserData, setMode, formData, createSamplingDuplicates, error, setError, references }) => {

    const [samplingMode, setSamplingMode] = React.useState("summary")
    const [openPrint, setOpenPrint] = React.useState(false)
    const [labUseOpen, setLabUseOpen] = React.useState(false)

    const handleBackToMain = () => {
      setMode('admin')
      setError(false) 
      window.history.pushState('page2', 'Title', '/admin/')
    }

    // need a workflow for sample-data-receiving. Switch from "summary" to "sampling"
      // page over each sample to take in required data and save

    const maxPages = userData.samplingEventInputs?.length

    const checkDisableSamplingSheets = () => {
      let disabledCheck = []
      userData.samples?.forEach(s => {
        const findSamplingInput = userData.samplingEventInputs?.find(si => si.id === s.id)

        if (findSamplingInput && findSamplingInput.columbiaSampleID && findSamplingInput.dimension) {
          disabledCheck.push("no")
        } else {
          disabledCheck.push("yes")
        }

      })

      return disabledCheck.indexOf("yes") !== -1
    }
    
    const sampleSheetsDisabled = checkDisableSamplingSheets()

    

    const printSamplingDisabled = userData.status === "samplingComplete" || userData.status === "client-signed"
    
    return (
      <Box
        component="form"
        autoComplete="off"   
        style={{padding: '25px'}}     
      >
        {loading ? "Loading..." : 
        <>
        {printSamplingDisabled ?
          <SamplingEventPrint data={formData} open={openPrint} setOpen={setOpenPrint} userInput={userData} maxPages={maxPages} references={references}/> 
        : null }
       
       <LabUse data={formData} userInput={userData} open={labUseOpen} setOpen={setLabUseOpen} setUserInput={setUserData}/> 

        {userData.submissionID ? decodeURIComponent(userData.submissionID) : "Almost done loading..."}
        {error === "noFIDData" ? 
          <>The Form ID provied could not be found. Please check URL.</>
        : null}

        {error && error !== "noFIDData" ? <h4 style={{color: 'red'}}>{error}</h4> : null}
        
        <Grid container spacing={2}>

          <Grid item xs={12}>
              {/* Toolbar / Menu */}
              <Button disabled={window.location.href.indexOf("sampling") > -1 && samplingMode === "samples"} onClick={handleBackToMain}>Back to Admin Home</Button>
              {window.location.href.indexOf("sampling") > -1 ? 
                   <>
                   <Button variant={samplingMode === "summary" ? "contained" : "outlined"} onClick={() => {setSamplingMode("summary")}}>Order Summary</Button>
                   <Button disabled={sampleSheetsDisabled} variant={samplingMode === "samples" ? "contained" : "outlined"} onClick={() => {setSamplingMode("samples")}}>Sampling Worksheets</Button>
                   <Button disabled={!printSamplingDisabled} onClick={() => setOpenPrint(true)}>Print Sampling Worksheets</Button>
                   </>         
              : null}

          </Grid>
       
        

        {/* Render Tools Below */}
        
        {userData.submissionID ? 
        <>  
          {samplingMode === "summary" ? 
            <>
              <Grid item md={9} xs={12}>
                <COCRender setMode={setMode} userInput={userData} setUserData={setUserData} data={formData} labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} createSamplingDuplicates={createSamplingDuplicates}/>                   
              </Grid>

              <Grid item md={3} xs={12}>
              
                {window.location.href.indexOf("sampling") > -1 ?               
                    <SamplingEventInstructions data={formData} userData={userData} setUserData={setUserData} />                
                : null }
                
                {userData.submissionID ?              
                  <ProjectNotes userInput={userData} />
                : null}
                
              </Grid>            
            </>
          : null}
              
          {samplingMode === "samples" ? 
            <Grid item xs={12}>
              <SamplingWorksheets data={formData} userData={userData} setUserData={setUserData} references={references} />
            </Grid>
          : null}
        </>
        : null}



        </Grid>
        </>
        }

        
      </Box>
    )

}

export default Relinquishment;
