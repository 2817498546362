import * as React from 'react';
import { cloneDeep } from 'lodash';

// components
import InputObjRender from '../../Form/InputObjRender'
import headerIcons from '../../Form/headerIcons';

// mui
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      color: 'black',
    },
  },
});


const LabUseEntry = ({ data, handleSaveEntries, userInput, setUserInput, mode, setMode }) => {

  const adminForm = data?.forms?.sections?.find(f => f.type === 'admin-receive');
  const adminReceive = cloneDeep(adminForm.sub_sections.find(ss => ss.sectionKey === 'adminReceive'));
  const adminAdditional = cloneDeep(adminForm.sub_sections.find(ss => ss.sectionKey === 'adminAdditional'));

  // filter out stuff here sometimes
  if (mode !== 'receive-admin') {
    adminReceive.inputs = adminReceive.inputs.filter(input => input.adminStandard);
    adminAdditional.inputs = adminAdditional.inputs.filter(input => input.adminStandard);
  }

  const [value, setValue] = React.useState({})

  const handleInput = (e, cat) => {
    const newValue = cloneDeep(value)
    newValue[cat] = e.target.value
    setValue(newValue)
  }

  const handleConfirm = () => {
    const newInput = cloneDeep(userInput)
    
    let newRec = cloneDeep(value)
    newRec.dateTime = new Date()
    newRec.type = mode.indexOf("relinquish") > -1 ? 'relinquish' : 'receive'
    newRec.staff = mode.indexOf("courier") > -1 ? 'courier' : 'admin'
    // newRec.prelogStorage = newRec.prelogStorage.join(';')
    newInput.signatures.push(newRec)    

    setUserInput(newInput)

    setValue({})
    setMode("home")

    handleSaveEntries(newInput)
  };

  const handleCancel = () => {
    setMode("home")
  }

  return (
    <>
    <ThemeProvider theme={theme}>

      <Grid container spacing={2} style={{marginTop: "15px"}} >

        {adminReceive.inputs.length ?   
          <>
            <Grid item xs={12} md={12} className="formHeaderContainer">{headerIcons.find(i => i.key === adminReceive.icon)  ? headerIcons.find(i => i.key === adminReceive.icon) .component : null}<Typography component='h5' variant='h5'>{adminReceive.header}</Typography></Grid>
            <InputObjRender
              checkRequirements={false}
              sourceDataObj={adminReceive}
              objKey={"adminReceive"}
              data={data}
              userInput={value}
              disabled={false}
              handleInput={handleInput}
            />
          </>        
        : null}

        {adminAdditional.inputs.length ?   
        <Accordion style={{marginTop: '15px', width: '100%', backgroundColor: '#eeeeee'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h5' style={{fontWeight: 'bold', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              {headerIcons.find(i => i.key === adminAdditional.icon)  ? headerIcons.find(i => i.key === adminAdditional.icon) .component : null}{adminAdditional.header}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Grid container spacing={2}>
              <InputObjRender
                checkRequirements={false}
                sourceDataObj={adminAdditional}
                objKey={"adminAdditional"}
                data={data}
                userInput={value}
                disabled={false}
                handleInput={handleInput}
                adminAlt={true}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        : null}

      </Grid>
      </ThemeProvider>
      <DialogActions>
          <Button onClick={handleConfirm}>Submit</Button>
          <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>      

    </>
  );
}

export default LabUseEntry;
