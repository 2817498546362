import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminArchive = ({ open, setOpen, handleArchive }) => {
    
  const handleCancel = () => {    
    setOpen(false);
  };

  const handleSelect = () => {
    handleArchive(open)    
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Archive Project # {open}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Archiving removes project from view. This can be undone later by clicking &quot;show archived&quot; button on main Admin page and clicking &quot;Re-Open&quot; on the project line.
          </DialogContentText>
        </DialogContent>
            
              
        <DialogActions>
          <Button onClick={handleSelect}>Confirm Archive</Button>
          <Button onClick={handleCancel}>Go Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminArchive;
