import React from 'react';

// components
import Signature from '../dialogues/Signature';
import SaveSubmission from '../dialogues/SaveSubmission';
import SamplingSubmission from '../dialogues/SamplingSubmission';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';

// utils
import "../../utils/styles/cocStyle.css"
import dateHandlers from '../../utils/functions/dateHandlers';

const Signatures = ({ data, userInput, setUserData, setMode, setGlobalLoading, samplingMode }) => {
  const [signOpen, setSignOpen] = React.useState(false)
  const [saveOpen, setSaveOpen] = React.useState(false)
  const [samplingSubOpen, setSamplingSubOpen] = React.useState(false)

  const signatures = userInput.signatures?.filter(s => s.type === 'relinquish').sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});

  const initialSignature = signatures ? signatures[0] : false
  const allOtherSignatures = signatures?.slice(1) || false

  React.useEffect(() => {

    if (!userInput.submissionID) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }

  }, [userInput]);

  return (
        
    <TableContainer style={{marginTop: "5px", marginLeft: "5px", maxWidth: "35%", float: "right", minHeight: '150px'}}>
    <Signature data={data} open={signOpen} setOpen={setSignOpen} userInput={userInput} setUserData={setUserData} /> 
    <SaveSubmission data={data} samplingMode={samplingMode} open={saveOpen} setOpen={setSaveOpen} userInput={userInput} setUserData={setUserData} mode={'samples'} />
    <SamplingSubmission data={data} samplingMode={samplingMode} open={samplingSubOpen} setOpen={setSamplingSubOpen} userInput={userInput} setUserData={setUserData} mode={'samples'} setGlobalLoading={setGlobalLoading} />
      
        {initialSignature ? 
          <Table>
              <TableHead>
                <TableRow>
                  <td className="signatureCellHeader">{ samplingMode ? 'Submitted' : 'Relinquished' } By</td>
                  <td className="signatureCellHeader">Date</td>
                  <td className="signatureCellHeader">Time</td>    
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <td className="signatureCell"><span>{initialSignature?.name}</span></td>
                  <td className="signatureCell">{initialSignature?.dateTime ? dateHandlers.standardDate(initialSignature?.dateTime) : ""}</td>
                  <td className="signatureCell">{initialSignature?.dateTime? dateHandlers.standardTime(initialSignature?.dateTime) : ""}</td>
                  {allOtherSignatures.length && !samplingMode ? 
                  <td className="signatureCellHeader">Temp., °C</td> 
                  : null}
                </TableRow> 

                {samplingMode && allOtherSignatures?.length > 0 ? 
                  <TableRow>
                    <td className="signatureCellHeader">Relinquished By</td>
                    <td className="signatureCellHeader">Date</td>
                    <td className="signatureCellHeader">Time</td>  
                    <td className="signatureCellHeader">Temp</td>    
                  </TableRow>
                : null } 
                {allOtherSignatures ? allOtherSignatures.map((s, i) => {
                  return (
                    <TableRow key={'sig-add-'+i}>
                      <td className="signatureCell">
                      <span>{s.name}</span>
                      </td>
                      <td className="signatureCell">{dateHandlers.standardDate(s.dateTime)}</td>
                      <td className="signatureCell">{dateHandlers.standardTime(s.dateTime)}</td>
                      <td className="signatureCell">{s.tempurature === null || s.tempurature === undefined || s.tempurature.toString() === '0.00' || s.tempurature === 0  ? 'NA' : s.tempurature}</td>
                    </TableRow>
                  )
                }) 
                : null}
                {samplingMode  && userInput.status !== 'sampling-complete' && userInput.status !== 'saved' && userInput.status !== 'saved-form' ?
                <div
                  className="blob red"
                  style={{
                    width: '66%',
                    backgroundColor: 'RGBA(255,255,255,0.5)',
                    position: 'absolute',
                    bottom: '15%',
                    right: '15%',
                    border: '1px black solid',
                    borderRadius: '15px',
                    padding: '10px 5px 15px 15px',
                    fontSize: '0.8vw'
                  }}
                >          
                  <h4>Sampling Event Submitted</h4>
                </div>
              : null}
              </TableBody>
      </Table>
        : 
        
        <>
        {userInput.submissionID && !initialSignature && (userInput.serviceLine === 'cannabis-sampling' || samplingMode) && userInput.status !== 'samplingComplete' && userInput.status !== 'saved' && userInput.status !== 'saved-form' ?
          <div
            className="blob red"
            style={{
              width: '66%',
              backgroundColor: 'RGBA(255,255,255,0.5)',
              position: 'absolute',
              bottom: '15%',
              right: '15%',
              border: '1px black solid',
              borderRadius: '15px',
              padding: '10px 5px 15px 15px',
              fontSize: '0.8vw'
            }}
          >          
            <h4>Sampling Event Submitted</h4>
          </div>
        : 
            null
        }
        </>
        
        }

    </TableContainer>     
    
  );
}

export default Signatures;
