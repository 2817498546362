import * as React from 'react';

// mui
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// utils
import '../utils/styles/imageStyles.css'

const Header = ({ data, entryMode }) => {
  const materialForm = data?.forms?.sections?.find(sec => sec.type === "materialOrder")

  const materialOrderHeader = materialForm?.materialName || false

  return (
    <Box sx={{ flexGrow: 1 }}>
        <img 
          alt="columbia-bg"
          src="/images/backdrop.jpg"
          className="backdropImage"
        />

      <AppBar position="static" id="app-header">
        
        <Toolbar style={{padding: "15px"}}>
          <a href="https://www.columbialaboratories.com/" target="_blank" rel="noopener noreferrer"><img alt="logo-cfl" src='/images/cfl-logo.png' id="cl-logo" /></a>
          <img alt="logo-cfl-alt" src='/images/cfl-logo-alt.png' id="cl-logo-alt" />
          <Typography component="div" sx={{ flexGrow: 1 }} id="header-title" >  
            {entryMode === 'samples' ? 
              <>{data?.forms?.pageHeader || 'Now Loading...'}</>
            :
              <>{materialOrderHeader ? materialOrderHeader : 'Now Loading...'}</>
            }          
            
          </Typography>
          <img alt="logo-tentamus" src='/images/tentamus-logo.svg' id="tent-logo"/>

        </Toolbar>
      </AppBar>
      
    </Box>
  )
}

export default Header;
