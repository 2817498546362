import React from 'react';

// mui
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const COCFooter = ({ page, maxPages, userInput, SEOR }) => {
  
  return (
      <Grid container spacing={0} justify = "center">
                
        <Grid item xs={12} >
          <Grid container>
            
            {userInput.serviceLine.indexOf('sampling') > -1 && (SEOR || !userInput.status || userInput.status === "submitted" || userInput.status === "samplingBalanceValidated") ? 
              <Grid item xs={12} md={12}>
                <Typography className="mainFooterTextBody" component='p' variant='p'>
                  This is a summary of submission. Once sampling is complete this will transform into a Chain of Custody.
                </Typography>
              </Grid>
              : 
                <Grid item xs={12} md={12}>
                  <Typography className="mainFooterTextBody" component='p' variant='p'>
                    Samples submitted to Columbia Laboratories with testing requirements constitute an agreement 
                    for services in accordance with the <a href="/pdf/cfl-ecoc-terms-and-conditions.pdf" target="_blank" rel="noreferrer">current terms of services</a> associated with this COC. 
                    By signing &quot;	Relinquished by&quot;	 you are agreeing to these terms.
                  </Typography>
                </Grid>
              }
              
              </Grid>
            </Grid>
        
        <Grid item xs={3}>
          <Typography className="mainFooterText" component='p' variant='p'>Columbia Laboratories</Typography>
          <Typography className="mainFooterText" component='p' variant='p'>12423 NE WHitaker Way</Typography>
          <Typography className="mainFooterText" component='p' variant='p'>Portland, OR 97230</Typography>
        </Grid>
        
        <Grid item xs={6}>
          <Typography className="mainFooterText" component='p' variant='p'>P: (503) 254-1794</Typography>
          <Typography className="mainFooterText" component='p' variant='p'><a href="mailto:info@columbialaboratories.com" target="_blank" rel="noreferrer">info@columbialaboratories.com</a></Typography>
        </Grid>
        
        <Grid item xs={3}>
          <Typography className="mainFooterText" component='p' variant='p'>Page {page + 1} of {maxPages}</Typography>
          <Typography className="mainFooterText" component='p' variant='p'><a href="https://www.columbialaboratories.com" target="_blank" rel="noreferrer">www.columbialaboratories.com</a></Typography>
        </Grid>

      </Grid>
    
    
  );
}

export default COCFooter;
